import { format } from "date-fns";
import ptBR from 'date-fns/locale/pt-BR'

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import colors from "../styles/colors";
import { formatCpf } from "./formatCpf";
import { FormatPhone } from "./formatPhone";
pdfMake.addVirtualFileSystem(pdfFonts);

const img = 'data:image/png;base64,colocar url base64'
export function createListPdf(lista){

    const reportTitle = [
        {
            text: "Associado",
            alignment: 'center',
            fontSize: 25,
            bold: true,
            margin: [0 , 20, 0, 60]
        }

    ];

    const dados = lista.map( (item, index) => {
        const _color = index%2 === 0 ? colors.gray : '#FFFFFF'
        return [
            {text: item.matricula, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: item.name, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: item.cpf, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: item.phone, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: item.email, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}
        ]
    })

    const details = [
        {
			table: {
				headerRows: 1,
                widths: [70, '*', 100, '*', '*',],
				body: [
					[
                        {text: 'Matrícula', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Nome', style: 'tableHeader', fontSize: 10}, 
                        {text: 'CPF', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Telefone', style: 'tableHeader', fontSize: 10}, 
                        {text: 'E-mail', style: 'tableHeader', fontSize: 10}
                    ],
					...dados
				]
			},
			layout: 'lightHorizontalLines'
		},
    ];

    function rodape(currentPage, pageCount){
        return[
            {
                text: currentPage.toString() + ' / ' + pageCount.toString(),
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0]
            }
        ]
    };

    const docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [15,50,15, 40],
        header: [reportTitle],
        content: [details],
        footer: rodape,
    };


    pdfMake.createPdf(docDefinition).open();
}

export function createPdfRelatorio(lista){

    const reportTitle = [
        {
            text: "Relatório Geral",
            alignment: 'center',
            fontSize: 25,
            bold: true,
            margin: [0 , 20, 0, 60]
        }

    ];

    const dados = lista.map( (item, index) => {
        const _color = index%2 === 0 ? colors.gray : '#FFFFFF'
        return [
            {text: item.user.name, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${parseFloat(item?.user?.valorINSS).toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${parseFloat(item.user.planoEQTPREV).toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${parseFloat(item.totalUnimedGeral).toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${parseFloat(item.totalGeralUniodonto).toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${parseFloat(item.totalOficinas).toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${parseFloat(item.totalAssistencia).toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${parseFloat(item?.user?.valorMensalidade).toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: item.status, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}
        ]
    })

    const details = [
        {
            text: format(new Date(lista[0].dateVencimento), '(MMMM/yyyy)', {locale: ptBR}), 
            alignment: 'center',
            fontSize: 15,
            margin: [0 , 2, 0, 15]
        },
        {   
			table: {
				headerRows: 1,
                widths: [120, '*', '*', '*', '*', '*', '*', '*', '*',],
				body: [
					[
                        {text: 'Nome', style: 'tableHeader', fontSize: 10}, 
                        {text: 'INSS', style: 'tableHeader', fontSize: 10}, 
                        {text: 'EQTPREV', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Unimed', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Uniodonto', style: 'tableHeader', fontSize: 10},
                        {text: 'Oficinas', style: 'tableHeader', fontSize: 10},
                        {text: 'Ass. Médica', style: 'tableHeader', fontSize: 10},
                        {text: 'Mensalidade', style: 'tableHeader', fontSize: 10},
                        {text: 'Status', style: 'tableHeader', fontSize: 10},
                    ],
					...dados
				]
			},
			layout: 'lightHorizontalLines'
		},
    ];

    function rodape(currentPage, pageCount){
        return[
            {
                text: currentPage.toString() + ' / ' + pageCount.toString(),
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0]
            }
        ]
    };

    const docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [15,50,15, 40],
        header: [reportTitle],
        content: [details],
        footer: rodape,
    };


    pdfMake.createPdf(docDefinition).open();
}

export function createPdfRelatorioUnimed(lista){

    const reportTitle = [
        {
            text: "Relatório Unimed",
            alignment: 'center',
            fontSize: 25,
            bold: true,
            margin: [0 , 20, 0, 60]
        }

    ];

    const dados = lista.map( (item, index) => {
        const _color = index%2 === 0 ? colors.gray : '#FFFFFF'
        return [
            {text: item.user.name, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `${FormatPhone(item.user.phone)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${parseFloat(item.totalUnimedGeral).toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color},
            {text: item.status, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}
        ]
    })

    const details = [
        {
            text: format(new Date(lista[0].dateVencimento), '(MMMM/yyyy)', {locale: ptBR}), 
            alignment: 'center',
            fontSize: 15,
            margin: [0 , 2, 0, 15]
        },
        {   
			table: {
				headerRows: 1,
                widths: [ '*', '*', '*', '*',],
				body: [
					[
                        {text: 'Nome', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Telefone', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Unimed', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Status', style: 'tableHeader', fontSize: 10},
                    ],
					...dados
				]
			},
			layout: 'lightHorizontalLines'
		},
    ];

    function rodape(currentPage, pageCount){
        return[
            {
                text: currentPage.toString() + ' / ' + pageCount.toString(),
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0]
            }
        ]
    };

    const docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [15,50,15, 40],
        header: [reportTitle],
        content: [details],
        footer: rodape,
    };


    pdfMake.createPdf(docDefinition).open();
}

export function createPdfRelatorioUniodonto(lista){

    const reportTitle = [
        {
            text: "Relatório Uniodonto",
            alignment: 'center',
            fontSize: 25,
            bold: true,
            margin: [0 , 20, 0, 60]
        }

    ];

    const dados = lista.map( (item, index) => {
        const _color = index%2 === 0 ? colors.gray : '#FFFFFF'
        return [
            {text: item.user.name, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `${FormatPhone(item.user.phone)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${parseFloat(item.totalGeralUniodonto).toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color},
            {text: item.status, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}
        ]
    })

    const details = [
        {
            text: format(new Date(lista[0].dateVencimento), '(MMMM/yyyy)', {locale: ptBR}), 
            alignment: 'center',
            fontSize: 15,
            margin: [0 , 2, 0, 15]
        },
        {   
			table: {
				headerRows: 1,
                widths: [ '*', '*', '*', '*',],
				body: [
					[
                        {text: 'Nome', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Telefone', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Uniodonto', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Status', style: 'tableHeader', fontSize: 10},
                    ],
					...dados
				]
			},
			layout: 'lightHorizontalLines'
		},
    ];

    function rodape(currentPage, pageCount){
        return[
            {
                text: currentPage.toString() + ' / ' + pageCount.toString(),
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0]
            }
        ]
    };

    const docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [15,50,15, 40],
        header: [reportTitle],
        content: [details],
        footer: rodape,
    };


    pdfMake.createPdf(docDefinition).open();
}

export function createPdfRelatorioOficinas(lista){

    const reportTitle = [
        {
            text: "Relatório Oficinas",
            alignment: 'center',
            fontSize: 25,
            bold: true,
            margin: [0 , 20, 0, 60]
        }

    ];

    const dados = lista.map( (item, index) => {
        const _color = index%2 === 0 ? colors.gray : '#FFFFFF'
        return [
            {text: item.user.name, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `${FormatPhone(item.user.phone)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${parseFloat(item.totalOficinas).toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color},
            {text: item.status, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}
        ]
    })

    const details = [
        {
            text: format(new Date(lista[0].dateVencimento), '(MMMM/yyyy)', {locale: ptBR}), 
            alignment: 'center',
            fontSize: 15,
            margin: [0 , 2, 0, 15]
        },
        {   
			table: {
				headerRows: 1,
                widths: [ '*', '*', '*', '*',],
				body: [
					[
                        {text: 'Nome', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Telefone', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Oficinas', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Status', style: 'tableHeader', fontSize: 10},
                    ],
					...dados
				]
			},
			layout: 'lightHorizontalLines'
		},
    ];

    function rodape(currentPage, pageCount){
        return[
            {
                text: currentPage.toString() + ' / ' + pageCount.toString(),
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0]
            }
        ]
    };

    const docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [15,50,15, 40],
        header: [reportTitle],
        content: [details],
        footer: rodape,
    };


    pdfMake.createPdf(docDefinition).open();
}

export function createPdfRelatorioAssMedica(lista){

    const reportTitle = [
        {
            text: "Relatório Assistência Médica",
            alignment: 'center',
            fontSize: 25,
            bold: true,
            margin: [0 , 20, 0, 60]
        }

    ];

    const dados = lista.map( (item, index) => {
        const _color = index%2 === 0 ? colors.gray : '#FFFFFF'
        return [
            {text: item.user.name, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `${FormatPhone(item.user.phone)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${parseFloat(item.totalAssistencia).toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color},
            {text: item.status, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}
        ]
    })

    const details = [
        {
            text: format(new Date(lista[0].dateVencimento), '(MMMM/yyyy)', {locale: ptBR}), 
            alignment: 'center',
            fontSize: 15,
            margin: [0 , 2, 0, 15]
        },
        {   
			table: {
				headerRows: 1,
                widths: [ '*', '*', '*', '*',],
				body: [
					[
                        {text: 'Nome', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Telefone', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Assistência Médica', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Status', style: 'tableHeader', fontSize: 10},
                    ],
					...dados
				]
			},
			layout: 'lightHorizontalLines'
		},
    ];

    function rodape(currentPage, pageCount){
        return[
            {
                text: currentPage.toString() + ' / ' + pageCount.toString(),
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0]
            }
        ]
    };

    const docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [15,50,15, 40],
        header: [reportTitle],
        content: [details],
        footer: rodape,
    };


    pdfMake.createPdf(docDefinition).open();
}

export function createPdfRelatorioInadimplentes(lista){

    const reportTitle = [
        {
            text: "Relatório Inadimplentes",
            alignment: 'center',
            fontSize: 25,
            bold: true,
            margin: [0 , 20, 0, 60]
        }

    ];

    const dados = lista.map( (item, index) => {
        const _color = index%2 === 0 ? colors.gray : '#FFFFFF'
        return [
            {text: item.user.name, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${parseFloat(item?.user?.valorINSS).toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${parseFloat(item.user.planoEQTPREV).toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${parseFloat(item.totalUnimedGeral).toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${parseFloat(item.totalGeralUniodonto).toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${parseFloat(item.totalOficinas).toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${parseFloat(item.totalAssistencia).toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${parseFloat(item?.user?.valorMensalidade).toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: item.status, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}
        ]
    })

    const details = [
        {
            text: format(new Date(lista[0].dateVencimento), '(MMMM/yyyy)', {locale: ptBR}), 
            alignment: 'center',
            fontSize: 15,
            margin: [0 , 2, 0, 15]
        },
        {   
			table: {
				headerRows: 1,
                widths: [120, '*', '*', '*', '*', '*', '*', '*', '*',],
				body: [
					[
                        {text: 'Nome', style: 'tableHeader', fontSize: 10}, 
                        {text: 'INSS', style: 'tableHeader', fontSize: 10}, 
                        {text: 'EQTPREV', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Unimed', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Uniodonto', style: 'tableHeader', fontSize: 10},
                        {text: 'Oficinas', style: 'tableHeader', fontSize: 10},
                        {text: 'Ass. Médica', style: 'tableHeader', fontSize: 10},
                        {text: 'Mensalidade', style: 'tableHeader', fontSize: 10},
                        {text: 'Status', style: 'tableHeader', fontSize: 10},
                    ],
					...dados
				]
			},
			layout: 'lightHorizontalLines'
		},
    ];

    function rodape(currentPage, pageCount){
        return[
            {
                text: currentPage.toString() + ' / ' + pageCount.toString(),
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0]
            }
        ]
    };

    const docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [15,50,15, 40],
        header: [reportTitle],
        content: [details],
        footer: rodape,
    };


    pdfMake.createPdf(docDefinition).open();
}

export function createPdfRelatorioCancelamentos(lista){

    const reportTitle = [
        {
            text: "Relatório Cancelamentos",
            alignment: 'center',
            fontSize: 25,
            bold: true,
            margin: [0 , 20, 0, 60]
        }

    ];

    const dados = lista.map( (item, index) => {
        const _color = index%2 === 0 ? colors.gray : '#FFFFFF'
        return [
            {text: item.matricula, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: item.name, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `${formatCpf(item.cpf)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `${FormatPhone(item.phone)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: item.status, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color},
            {text: item.motivo, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}
        ]
    })

    const details = [
        {
            text: "", 
            alignment: 'center',
            fontSize: 15,
            margin: [0 , 2, 0, 15]
        },
        {   
			table: {
				headerRows: 1,
                widths: ['*', 120, '*', '*', '*', '*',],
				body: [
					[
                        {text: 'Matrícula', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Nome', style: 'tableHeader', fontSize: 10}, 
                        {text: 'CPF', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Telefone', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Status', style: 'tableHeader', fontSize: 10},
                        {text: 'Motivo', style: 'tableHeader', fontSize: 10},
                    ],
					...dados
				]
			},
			layout: 'lightHorizontalLines'
		},
    ];

    function rodape(currentPage, pageCount){
        return[
            {
                text: currentPage.toString() + ' / ' + pageCount.toString(),
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0]
            }
        ]
    };

    const docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [15,50,15, 40],
        header: [reportTitle],
        content: [details],
        footer: rodape,
    };


    pdfMake.createPdf(docDefinition).open();
}

export function createPdfReceitas(lista){

    const reportTitle = [
        {
            text: "Receitas",
            alignment: 'center',
            fontSize: 25,
            bold: true,
            margin: [0 , 20, 0, 60]
        }

    ];

    const dados = lista.map( (item, index) => {
        const _color = index%2 === 0 ? colors.gray : '#FFFFFF'
        return [
            {text: item.tipo, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `Referente a ${item.tipo}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${item.total.toFixed(2)}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
        ]
    })

    const details = [
        {
            text: format(new Date(lista[0].dateVencimento), '(MMMM/yyyy)', {locale: ptBR}),
            alignment: 'center',
            fontSize: 15,
            margin: [0 , 2, 0, 15]
        },
        {   
			table: {
				headerRows: 1,
                widths: ['*', '*', '*',],
				body: [
					[
                        {text: 'Item', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Descrição', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Valor', style: 'tableHeader', fontSize: 10}, 
                    ],
					...dados
				]
			},
			layout: 'lightHorizontalLines'
		},
    ];

    function rodape(currentPage, pageCount){
        return[
            {
                text: currentPage.toString() + ' / ' + pageCount.toString(),
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0]
            }
        ]
    };

    const docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [15,50,15, 40],
        header: [reportTitle],
        content: [details],
        footer: rodape,
    };


    pdfMake.createPdf(docDefinition).open();
}

export function createPdfDespesas(lista){

    const reportTitle = [
        {
            text: "Despesas",
            alignment: 'center',
            fontSize: 25,
            bold: true,
            margin: [0 , 20, 0, 60]
        }

    ];

    const dados = lista.map( (item, index) => {
        const _color = index%2 === 0 ? colors.gray : '#FFFFFF'
        return [
            {text: item.item, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: item.description, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: `R$ ${item.valor}`, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: format(item.data, 'dd/MM/yyyy'), fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
        ]
    })

    const details = [
        {
            text: format(new Date(lista[0].data), '(MMMM/yyyy)', {locale: ptBR}),
            alignment: 'center',
            fontSize: 15,
            margin: [0 , 2, 0, 15]
        },
        {   
			table: {
				headerRows: 1,
                widths: ['*', '*', '*', '*'],
				body: [
					[
                        {text: 'Item', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Descrição', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Valor', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Data', style: 'tableHeader', fontSize: 10}, 
                    ],
					...dados
				]
			},
			layout: 'lightHorizontalLines'
		},
    ];

    function rodape(currentPage, pageCount){
        return[
            {
                text: currentPage.toString() + ' / ' + pageCount.toString(),
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0]
            }
        ]
    };

    const docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [15,50,15, 40],
        header: [reportTitle],
        content: [details],
        footer: rodape,
    };


    pdfMake.createPdf(docDefinition).open();
}

export function createPdfAniversariantes(lista){

    const reportTitle = [
        {
            text: "Aniversariantes do Mês",
            alignment: 'center',
            fontSize: 25,
            bold: true,
            margin: [0 , 20, 0, 60]
        }

    ];

    const dados = lista.map( (item, index) => {
        const _color = index%2 === 0 ? colors.gray : '#FFFFFF'
        return [
            {text: item.name, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: item.dataNascimento, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}, 
            {text: item.status, fontSize: 9, margin: [0, 2, 0, 2], fillColor: _color}
        ]
    })

    const limpDate = lista[0].dataNascimento.split("/");

    const details = [
        {
            text: format(new Date(limpDate[2], limpDate[1]-1, limpDate[0]), '(MMMM)', {locale: ptBR}), 
            alignment: 'center',
            fontSize: 15,
            margin: [0 , 2, 0, 15]
        },
        {   
			table: {
				headerRows: 1,
                widths: ['*', '*', '*',],
				body: [
					[
                        {text: 'Nome', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Data', style: 'tableHeader', fontSize: 10}, 
                        {text: 'Status', style: 'tableHeader', fontSize: 10},
                    ],
					...dados
				]
			},
			layout: 'lightHorizontalLines'
		},
    ];

    function rodape(currentPage, pageCount){
        return[
            {
                text: currentPage.toString() + ' / ' + pageCount.toString(),
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0]
            }
        ]
    };

    const docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [15,50,15, 40],
        header: [reportTitle],
        content: [details],
        footer: rodape,
    };


    pdfMake.createPdf(docDefinition).open();
}