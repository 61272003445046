import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { AntDesign, SimpleLineIcons, MaterialIcons } from '@expo/vector-icons'; 

import Footer from "../components/footer";
import Header from "../components/header";
import Menu from "../components/menu";
import { responsiveFontSize } from "react-native-responsive-dimensions";
import colors from "../styles/colors";

export default function GestaoFinanceira({navigation, route}){
    return(
        <View style={styles.container}>
            <Header nav={navigation}/>
            <View style={styles.containerBody}>
                <Menu num={3} name={route.name} navigation={navigation}/>
                <View>
                    <View style={styles.containerBtn}>
                        <TouchableOpacity style={styles.btn} onPress={()=>{navigation.navigate("Receitas")}}>
                            <View style={styles.containerIcon}>
                                <SimpleLineIcons name="calculator" size={responsiveFontSize(4)} color="white" />
                            </View>
                            <Text style={styles.btnTxt}>Receitas e Despesas</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.btn} onPress={()=>{navigation.navigate("Relatorios")}}>
                            <View style={styles.containerIcon}>
                                <MaterialIcons name="list-alt" size={responsiveFontSize(5)} color="white" />
                            </View>
                            <Text style={styles.btnTxt}>Relatórios</Text>
                        </TouchableOpacity>


                    </View>
                </View>
            </View>
            <Footer/>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    containerBody: {
        flexDirection: 'row'
    },
    containerBtn: {
        flexDirection: 'row',
        alignItems: 'center',
        margin: responsiveFontSize(4)
    },
    btn: {
        backgroundColor: colors.gray,
        borderRadius: responsiveFontSize(1),
        marginHorizontal: responsiveFontSize(0.5)
    },
    containerIcon: {
        backgroundColor: colors.blue,
        borderRadius: responsiveFontSize(1),
        width: responsiveFontSize(12),
        height: responsiveFontSize(7),
        alignItems: 'center',
        justifyContent: 'center'
    },
    btnTxt: {
        fontSize: responsiveFontSize(1),
        padding: responsiveFontSize(1)
    }
})