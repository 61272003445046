import React, {useEffect, useState} from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { AntDesign, MaterialCommunityIcons  } from '@expo/vector-icons'; 

import Footer from "../components/footer";
import Header from "../components/header";
import Menu from "../components/menu";
import { responsiveFontSize } from "react-native-responsive-dimensions";
import colors from "../styles/colors";
import ModalInfoAccount from "../components/modalInfoAccount";

export default function Inicio({navigation, route}){
    const [modalInfo, setModalInfo] = useState(false)
    return(
        <View style={styles.container}>
            <Header nav={navigation}/>
            <View style={styles.containerBody}>
                <Menu num={0} name={route.name} navigation={navigation}/>
                <View>
                    <View style={styles.containerBtn}>
                        <TouchableOpacity style={styles.btn} onPress={()=>{navigation.navigate("AreaAdministrativa")}}>
                            <View style={styles.containerIcon}>
                                <AntDesign name="copy1" size={responsiveFontSize(5)} color="white" />
                            </View>
                            <Text style={styles.btnTxt}>Área Administrativa</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.btn} onPress={()=>{navigation.navigate("Relacionamento")}}>
                            <View style={[styles.containerIcon,{backgroundColor: colors.red}]}>
                                <MaterialCommunityIcons name="handshake-outline" size={responsiveFontSize(5)} color="white" />
                            </View>
                            <Text style={styles.btnTxt}>Relacionamento com Clientes</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.btn} onPress={()=>{
                                navigation.navigate("GestaoFinanceira")
                            }}>
                            <View style={styles.containerIcon}>
                                <AntDesign name="barschart" size={responsiveFontSize(5)} color="white" />
                            </View>
                            <Text style={styles.btnTxt}>Gestão Financeira</Text>
                        </TouchableOpacity>

                        {/* <TouchableOpacity style={styles.btn} onPress={()=>{
                                // navigation.navigate("ConfiguracoesSistema")
                                setModalInfo(true)
                            }}>
                            <View style={[styles.containerIcon,{backgroundColor: colors.red}]}>
                                <AntDesign name="setting" size={responsiveFontSize(5)} color="white" />
                            </View>
                            <Text style={[styles.btnTxt,{color: colors.gray3}]}>Configuração do Sistema</Text>
                        </TouchableOpacity> */}
                    </View>
                </View>
                <ModalInfoAccount
                    visible={modalInfo}
                    title={"Pausa para o café ☕"}
                    msg={"Estamos trabalhando incansavélmente para trazer novas funcionalidades 👨🏻‍💻, em breve teremos mais novidade. ✌🏻🚀"}
                    callback={(value)=>{setModalInfo(false)}}
                />
            </View>
            <Footer/>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    containerBody: {
        flexDirection: 'row'
    },
    containerBtn: {
        flexDirection: 'row',
        alignItems: 'center',
        margin: responsiveFontSize(4)
    },
    btn: {
        backgroundColor: colors.gray,
        borderRadius: responsiveFontSize(1),
        marginHorizontal: responsiveFontSize(0.5)
    },
    containerIcon: {
        backgroundColor: colors.blue,
        borderRadius: responsiveFontSize(1),
        width: responsiveFontSize(15),
        height: responsiveFontSize(10),
        alignItems: 'center',
        justifyContent: 'center'
    },
    btnTxt: {
        fontSize: responsiveFontSize(1),
        padding: responsiveFontSize(1)
    }
})