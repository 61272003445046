import React, { useState } from 'react'
import {View, StyleSheet, Modal, Text, TouchableOpacity, Picker} from 'react-native'
import { responsiveFontSize, responsiveWidth } from 'react-native-responsive-dimensions';
import colors from '../styles/colors';


export default function ModalRemove({visible, title, msg, callback}){
    return(
        <View style={styles.centeredView}>
            <Modal
                animationType='fade'
                transparent={true}
                visible={visible}>

                <View style={styles.centeredModal}>
                    <View style={styles.modalView}>
                        <Text style={styles.title}>{title}</Text>
                        <Text style={styles.txt}>{msg}</Text>

                        <View style={styles.containerInput}>
                            <TouchableOpacity style={styles.btn} onPress={()=>{callback('não')}}>
                                <Text style={styles.btnTxt}>Voltar</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.btn,{backgroundColor: colors.red}]} onPress={()=>{callback("sim")}}>
                                <Text style={styles.btnTxt}>Confirmar</Text>
                            </TouchableOpacity>
                        </View>
                        
                    </View>
                </View>
            </Modal>
        </View>
    )

}

const styles = StyleSheet.create({
    centeredView: {
      flex: 1
    },
    centeredModal: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#000000aa'
    },
    modalView: {
        marginHorizontal: responsiveFontSize(1),
        backgroundColor: 'white',
        borderRadius: 5,
        padding: responsiveFontSize(1),
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    title: {
        fontSize: responsiveFontSize(1.5),
        width: responsiveWidth(30),
        fontWeight: "bold"
    },
    txt: {
        fontSize: responsiveFontSize(1),
        color: colors.gray3,
        paddingTop: responsiveFontSize(0.5),
        paddingBottom: responsiveFontSize(1),
        marginLeft: responsiveFontSize(0.5)
    },

    containerInput: {
        flexDirection: 'row',
        justifyContent: 'center'
    },

    //btn
    btn: {
        width: responsiveFontSize(10),
        padding: responsiveFontSize(0.5),
        backgroundColor: colors.gray3,
        borderRadius: responsiveFontSize(0.5),
        marginVertical: responsiveFontSize(1),
        marginHorizontal: responsiveFontSize(0.5),
        alignItems: 'center'
    },
    btnTxt: {
        fontSize: responsiveFontSize(1),
        color: "white"
    }
    
});
