import React, {useEffect, useState} from "react";
import { View, StyleSheet, TextInput, TouchableOpacity, Text, ScrollView, Image } from "react-native"
import { responsiveFontSize, responsiveWidth } from "react-native-responsive-dimensions";
import * as Print from 'expo-print';

import Footer from "../components/footer";
import Header from "../components/header";
import Menu from "../components/menu";
import colors from "../styles/colors";
import { FormatPhone } from "../utils/formatPhone";
import { LoadOneUser } from "../services/firebaseServices";
import ModalInfoAccount from "../components/modalInfoAccount";
import LoadingModal from "../components/loadingModal";
import { formatCpf } from "../utils/formatCpf";
import { formatDate } from "../utils/formatDate";
import { format } from "date-fns";
import { _pickDoc } from "../utils/uploadDoc";
import { formatPrice } from "../utils/formatPrice";
import { createListPdf } from "../utils/createPDF";

const pathRef = "/Fornecedores"
export default function Recibo({navigation, route}){
    const [user, setUser] = useState(null)

    const [name, setName] = useState(null)
    const [cpf, setCpf] = useState(null)
    const [phone, setPhone] = useState(null)
    const [email, setEmail] = useState(null)
    const [endereco, setEndereco] = useState(null)

    const [servico, setServico] = useState(null)
    const [valorServico, setValorServico] = useState(null)

    //payment
    const [pagoPor, setPagoPor] = useState(null)
    const [recebidoPor, setRecebidoPor] = useState(null)
    const [data, setData] = useState(format(new Date(),"dd/MM/yyyy"))
    const [nRecibo, setNRecebido] = useState(null)

    // modal info
    const [visibleModalInfo, setVisibleModalInfo] = useState(false)
    const [titleModalInfo, setTitleModalInfo] = useState(null)
    const [msgModalInfo, setMsgModalInfo] = useState(null)
    // ModalLoading
    const [loading, setLoading] = useState(false)

    const [thisPrint, setThisPrint] = useState(false)

    useEffect(()=>{
        let key = route.params?.id;
        if(key !== undefined && key !== ''){
            LoadOneUser(pathRef, key, data => setUser(data))
        }
    },[])

    useEffect(()=>{
        if(user !== null){
            setName(user.name)
            setCpf(user.cpf)
            setPhone(user.phone)
            setEndereco(user.endereco)
            setEmail(user.email)
            
            setServico(user.servico)
            setValorServico(user.valorServico)
            
        }
    },[user])

    useEffect(()=>{
        if(data !== null && data !== undefined && data !== ''){
            let dtAt = format(new Date(), "yyyy")
            let dt = data.split("/")
            if(dt[2] > dtAt){
                setTitleModalInfo("Essa não 🤔")
                setMsgModalInfo(`Parece que você esqueceu de tomar seu café ☕ e informou uma data inválida no campo "Data de Entrada", por favor verifique a data e tente novamente ✌🏻`)
                setVisibleModalInfo(true)
            }
        }
    },[data])

    async function print(){
        let result = await Print.printAsync({
            width: responsiveWidth(100), 
            orientation: "landscape",
            base64: true
        });
        //  console.log(result)
         if(result === undefined){
            setThisPrint(false)
         }
    };

    return(
        <View style={styles.container}>
            {thisPrint === false && (<Header nav={navigation}/>)}
                <View style={styles.containerBody}>
                {thisPrint === false && (<Menu num={1} name={"Fornecedores"} navigation={navigation}/>)}
                    <View style={{width: responsiveFontSize(80)}}>
                        
                        <Text style={styles.txtTitle}>Recibo</Text>
                        <ScrollView style={styles.form}>
                            <View style={styles.containerHeader}>
                                <Image style={styles.imgRecibo} source={{uri: "https://firebasestorage.googleapis.com/v0/b/as4celpa.appspot.com/o/Logo%20AsaCelpa.png?alt=media&token=ef7e6d9c-564a-4431-ad14-7493b2a3f608"}}/>
                                <Text style={styles.titleHeader}>Recibo</Text>
                                <View style={styles.containerInfoHeader}>
                                    <View style={styles.horizontal}>
                                        <Text style={[styles.txtLabel,{width: responsiveFontSize(4.5)}]}>N° Recibo:</Text>
                                        <TextInput
                                            style={styles.input2}
                                            placeholder=''
                                            onChangeText={setNRecebido}
                                            value={nRecibo}
                                            keyboardType={'default'}
                                        />
                                    </View>
                                    <View style={styles.horizontal}>
                                        <Text style={[styles.txtLabel,{width: responsiveFontSize(3)}]}>Valor:</Text>
                                        <TextInput
                                            style={styles.input2}
                                            placeholder=''
                                            onChangeText={text => setValorServico(formatPrice(text))}
                                            value={valorServico}
                                            keyboardType={'default'}
                                        />
                                    </View>
                                </View>
                            </View>
                            <View style={styles.containerCategory}>
                                <View style={styles.containerInput}>
                                    <View style={styles.horizontal}>
                                        <Text style={styles.txtLabel}>Fornecedor:</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholder=''
                                            onChangeText={setName}
                                            value={name}
                                            keyboardType={'default'}
                                        />
                                    </View>
                                    <View style={styles.horizontal}>
                                        <Text style={styles.txtLabel}>CPF ou CNPJ:</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholder=''
                                            onChangeText={setCpf}
                                            value={formatCpf(cpf)}
                                            maxLength={18}
                                            keyboardType={'default'}
                                        />
                                    </View>
                                    <View style={styles.horizontal}>
                                        <Text style={[styles.txtLabel,{width: responsiveFontSize(4.5)}]}>Telefone:</Text>
                                        <TextInput
                                            style={[styles.input,{width: responsiveFontSize(10)}]}
                                            placeholder=''
                                            onChangeText={setPhone}
                                            maxLength={15}
                                            value={FormatPhone(phone)}
                                            keyboardType={'default'}
                                        />
                                    </View>
                                </View>

                                <View style={styles.containerInput}>
                                    
                                    <View style={styles.horizontal}>
                                        <Text style={styles.txtLabel}>Endereço:</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholder=''
                                            onChangeText={setEndereco}
                                            value={endereco}
                                            keyboardType={'default'}
                                        />
                                    </View>
                                    <View style={styles.horizontal}>
                                        <Text style={[styles.txtLabel,{textAlign: 'center'}]}>E-mail:</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholder=''
                                            onChangeText={setEmail}
                                            value={email}
                                            keyboardType={'default'}
                                        />
                                    </View>
                                    <View style={{width: responsiveFontSize(15.5)}}/>

                                </View>

                                
                            </View>

                            <View style={styles.containerCategory}>
                                <View style={styles.containerInput}>
                                    <View>
                                        <Text style={[styles.txtLabel,{width: responsiveFontSize(10)}]}>Descrição do Serviço:</Text>
                                        <Text style={styles.txtService}>{servico}</Text>
                                    </View>

                                </View>
                            </View>

                            <View style={styles.containerCategory}>
                                <View style={styles.containerInput}>
                                    <View style={styles.horizontal}>
                                        <Text style={styles.txtLabel}>Pago por:</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholder=''
                                            onChangeText={setPagoPor}
                                            value={pagoPor}
                                            keyboardType={'default'}
                                        />
                                    </View>
                                    <View style={styles.horizontal}>
                                        <Text style={styles.txtLabel}>Recebido por:</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholder=''
                                            onChangeText={setRecebidoPor}
                                            value={recebidoPor}
                                            keyboardType={'default'}
                                        />
                                    </View>
                                    <View style={styles.horizontal}>
                                        <Text style={[styles.txtLabel,{width: responsiveFontSize(3)}]}>Data:</Text>
                                        <TextInput
                                            style={[styles.input,{width: responsiveFontSize(10)}]}
                                            placeholder=''
                                            onChangeText={text => setData(formatDate(text))}
                                            value={data}
                                            maxLength={10}
                                            keyboardType={'default'}
                                        />
                                    </View>
                                </View>

                                
                            </View>
                            <ModalInfoAccount 
                                visible={visibleModalInfo}
                                title={titleModalInfo}
                                msg={msgModalInfo}
                                callback={({value})=>{
                                    setVisibleModalInfo(false)
                                }}/>

                            <LoadingModal 
                                visible={loading}
                                msg={'Carregando, aguarde...'}
                            />
                            {/* Fim modals */}
                            {thisPrint === false && (
                                <View style={styles.containerInput}>
                                    <TouchableOpacity style={[styles.btn,{backgroundColor: colors.gray3}]} onPress={()=>{navigation.goBack()}}>
                                        <Text style={styles.btnTxt}>Voltar</Text>
                                    </TouchableOpacity>
                                    
                                    <TouchableOpacity style={styles.btn} onPress={()=>{
                                        setThisPrint(true);
                                        setTimeout(()=>{print()},500)
                                        // print()
                                        // createListPdf()
                                        }}>
                                        <Text style={styles.btnTxt}>Imprimir</Text>
                                    </TouchableOpacity>
                                </View>
                            )}
                        </ScrollView>
                    </View>
                </View>
            {thisPrint === false && (<Footer/>)}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    containerBody: {
        flexDirection: 'row'
    },
    txtTitle: {
        width: responsiveFontSize(79),
        fontSize: responsiveFontSize(1.5),
        color: colors.gray3,
        padding: responsiveFontSize(0.5),
        borderBottomWidth: responsiveFontSize(0.1),
        borderBottomColor: colors.gray2,
        marginLeft: responsiveFontSize(1.5)
    },
    form: {
        marginLeft: responsiveFontSize(1.5),
        width: responsiveFontSize(79),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        borderRadius: responsiveFontSize(0.5),
        borderTopWidth: 0,
        borderTopEndRadius: 0,
        borderTopStartRadius: 0,
        padding: responsiveFontSize(0.5)
    },
    // header Recibo
    containerHeader: {
        flexDirection: "row",
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: responsiveFontSize(4)
    },
    imgRecibo: {
        width: responsiveFontSize(4),
        height: responsiveFontSize(4)
    },
    titleHeader: {
        fontSize: responsiveFontSize(2),
        fontWeight: "bold"
    },
    containerInfoHeader: {
        flexDirection: 'row'
    },
    horizontal: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    containerCategory: {
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        marginTop: responsiveFontSize(0.5),
        padding: responsiveFontSize(0.5)
    },
    containerInput: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    txtLabel: {
        width: responsiveFontSize(6),
        fontSize: responsiveFontSize(0.8),
        fontWeight: "bold",
        paddingVertical: responsiveFontSize(1)
    },
    input: {
        width: responsiveFontSize(20),
        height: responsiveFontSize(2),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        borderRadius: responsiveFontSize(0.5),
        fontSize: responsiveFontSize(1),
        padding: responsiveFontSize(0.2),
        marginRight: responsiveFontSize(1   )
    },
    input2: {
        width: responsiveFontSize(8),
        height: responsiveFontSize(2),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        borderRadius: responsiveFontSize(0.5),
        fontSize: responsiveFontSize(1),
        padding: responsiveFontSize(0.2),
        marginRight: responsiveFontSize(1   )
    },

    //btn
    btn: {
        width: responsiveFontSize(15),
        padding: responsiveFontSize(0.5),
        backgroundColor: colors.blue,
        borderRadius: responsiveFontSize(0.5),
        marginVertical: responsiveFontSize(1),
        alignItems: 'center'
    },
    btnTxt: {
        fontSize: responsiveFontSize(1),
        color: "white"
    },
    txtService: {
        fontSize: responsiveFontSize(0.8)
    }
    
})