import React, {useState, useEffect} from "react"
import { View, Text, StyleSheet, TouchableOpacity} from "react-native"
import { responsiveFontSize, responsiveHeight } from "react-native-responsive-dimensions"
import { AntDesign } from '@expo/vector-icons'; 

import colors from "../styles/colors"

export default function Menu({navigation, num, name, sub = 0}){
    const [selected, setSelected] = useState(num)
    const [selectedSub, setSelectedSub] = useState(sub)
    return(
        <View style={styles.container}>
            <View style={name !== "Inicio" ? styles.containerBtn : styles.containerBtnActive}>
                <TouchableOpacity style={styles.btn} onPress={()=>{navigation.navigate("Inicio")}}>
                    <Text style={styles.txt}>Inicio</Text>
                </TouchableOpacity>
            </View>
            <View style={styles.containerBtn}>
                <TouchableOpacity style={name === "AreaAdministrativa" ? styles.btnActive : styles.btn} onPress={()=>{navigation.navigate("AreaAdministrativa")}}>
                    <Text style={styles.txt}>Área Administrativa</Text>
                    <AntDesign name={selected !== 1 ? "right" : "down"} size={responsiveFontSize(1)} color="black" />
                </TouchableOpacity>
                {selected === 1 && (
                    <>
                    <TouchableOpacity style={name === "Associados" ? styles.btnSecundActive : styles.btnSecund} onPress={()=>{navigation.navigate("Associados")}}>
                        <Text style={styles.btnSecundtxt}>Associados</Text>
                        <AntDesign name={"right"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                    </TouchableOpacity>
                    <TouchableOpacity style={name === "Funcionarios" ? styles.btnSecundActive : styles.btnSecund} onPress={()=>{navigation.navigate("Funcionarios")}}>
                        <Text style={styles.btnSecundtxt}>Funcionários</Text>
                        <AntDesign name={"right"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                    </TouchableOpacity>
                    <TouchableOpacity style={name === "Fornecedores" ? styles.btnSecundActive : styles.btnSecund} onPress={()=>{navigation.navigate("Fornecedores")}}>
                        <Text style={styles.btnSecundtxt}>Fornecedores</Text>
                        <AntDesign name={"right"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                    </TouchableOpacity>
                    </>
                )}

            </View>
            <View style={styles.containerBtn}>
                <TouchableOpacity style={name === "Relacionamento" ? styles.btnActive : styles.btn} onPress={()=>{navigation.navigate("Relacionamento")}}>
                    <Text style={styles.txt}>Relacionamento com Clientes</Text>
                    <AntDesign name={selected !== 2 ? "right" : "down"} size={responsiveFontSize(1)} color="black" />
                </TouchableOpacity>
                {selected === 2 && (
                    <>
                    <TouchableOpacity style={name === "Aniversariantes" ? styles.btnSecundActive : styles.btnSecund} onPress={()=>{navigation.navigate("Aniversariantes")}}>
                        <Text style={styles.btnSecundtxt}>Aniversariantes</Text>
                        <AntDesign name={"right"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                    </TouchableOpacity>
                    {/* <TouchableOpacity style={name === "EnvioMensagem" ? styles.btnSecundActive : styles.btnSecund} onPress={()=>{
                            // navigation.navigate("EnvioMensagem")
                        }}>
                        <Text style={styles.btnSecundtxt}>Mensagens (Em breve)</Text>
                        <AntDesign name={"right"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                    </TouchableOpacity> */}
                    </>
                )}

            </View>
            <View style={styles.containerBtn}>
                <TouchableOpacity style={name === "GestaoFinanceira" ? styles.btnActive : styles.btn} onPress={()=>{
                        navigation.navigate("GestaoFinanceira")
                    }}>
                    <Text style={styles.txt}>Gestão Financeira</Text>
                    <AntDesign name={selected !== 3 ? "right" : "down"} size={responsiveFontSize(1)} color="black"/>
                </TouchableOpacity>
                {selected === 3 && (
                    <>
                    <TouchableOpacity style={name === "Receitas" ? styles.btnSecundActive : styles.btnSecund} onPress={()=>{navigation.navigate("Receitas")}}>
                        <Text style={styles.btnSecundtxt}>Receitas e Despesas</Text>
                        <AntDesign name={"right"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                    </TouchableOpacity>
                    {/* <TouchableOpacity style={name === "Contas" ? styles.btnSecundActive : styles.btnSecund} onPress={()=>{}}>
                        <Text style={styles.btnSecundtxt}>Contas</Text>
                        <AntDesign name={selectedSub !== 1 ? "right" : "down"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                    </TouchableOpacity>
                    {selectedSub === 1 && (
                        <>
                            <TouchableOpacity style={name === "Apagar" ? [styles.btnSecundActive,{marginLeft: responsiveFontSize(2)}] :[ styles.btnSecund,{marginLeft: responsiveFontSize(2)}]} onPress={()=>{}}>
                                <Text style={styles.btnSecundtxt}>A pagar</Text>
                                <AntDesign name={"right"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                            </TouchableOpacity>

                            <TouchableOpacity style={name === "Areceber" ? [styles.btnSecundActive,{marginLeft: responsiveFontSize(2)}] :[ styles.btnSecund,{marginLeft: responsiveFontSize(2)}]} onPress={()=>{}}>
                                <Text style={styles.btnSecundtxt}>A receber</Text>
                                <AntDesign name={"right"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                            </TouchableOpacity>
                        </>
                    )} */}
                    <TouchableOpacity style={name === "Relatorios" ? styles.btnSecundActive : styles.btnSecund} onPress={()=>{navigation.navigate("Relatorios")}}>
                        <Text style={styles.btnSecundtxt}>Relatórios</Text>
                        <AntDesign name={selectedSub !== 2 ? "right" : "down"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                    </TouchableOpacity>
                    {selectedSub === 2 && (
                        <>
                            <TouchableOpacity style={name === "RUnimed" ? [styles.btnSecundActive,{marginLeft: responsiveFontSize(2)}] :[ styles.btnSecund,{marginLeft: responsiveFontSize(2)}]} 
                                onPress={()=>{navigation.navigate("RUnimed")}}>
                                <Text style={styles.btnSecundtxt}>Unimed</Text>
                                <AntDesign name={"right"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                            </TouchableOpacity>

                            <TouchableOpacity style={name === "RUniodonto" ? [styles.btnSecundActive,{marginLeft: responsiveFontSize(2)}] :[ styles.btnSecund,{marginLeft: responsiveFontSize(2)}]} 
                                onPress={()=>{navigation.navigate("RUniodonto")}}>
                                <Text style={styles.btnSecundtxt}>Uniodonto</Text>
                                <AntDesign name={"right"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                            </TouchableOpacity>

                            <TouchableOpacity style={name === "ROficinas" ? [styles.btnSecundActive,{marginLeft: responsiveFontSize(2)}] :[ styles.btnSecund,{marginLeft: responsiveFontSize(2)}]} 
                                onPress={()=>{navigation.navigate("ROficinas")}}>
                                <Text style={styles.btnSecundtxt}>Oficinas</Text>
                                <AntDesign name={"right"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                            </TouchableOpacity>

                            <TouchableOpacity style={name === "RAssMedica" ? [styles.btnSecundActive,{marginLeft: responsiveFontSize(2)}] :[ styles.btnSecund,{marginLeft: responsiveFontSize(2)}]} 
                                onPress={()=>{navigation.navigate("RAssMedica")}}>
                                <Text style={styles.btnSecundtxt}>Assistência Médica</Text>
                                <AntDesign name={"right"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                            </TouchableOpacity>

                            <TouchableOpacity style={name === "RInadimplentes" ? [styles.btnSecundActive,{marginLeft: responsiveFontSize(2)}] :[ styles.btnSecund,{marginLeft: responsiveFontSize(2)}]} 
                                onPress={()=>{navigation.navigate("RInadimplentes")}}>
                                <Text style={styles.btnSecundtxt}>Inadimplentes</Text>
                                <AntDesign name={"right"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                            </TouchableOpacity>

                            <TouchableOpacity style={name === "RCancelamentos" ? [styles.btnSecundActive,{marginLeft: responsiveFontSize(2)}] :[ styles.btnSecund,{marginLeft: responsiveFontSize(2)}]} 
                                onPress={()=>{navigation.navigate("RCancelamentos")}}>
                                <Text style={styles.btnSecundtxt}>Cancelamentos</Text>
                                <AntDesign name={"right"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                            </TouchableOpacity>
                        </>
                    )}
                    
                    </>
                )}

            </View>
            {/* <View style={styles.containerBtn}>
                <TouchableOpacity style={name === "ConfiguracoesSistema" ? styles.btnActive : styles.btn} onPress={()=>{
                        // navigation.navigate("ConfiguracoesSistema")
                    }}>
                    <Text style={[styles.txt, {color: colors.gray3}]}>Configuração do Sistema (Em breve)</Text>
                    <AntDesign name={selected !== 4 ? "right" : "down"} size={responsiveFontSize(1)} color={colors.gray3} />
                </TouchableOpacity>
                {selected === 4 && (
                    <>
                    <TouchableOpacity style={name === "Cancelamentos" ? styles.btnSecundActive : styles.btnSecund} onPress={()=>{navigation.navigate("Cancelamentos")}}>
                        <Text style={styles.btnSecundtxt}>Gerenciar Cancelamentos</Text>
                        <AntDesign name={"right"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                    </TouchableOpacity>
                    <TouchableOpacity style={name === "PlanosSaude" ? styles.btnSecundActive : styles.btnSecund} onPress={()=>{navigation.navigate("PlanosSaude")}}>
                        <Text style={styles.btnSecundtxt}>Planos de Saúde</Text>
                        <AntDesign name={"right"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.btnSecund} onPress={()=>{}}>
                        <Text style={styles.btnSecundtxt}>Convênios</Text>
                        <AntDesign name={"right"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.btnSecund} onPress={()=>{}}>
                        <Text style={styles.btnSecundtxt}>Relatórios</Text>
                        <AntDesign name={"right"} style={styles.setaSecund} size={responsiveFontSize(0.8)} color="black" />
                    </TouchableOpacity>
                    </>
                )}

            </View> */}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: responsiveFontSize(20),
        height: responsiveHeight(89.3),
        backgroundColor: colors.gray,
        justifyContent: 'center'
    },
    containerBtn: {
        borderTopWidth: responsiveFontSize(0.1),
        borderTopColor: colors.gray2,
    },
    containerBtnActive: {
        borderTopWidth: responsiveFontSize(0.1),
        borderTopColor: colors.gray2,
        backgroundColor: colors.gray2
    },
    btn: {
        padding: responsiveFontSize(1),
        flexDirection: 'row',
        alignItems: 'center'
    },
    btnActive: {
        padding: responsiveFontSize(1),
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: colors.gray2
    },
    txt: {
        fontSize: responsiveFontSize(1),
        marginRight: 'auto'
    },
    btnSecund: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: responsiveFontSize(0.5),
    },
    btnSecundActive: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: responsiveFontSize(0.5),
        backgroundColor: colors.gray2
    },
    btnSecundtxt: {
        fontSize: responsiveFontSize(0.8),
        marginRight: 'auto',
        marginLeft: responsiveFontSize(2),
        color: colors.gray3
    },
    setaSecund: {
        marginRight: responsiveFontSize(1)
    }
})