// import Constants from 'expo-constants';
import * as Linking from 'expo-linking';
const MP_ACCESS_TOKEN = "TEST-1331000816481207-022618-ba0f96352f5eb1eed2b1226eaccfd7cb-146268888"
export async function getPreferenceId (payer, total, description){
  let redirectUrlSucess = Linking.createURL('confirmeReserva', {
    queryParams: { status: 'sucess' },
  });
  let redirectUrlPending = Linking.createURL('confirmeReserva', {
    queryParams: { status: 'pending' },
  });
  let redirectUrlFailure = Linking.createURL('confirmeReserva', {
    queryParams: { status: 'failure' },
  });
  // console.log("user: ", payer)
    const response = await fetch(
      `https://api.mercadopago.com/v1/payments?access_token=${MP_ACCESS_TOKEN}`,
      {
        method: 'POST',
        body: JSON.stringify({
          transaction_amount: total,
          description,
          payment_method_id: "bolbradesco",
          payer
        })
      }
    );
  
    const preference = await response.json();
    //  console.log(preference)
    return preference;
};
export async function cancelPayment(id){
  const response = await fetch(
    `https://api.mercadopago.com/v1/payments/${id}/refunds?access_token=${MP_ACCESS_TOKEN}`);
  
  const result = await response.json();
  return result;
}

export async function checkPayment(id){
  const response = await fetch(
    `https://api.mercadopago.com/v1/payments/${id}?access_token=${MP_ACCESS_TOKEN}`);
  
  const result = await response.json();
  return result;
}
 