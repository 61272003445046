import React, {useEffect, useState} from "react";
import { View, StyleSheet, TextInput, TouchableOpacity, Text, ScrollView, Picker, FlatList } from "react-native"
import { responsiveFontSize } from "react-native-responsive-dimensions";
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'

import Footer from "../components/footer";
import Header from "../components/header";
import HeaderAssociados from "../components/headerAssociados";
import Menu from "../components/menu";
import ModalCancelAssociado from "../components/modalCancelAssociado";
import colors from "../styles/colors";
import { FormatPhone } from "../utils/formatPhone";
import { checkMatricula, LoadOneUser, RemoveDep, RemoveUser, saveUser } from "../services/firebaseServices";
import ModalInfoAccount from "../components/modalInfoAccount";
import LoadingModal from "../components/loadingModal";
import { generateUUID } from "../utils/generateUID";
import { formatCpf } from "../utils/formatCpf";
import { formatDate } from "../utils/formatDate";
import { format } from "date-fns";
import { _pickDoc } from "../utils/uploadDoc";
import ModalRemove from "../components/modalRemove";



const pathRef = "/Users"
export default function NovoDependente({navigation, route}){
    const [user, setUser] = useState(null)

    // vinculo para dependente
    const [idAssociado, setIdAssociado] = useState(null)

    const [status, setStatus] = useState("Ativo")
    const [motivo, setMotivo] = useState(null)
    const [tpVinculo, setTpVinculo] = useState('Dependente')
    const [matricula, setMatricula] = useState(null)
    const [dtEntrada, setDtEntrada] = useState(format(new Date(),"dd/MM/yyyy"))
    const [name, setName] = useState(null)
    const [cpf, setCpf] = useState(null)
    const [rg, setRg] = useState(null)
    const [phone, setPhone] = useState(null)
    const [email, setEmail] = useState(null)
    const [dataNascimento, setDataNascimento] = useState(null)
    const [docDigital, setDocDigital] = useState(null)

    // modal info
    const [visibleCancelModal, setVisibleCancelModal] = useState(false)
    const [visibleModalInfo, setVisibleModalInfo] = useState(false)
    const [titleModalInfo, setTitleModalInfo] = useState(null)
    const [msgModalInfo, setMsgModalInfo] = useState(null)
    // ModalLoading
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        let key = route.params?.id;
        let idA = route.params?.idAssociado;
        if(key !== undefined && key !== ''){
            LoadOneUser(pathRef, key, data => setUser(data))
        }
        if(idA !== undefined && idA !== ''){
            // console.log("Ida: ", idA)
            setIdAssociado(idA)
        }
    },[])

    useEffect(()=>{
        if(user !== null){
            setTpVinculo(user.tpVinculo)
            setMatricula(user.matricula)
            setDtEntrada(user.dtEntrada)
            setName(user.name)
            setCpf(user.cpf)
            setRg(user.rg)
            setPhone(user.phone)
            setDataNascimento(user.dataNascimento)
            setEmail(user.email)
            setStatus(user.status)
            setIdAssociado(user.idAssociado)
            if(user.docDigital !== undefined){
                setDocDigital(user.docDigital)
            }
            if(user.motivo !== undefined){
                setMotivo(user.motivo)
            }
            
        }
    },[user])

    useEffect(()=>{
        if(dtEntrada !== null && dtEntrada !== undefined && dtEntrada !== ''){
            let dtAt = format(new Date(), "yyyy")
            let dt = dtEntrada.split("/")
            if(dt[2] > dtAt){
                setTitleModalInfo("Essa não 🤔")
                setMsgModalInfo(`Parece que você esqueceu de tomar seu café ☕ e informou uma data inválida no campo "Data de Entrada", por favor verifique a data e tente novamente ✌🏻`)
                setVisibleModalInfo(true)
            }
        }
    },[dtEntrada])

    function gerarMatricula(){
        let nM = 0;
        let exists = true;
        setLoading(true)
        switch(exists){
            case true:
                nM = generateUUID(6)
                checkMatricula(pathRef, nM, data =>{exists = data})
            case false:
                setMatricula(nM)
                setLoading(false)
                break;

        }
    }

    function salvar(){
        if(matricula !== null && dtEntrada !== null && name !== null && cpf !== null && rg !== null && dataNascimento !== null
        && phone !== null && email !== null){
            setLoading(true)
            let _user = {
                status,
                motivo,
                tpVinculo,
                matricula,
                dtEntrada,
                name,
                cpf,
                rg,
                dataNascimento,
                phone,
                email,
                docDigital,
                idAssociado
            }
            saveUser(pathRef, _user, data =>{
                if(data){
                    setLoading(false)
                    setTitleModalInfo("Cadastro 📃")
                    setMsgModalInfo("Informações salvas com sucesso ✅")
                    setVisibleModalInfo(true)
                }else{
                    setLoading(false)
                    setTitleModalInfo("Cadastro 📃")
                    setMsgModalInfo("IParece que algo deu errado, tente novamente 🤔")
                    setVisibleModalInfo(true)
                }
            })
        }else{
            setTitleModalInfo("Atenção 🚨")
            setMsgModalInfo("Parece que você esqueceu de informar alguns dados, preencha-os e tente novamente. ✌🏻")
            setVisibleModalInfo(true)
        }
    }

    return(
        <View style={styles.container}>
            <Header nav={navigation}/>
                <View style={styles.containerBody}>
                    <Menu num={1} name={"Associados"} navigation={navigation}/>
                    <View style={{width: responsiveFontSize(80)}}>
                        <View style={{height: responsiveFontSize(1)}}/>
                        <Text style={styles.txtTitle}>Dependentes</Text>
                        <ScrollView style={styles.form}>
                            {/* Dados Pessoais */}
                            <Text style={[styles.txtLabel,{marginTop: responsiveFontSize(1), fontSize: responsiveFontSize(1.2)}]}>Dados Pessoais</Text>
                            <View style={styles.containerInput}>
                                    <View>
                                        <Text style={styles.txtLabel}>Tipo de Vínculo</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholder=''
                                            onChangeText={setTpVinculo}
                                            value={tpVinculo}
                                            keyboardType={'default'}
                                            editable={false}
                                        />
                                    </View>
                                    <View>
                                        <Text style={styles.txtLabel}>Matrícula</Text>
                                        <View style={{flexDirection: 'row'}}>
                                            <TextInput
                                                style={[styles.input,{width: responsiveFontSize(15)}]}
                                                placeholder=''
                                                onChangeText={setMatricula}
                                                value={matricula}
                                                keyboardType={'default'}
                                                editable={matricula === null ? true : false}
                                            />
                                            <TouchableOpacity disabled={matricula === null ? false : true} style={styles.btnGerarMatricula} onPress={()=>{gerarMatricula()}}>
                                                <MaterialCommunityIcons name="numeric" size={responsiveFontSize(1)} color="white" />
                                                <Text style={styles.txtBtnGerar}>Gerar</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                    <View>
                                        <Text style={styles.txtLabel}>Data de Entrada</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholder=''
                                            onChangeText={text => setDtEntrada(formatDate(text))}
                                            value={dtEntrada}
                                            maxLength={10}
                                            keyboardType={'default'}
                                        />
                                    </View>
                            </View>

                            <View style={styles.containerInput}>
                                <View>
                                    <Text style={styles.txtLabel}>Nome Completo</Text>
                                    <TextInput
                                        style={styles.input}
                                        placeholder=''
                                        onChangeText={setName}
                                        value={name}
                                        keyboardType={'default'}
                                    />
                                </View>
                                <View>
                                    <Text style={styles.txtLabel}>CPF</Text>
                                    <TextInput
                                        style={styles.input}
                                        placeholder=''
                                        onChangeText={setCpf}
                                        value={formatCpf(cpf)}
                                        maxLength={14}
                                        keyboardType={'default'}
                                    />
                                </View>
                                <View>
                                    <Text style={styles.txtLabel}>RG</Text>
                                    <TextInput
                                        style={styles.input}
                                        placeholder=''
                                        onChangeText={setRg}
                                        value={rg}
                                        keyboardType={'default'}
                                    />
                                </View>
                            </View>

                            <View style={styles.containerInput}>
                                <View>
                                    <Text style={styles.txtLabel}>Telefone</Text>
                                    <TextInput
                                        style={styles.input}
                                        placeholder=''
                                        onChangeText={setPhone}
                                        maxLength={15}
                                        value={FormatPhone(phone)}
                                        keyboardType={'default'}
                                    />
                                </View>
                                <View>
                                    <Text style={styles.txtLabel}>Data de Nascimento</Text>
                                    <TextInput
                                        style={styles.input}
                                        placeholder=''
                                        onChangeText={text => setDataNascimento(formatDate(text))}
                                        value={dataNascimento}
                                        maxLength={10}
                                        keyboardType={'default'}
                                    />
                                </View>
                                <View>
                                    <Text style={styles.txtLabel}>E-mail</Text>
                                    <TextInput
                                        style={styles.input}
                                        placeholder=''
                                        onChangeText={setEmail}
                                        value={email}
                                        keyboardType={'default'}
                                    />
                                </View>

                            </View>
                            {/* doc Digital */}
                            <Text style={styles.txtLabel}>Documentos Digitalizados</Text>
                            <Text style={styles.txtDoc}>É importante que crie um pdf com todos os documentos digitalizados e envie somente um arquivo.</Text>
                            <View style={styles.containerDocDigital}>
                                <TouchableOpacity style={[styles.btnMensalidade,{width: responsiveFontSize(5), backgroundColor: colors.gray3, marginTop: 0, marginRight: responsiveFontSize(1)}]} onPress={()=>{
                                        if(user !== undefined && user !== null){
                                            _pickDoc("/Users",user.key,"doc-digitalizado", load => setLoading(load), link => setDocDigital(link))
                                        
                                        }else{
                                            setTitleModalInfo("Atenção 🚨");
                                            setMsgModalInfo("Para enviar o pdf dos documentos digitalizados, primeiro você precisa informar as informações de dados pessoais e apertar em SALVAR.")
                                            setVisibleModalInfo(true)
                                        }
                                }}>
                                    <Ionicons name="ios-document-text-outline" size={responsiveFontSize(2)} color="white" />
                                    <Text style={styles.txtUniodonto}>{docDigital === null ? "Enviar" : "Alterar"}</Text> 
                                </TouchableOpacity>
                                {docDigital !== null && (
                                    <TouchableOpacity style={[styles.btnMensalidade,{width: responsiveFontSize(5), backgroundColor: colors.blue,  marginTop: 0}]} onPress={()=>{window.open(docDigital)}}>
                                        <MaterialCommunityIcons  name="file-eye-outline" size={responsiveFontSize(2)} color="white" />
                                        <Text style={styles.txtUniodonto}>Ver</Text>
                                    </TouchableOpacity>
                                )}
                            </View>
                            {/* Modals */}

                            <ModalInfoAccount 
                                visible={visibleModalInfo}
                                title={titleModalInfo}
                                msg={msgModalInfo}
                                callback={({value})=>{
                                    setVisibleModalInfo(false)
                                    if(status === "Inativo"){
                                        navigation.goBack();
                                    }
                                }}/>
                            
                            <ModalRemove
                                visible={visibleCancelModal}
                                title={"Deseja mesmo remover este cadastro?"}
                                msg={"Essa ação é irreversível"}
                                callback={(value)=>{
                                    setVisibleCancelModal(false)
                                    if(value === "sim"){
                                        setLoading(true)
                                        RemoveDep(pathRef, user.idAssociado, user.key)
                                        RemoveUser(pathRef, user.key, response => {
                                            if(response === true){
                                                setLoading(false)
                                                navigation.push("NovoAssociado",{id: user.idAssociado})
                                            }
                                        })
                                    }
                                }}
                            />

                            <LoadingModal 
                                visible={loading}
                                msg={'Carregando, aguarde...'}
                            />
                            {/* Fim modals */}
                            <View style={styles.containerInput}>
                                <TouchableOpacity style={[styles.btn,{backgroundColor: colors.gray2}]} onPress={()=>{navigation.goBack()}}>
                                    <Text style={styles.btnTxt}>Voltar</Text>
                                </TouchableOpacity>
                                {user !== null && (
                                    <TouchableOpacity style={[styles.btn,{backgroundColor: colors.red}]} onPress={()=>{setVisibleCancelModal(true)}}>
                                        <Text style={styles.btnTxt}>Remover Cadastro</Text>
                                    </TouchableOpacity>
                                )}
                                <TouchableOpacity style={styles.btn} onPress={()=>{salvar()}}>
                                    <Text style={styles.btnTxt}>Salvar</Text>
                                </TouchableOpacity>
                            </View>
                        </ScrollView>
                    </View>
                </View>
            <Footer/>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    containerBody: {
        flexDirection: 'row'
    },
    txtTitle: {
        width: responsiveFontSize(79),
        fontSize: responsiveFontSize(1.5),
        color: colors.gray3,
        padding: responsiveFontSize(0.5),
        borderBottomWidth: responsiveFontSize(0.1),
        borderBottomColor: colors.gray2,
        marginLeft: responsiveFontSize(1.5)
    },
    form: {
        marginLeft: responsiveFontSize(1.5),
        width: responsiveFontSize(79),
        height: responsiveFontSize(20),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        borderRadius: responsiveFontSize(0.5),
        borderTopWidth: 0,
        borderTopEndRadius: 0,
        borderTopStartRadius: 0,
        padding: responsiveFontSize(0.5)
    },
    containerInput: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    txtLabel: {
        fontSize: responsiveFontSize(0.8),
        fontWeight: "bold",
        paddingVertical: responsiveFontSize(0.5)
    },
    input: {
        width: responsiveFontSize(20),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        borderRadius: responsiveFontSize(0.5),
        fontSize: responsiveFontSize(1),
        padding: responsiveFontSize(0.2)
    },
    // docDigital
    containerDocDigital: {
        flexDirection: "row",
    },
    txtDoc: {
        color: colors.gray3,
        fontSize: responsiveFontSize(0.8),
        fontStyle: "italic",
        marginBottom: responsiveFontSize(1)
    },
    // btnMatricula
    btnGerarMatricula: {
        width: responsiveFontSize(4),
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        backgroundColor: colors.blue,
        borderRadius: responsiveFontSize(0.5),
        marginLeft: responsiveFontSize(1)
    },
    txtBtnGerar: {
        color: "white",
        fontSize: responsiveFontSize(0.8)
    },

    //btn
    btn: {
        width: responsiveFontSize(15),
        padding: responsiveFontSize(0.5),
        backgroundColor: colors.blue,
        borderRadius: responsiveFontSize(0.5),
        marginVertical: responsiveFontSize(1),
        alignItems: 'center'
    },
    btnTxt: {
        fontSize: responsiveFontSize(1),
        color: "white"
    },

    //Mensalidade
    btnMensalidade: {
        width: responsiveFontSize(20),
        backgroundColor: colors.blue,
        padding: responsiveFontSize(0.2),
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: responsiveFontSize(0.5),
        marginTop: responsiveFontSize(1.5)
    },
    // Convenio Unimed
    inputUnimedDp: {
        width: responsiveFontSize(10),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        borderRadius: responsiveFontSize(0.5),
        fontSize: responsiveFontSize(1),
        padding: responsiveFontSize(0.2)
    },
    // Uniodonto
    txtUniodonto: {
        color: "white",
        fontSize: responsiveFontSize(0.8)
    },
    // Ass medica
    containerInputAssM: {
        flexDirection: 'row',
    },
    txtAss: {
        fontSize: responsiveFontSize(1),
        marginRight: responsiveFontSize(1)
    },
    btnAssMed: {
        width: responsiveFontSize(6),
        backgroundColor: colors.blue,
        padding: responsiveFontSize(0.2),
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: responsiveFontSize(0.5),
        marginTop: responsiveFontSize(1.5),
        marginLeft: responsiveFontSize(1)
    },
    txtBtnAssM: {
        fontSize: responsiveFontSize(1),
        color: "white"
    },
    // Solicitações assistencia
    containerSolicitacaoItem: {
        flexDirection: "row",
        justifyContent: 'space-between',
        width: responsiveFontSize(35),
        padding: responsiveFontSize(0.2),
        borderRadius: responsiveFontSize(0.2),
        backgroundColor: colors.gray,
        marginTop: responsiveFontSize(1),
        alignItems: 'center'
    },
    solicitacaoTxt: {
        width: responsiveFontSize(10),
        fontSize: responsiveFontSize(1)
    },
    btnSolicitacaoEdit: {
        padding: responsiveFontSize(0.2),
        borderRadius: responsiveFontSize(0.2),
        backgroundColor: colors.blue
    },
    btnSolicitacaoEditTxt: {
        fontSize: responsiveFontSize(1),
        color: "white"
    },
    // Oficinas
    containerOficinaItem: {
        flexDirection: "row",
        justifyContent: 'space-between',
        width: responsiveFontSize(30),
        padding: responsiveFontSize(0.2),
        borderRadius: responsiveFontSize(0.2),
        backgroundColor: colors.gray,
        marginTop: responsiveFontSize(1),
        alignItems: 'center'
    },
    oficinasTxt: {
        width: responsiveFontSize(10),
        fontSize: responsiveFontSize(1)
    },
    btnOficinaDelete: {
        padding: responsiveFontSize(0.2),
        borderRadius: responsiveFontSize(0.2),
        backgroundColor: colors.red,
        marginRight: responsiveFontSize(0.5)
    },
    btnOficinaDeleteTxt: {
        fontSize: responsiveFontSize(1),
        color: "white"
    },
    txtEmptyOficina: {
        marginVertical: responsiveFontSize(2),
        marginLeft: responsiveFontSize(1),
        fontSize: responsiveFontSize(1)
    }
    
})