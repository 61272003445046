import React, { useEffect, useState } from 'react'
import {View, StyleSheet, Modal, Text, TouchableOpacity, SectionList} from 'react-native'
import { responsiveFontSize, responsiveWidth } from 'react-native-responsive-dimensions';
import colors from '../styles/colors';
import { DeleteDocStorage, deleteItem, LoadAssiM } from '../services/firebaseServices';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR'
import LoadingModal from './loadingModal';


export default function ModalViewAssistenciaMedica({visible, callback, idUser}){
    const [loading, setLoading] = useState(false);

    let _dados = [
        {
            title: "Janeiro",
            data: []
        },
        {
            title: "Fevereiro",
            data: []
        },
        {
            title: "Março",
            data: []
        },
        {
            title: "Abril",
            data: []
        },
        {
            title: "Maio",
            data: []
        },
        {
            title: "Junho",
            data: []
        },
        {
            title: "Julho",
            data: []
        },
        {
            title: "Agosto",
            data: []
        },
        {
            title: "Setembro",
            data: []
        },
        {
            title: "Outubro",
            data: []
        },
        {
            title: "Novembro",
            data: []
        },
        {
            title: "Dezembro",
            data: []
        },
    
    ]

    let All = null;

    if(idUser !== null && idUser !== undefined && visible){
        LoadAssiM("/AssistenciaMedica", idUser, response => {
            
            if(response !== null){
                let limp = response.sort((a, b) => {
                    if(a.data < b.data){
                        return -1
                    }
                    if(a.data > b.data){
                        return 1
                    }
            
                    return 0
                }).filter(item => item.status === "Aprovado")

                if(!limp.length === false){
                    All = limp;
                    let list = [..._dados]
                    limp.map(item => {
                        let dt = new Date(item.data)
                        list[dt.getMonth()].data =  list[dt.getMonth()].data !== undefined ? [...list[dt.getMonth()].data, item] : [item]
                    })
                    _dados = list;
                    // console.log("Filtro: ", _dados)
                }
                // console.log("res: ", limp)
            }
        })
    }

 
    function renderItem(item){
        return (
            <View style={styles.containerItem}>
                <Text style={styles.txtInfo}>Data: {format(new Date(item.data), "dd/MM/yyyy", {locale: ptBR})}</Text>
                <Text style={styles.txtInfo}>Utilização: {item.nome}</Text>
                <Text style={styles.txtInfo}>Valor: R$ {item.valor}</Text>
                <TouchableOpacity style={[styles.btnDelete,{backgroundColor: colors.blue, marginRight: responsiveFontSize(1)}]} onPress={()=>{window.open(item.guia)}}>
                    <Text style={styles.btnDeleteTxt}>Ver Guia</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnDelete} onPress={()=>{
                    setLoading(true)
                    DeleteDocStorage(`/Users/${item.idUser}/${item.key}`);
                    deleteItem("/AssistenciaMedica", item.key, callback => {
                        if(callback === true){
                            setLoading(false)
                        }
                    })
                }}>
                    <Text style={styles.btnDeleteTxt}> Excluir </Text>
                </TouchableOpacity>
            </View>
        )
    }

    function renderSectionHeader(item){
        return !item.data.length === false &&(
            <View style={styles.containerSectionHeader}>
                <Text style={styles.sectionTitle}>{item.title}</Text>
            </View>
        )
    }

    
    return(
        <View style={styles.centeredView}>
            <Modal
                animationType='fade'
                transparent={true}
                visible={visible}>

                <View style={styles.centeredModal}>
                    <View style={styles.modalView}>
                        {All !== null ? (
                        <SectionList
                            sections={_dados}
                            renderItem={({item}) => renderItem(item)}
                            keyExtractor={(item,index) => item + index}
                            renderSectionHeader={({section}) => renderSectionHeader(section)}
                            contentContainerStyle={{padding: 20, marginTop: 20}}
                        />
                        ): (
                            <Text style={styles.txtEmp}>Nenhum dado encontrado</Text>
                        )}
                        <LoadingModal
                            visible={loading}
                            msg={"Aguarde carregando..."}
                        />
                        
                        <TouchableOpacity style={styles.btn} onPress={()=>{callback("Fechar")}}>
                            <Text style={styles.txtBtn}>Voltar</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>
        </View>
    )

}

const styles = StyleSheet.create({
    centeredView: {
      flex: 1
    },
    centeredModal: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#000000aa'
    },
    modalView: {
        width: responsiveWidth(50),
        marginHorizontal: responsiveFontSize(1),
        backgroundColor: 'white',
        borderRadius: 5,
        padding: responsiveFontSize(1),
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    containerItem: {
        flexDirection: 'row',
        marginLeft: responsiveFontSize(1),
        marginTop: responsiveFontSize(1)
    },
    containerSectionHeader: {
       
    },
    sectionTitle: {
        fontSize: responsiveFontSize(1.2),
        fontWeight: "bold"
    },
    txtInfo: {
        width: responsiveFontSize(15),
        fontSize: responsiveFontSize(1),
        marginRight: responsiveFontSize(1)
    },
    containerBtn: {
        height: responsiveFontSize(3), 
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    btnBoleto: {
        backgroundColor: colors.blue,
        width: responsiveFontSize(2),
        height: responsiveFontSize(2),
        borderRadius: responsiveFontSize(0.2),
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center'
    },
    btn: {
        padding: responsiveFontSize(0.2),
        alignSelf: 'flex-end'
    },
    txtBtn: {
        color: colors.blue,
        fontSize: responsiveFontSize(1),
        marginTop: responsiveFontSize(2),
    },
    // Debito
    txtDeb: {
        height: responsiveFontSize(3),
        fontSize: responsiveFontSize(1),
        fontWeight: "bold",
        textAlign: "center",
        paddingTop: responsiveFontSize(0.8),
        color: colors.red
    },
    txtPriceDeb: {
        height: responsiveFontSize(3),
        fontSize: responsiveFontSize(1),
        textAlign: "center",
        paddingTop: responsiveFontSize(0.8),
        backgroundColor: colors.gray,
        color: colors.red
    },
    // empty
    txtEmp: {
        fontSize: responsiveFontSize(1),
        paddingVertical: responsiveFontSize(1)
    },
    // Btn Delete
    btnDelete: {
        padding: responsiveFontSize(0.2),
        borderRadius: responsiveFontSize(0.2),
        backgroundColor: colors.red
    },
    btnDeleteTxt: {
        fontSize: responsiveFontSize(1),
        color: "white"
    }
    
});
