import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as Linking from 'expo-linking';
import Aniversariantes from './pages/aniversariantes';
import AreaAdministrativa from './pages/areaAdministrativa';
import Associados from './pages/associados';
import ConfiguracoesSistema from './pages/configuracoesSistema';
import Despesas from './pages/despesas';
import EmissaoBoletos from './pages/emissaoBoletos';
import EnvioMensagem from './pages/envioMensagem';
import GestaoFinanceira from './pages/gestaoFinanceira';
import Home from './pages/home';
import Inicio from './pages/inicio';
import NovoAssociado from './pages/novoAssociado';
import NovoDependente from './pages/novoDependente';
import Receitas from './pages/receitas';
import Relacionamento from './pages/relacionamento';
import Cancelamentos from './pages/cancelamentos';
import PlanosSaude from './pages/planossaude';
import Funcionarios from './pages/funcionarios';
import NovoFuncionario from './pages/novoFuncionario';
import Fornecedores from './pages/fornecedores';
import NovoFornecedor from './pages/novoFornecedor';
import Recibo from './pages/recibo';
import Relatorios from './pages/relatorios';
import RUnimed from './pages/rUnimed';
import RUniodonto from './pages/rUniodonto';
import ROficinas from './pages/rOficinas';
import RAssMedica from './pages/rAssMedica';
import RInadimplentes from './pages/rInadimplentes';
import RCancelamentos from './pages/rCancelamentos';

const prefix = Linking.createURL("/");

const config = {
    screens: {
      initialRouteName: 'Home',
      Home: "Painel/",
      Inicio: "Painel/Inicio",
      AreaAdministrativa: "Painel/AreaAdministrativa",
      Associados: "Painel/Associados",
      NovoAssociado: "Painel/NovoAssociado/:id?/:tpV?/:idAssociado?",
      NovoDependente: "Painel/NovoDependente/:id?/:idAssociado?",
      Relacionamento: "Painel/Relacionamento",
      Aniversariantes: "Painel/Aniversariantes",
      EnvioMensagem: "Painel/EnvioMensagem",
      GestaoFinanceira: "Painel/GestaoFinanceira",
      Receitas: "Painel/Receitas",
      Despesas: "Painel/Despesas",
      EmissaoBoletos: "Painel/EmissaoBoletos",
      ConfiguracoesSistema: "Painel/ConfiguracoesSistema",
      Cancelamentos: "Painel/Cancelamentos",
      PlanosSaude: "Painel/PlanosSaude",
      Funcionarios: "Painel/Funcionarios",
      NovoFuncionario: "Painel/NovoFuncionario/:id?/",
      Fornecedores: "Painel/Fornecedores",
      NovoFornecedor: "Painel/NovoFornecedor/:id?/",
      Recibo: "Painel/Recibo/:id?/",
      Relatorios: "Painel/Relatorios",
      RUnimed: "Painel/RelatorioUnimed",
      RUniodonto: "Painel/RelatorioUniodonto",
      ROficinas: "Painel/RelatorioOficina",
      RAssMedica: "Painel/RelatorioAssistenciaMedica",
      RInadimplentes: "Painel/RelatorioInadinplentes",
      RCancelamentos: "Painel/RelatorioCancelamentos"
    }
};

const linking = {
    prefixes: [prefix],
    config
};

const Stack = createNativeStackNavigator();

export default function Routes(){
    return(
        <NavigationContainer linking={linking}>
            <Stack.Navigator>
                <Stack.Screen name="Home" component={Home} options={{headerShown: false}}/>
                <Stack.Screen name="Inicio" component={Inicio} options={{headerShown: false}}/>
                <Stack.Screen name="AreaAdministrativa" component={AreaAdministrativa} options={{headerShown: false}}/>
                <Stack.Screen name="Associados" component={Associados} options={{headerShown: false}}/>
                <Stack.Screen name="NovoAssociado" component={NovoAssociado} options={{headerShown: false}}/>
                <Stack.Screen name="NovoDependente" component={NovoDependente} options={{headerShown: false}}/>
                <Stack.Screen name="Relacionamento" component={Relacionamento} options={{headerShown: false}}/>
                <Stack.Screen name="Aniversariantes" component={Aniversariantes} options={{headerShown: false}}/>
                <Stack.Screen name="EnvioMensagem" component={EnvioMensagem} options={{headerShown: false}}/>
                <Stack.Screen name="GestaoFinanceira" component={GestaoFinanceira} options={{headerShown: false}}/>
                <Stack.Screen name="Receitas" component={Receitas} options={{headerShown: false}}/>
                <Stack.Screen name="Despesas" component={Despesas} options={{headerShown: false}}/>
                <Stack.Screen name="EmissaoBoletos" component={EmissaoBoletos} options={{headerShown: false}}/>
                <Stack.Screen name="ConfiguracoesSistema" component={ConfiguracoesSistema} options={{headerShown: false}}/>
                <Stack.Screen name="Cancelamentos" component={Cancelamentos} options={{headerShown: false}}/>
                <Stack.Screen name="PlanosSaude" component={PlanosSaude} options={{headerShown: false}}/>
                <Stack.Screen name="Funcionarios" component={Funcionarios} options={{headerShown: false}}/>
                <Stack.Screen name="NovoFuncionario" component={NovoFuncionario} options={{headerShown: false}}/>
                <Stack.Screen name="Fornecedores" component={Fornecedores} options={{headerShown: false}}/>
                <Stack.Screen name="NovoFornecedor" component={NovoFornecedor} options={{headerShown: false}}/>
                <Stack.Screen name="Recibo" component={Recibo} options={{headerShown: false}}/>
                <Stack.Screen name="Relatorios" component={Relatorios} options={{headerShown: false}}/>
                <Stack.Screen name="RUnimed" component={RUnimed} options={{headerShown: false}}/>
                <Stack.Screen name="RUniodonto" component={RUniodonto} options={{headerShown: false}}/>
                <Stack.Screen name="ROficinas" component={ROficinas} options={{headerShown: false}}/>
                <Stack.Screen name="RAssMedica" component={RAssMedica} options={{headerShown: false}}/>
                <Stack.Screen name="RInadimplentes" component={RInadimplentes} options={{headerShown: false}}/>
                <Stack.Screen name="RCancelamentos" component={RCancelamentos} options={{headerShown: false}}/>
            </Stack.Navigator>
        </NavigationContainer>
    )
}