const colors = {
    green: '#2FD929',
    red: '#c01025',
    redLight: '#ef394f',
    blue: '#0175da',
    blue2: '#70b7f4',
    gray: '#F3F4F5',
    gray2: '#E3E4E6',
    gray3: '#919292'

}

export default colors;