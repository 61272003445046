import React from 'react'
import {View, StyleSheet, Modal, Text, TouchableOpacity} from 'react-native'
import { responsiveFontSize, responsiveWidth } from 'react-native-responsive-dimensions';
import colors from '../styles/colors';


export default function ModalInfoAccount({visible, title, msg, callback}){
    return(
        <View style={styles.centeredView}>
            <Modal
                animationType='fade'
                transparent={true}
                visible={visible}>

                <View style={styles.centeredModal}>
                    <View style={styles.modalView}>
                        <Text style={styles.title}>{title}</Text>
                        <Text style={styles.txtMsg}>{msg}</Text>
                        <TouchableOpacity style={styles.btn} onPress={()=>{callback("Fechar")}}>
                            <Text style={styles.txtBtn}>Ok</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>
        </View>
    )

}

const styles = StyleSheet.create({
    centeredView: {
      flex: 1
    },
    centeredModal: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#000000aa'
    },
    modalView: {
        width: responsiveWidth(40),
        marginHorizontal: responsiveFontSize(1),
        backgroundColor: 'white',
        borderRadius: 5,
        padding: responsiveFontSize(1),
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    title: {
        fontSize: responsiveFontSize(1.5),
        fontWeight: "bold",
        marginBottom: responsiveFontSize(2)
    },
    txtMsg: {
        fontSize: responsiveFontSize(1)
    },
    btn: {
        width: responsiveFontSize(2),
        alignSelf: 'flex-end'
    },
    txtBtn: {
        color: colors.blue,
        fontSize: responsiveFontSize(1),
        marginTop: responsiveFontSize(2),
    }
    
});
