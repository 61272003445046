import React, { useEffect, useState } from "react";
import { View, StyleSheet, FlatList, TouchableOpacity, Text, TextInput } from "react-native"
import { AntDesign, MaterialIcons  } from '@expo/vector-icons'; 
import { responsiveFontSize } from "react-native-responsive-dimensions";
import Footer from "../components/footer";
import Header from "../components/header";
import HeaderRelatorios from "../components/headerRelatorios";
import LoadingModal from "../components/loadingModal";
import Menu from "../components/menu";
import { LoadRelatorio, LoadUsers } from "../services/firebaseServices";
import colors from "../styles/colors";
import { createPdfRelatorio, createPdfRelatorioCancelamentos, createPdfRelatorioInadimplentes } from "../utils/createPDF";
import { formatCpf } from "../utils/formatCpf";
import { FormatPhone } from "../utils/formatPhone";

const pathRef = "/Users"
export default function RCancelamentos({navigation, route}){

    const [selectMes, setSelectMes] = useState(new Date().getMonth())
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [users, setUsers] = useState(null)
    const [userFilter, setUserFilter] = useState(null)
    const [searchTxt, setSearchTxt] = useState('')

    // modal
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        LoadUsers(pathRef, response => {
            if(response !== null){
                let limp = response.filter(item => item.tpVinculo === "Associado" && item.status !== "Ativo")
                let ordenar = limp.sort((a,b) => {
                    let x = a.name.toLowerCase(),
                        y = b.name.toLowerCase();

                    return x === y ? 0 : x > y ? 1 : -1;

                })
                setUsers(ordenar)

            }
        })
    },[])

    useEffect(()=>{
        if(users !== null){
            if(searchTxt !== ''){
                let _limp = users.filter(item => item.name.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1)
                // console.log(_limp)
                setUserFilter(_limp)
            }else{
                setUserFilter(users)
            }
        }
    },[searchTxt,users])

    function renderItemList(item,index){
        return(
            <TouchableOpacity style={index%2 === 0 ? styles.containerItemList : styles.containerItemList2} onPress={()=>{}}>
                {/* <Text style={styles.txtList}>{item.key}</Text> */}
                <Text style={styles.txtList}>{item.matricula}</Text>
                <Text style={[styles.txtList,{width: responsiveFontSize(30)}]}>{item.name}</Text>
                <Text style={styles.txtList}>{formatCpf(item.cpf)}</Text>
                <Text style={styles.txtList}>{FormatPhone(item.phone)}</Text>
                <Text style={styles.txtList}>{item.status}</Text>
                <Text style={[styles.txtList,{borderRightWidth: responsiveFontSize(0.1), width: responsiveFontSize(15)}]}>{item.motivo}</Text>
            </TouchableOpacity>
        )
    }

    return(
        <View style={styles.container}>
            <Header nav={navigation}/>
                <View style={styles.containerBody}>
                    <Menu num={3} name={route.name} navigation={navigation} sub={2}/>
                    <View style={{width: responsiveFontSize(80)}}>
                        {/* barra de pesquisa */}
                        <View style={styles.containerInput}>
                            <TextInput 
                                style={styles.input}
                                placeholder=''
                                onChangeText={setSearchTxt}
                                value={searchTxt}
                                keyboardType={'default'}
                            />
                            <TouchableOpacity style={styles.iconSearch} onPress={()=>{}}>
                                <AntDesign name="search1" size={responsiveFontSize(2)} color={colors.gray2} />
                            </TouchableOpacity>
                        </View>
                        {userFilter !== null && (
                            <TouchableOpacity style={styles.btnPrint} onPress={()=>{createPdfRelatorioCancelamentos(userFilter)}}>
                                <Text style={styles.btnPrintTxt}>Imprimir</Text>
                            </TouchableOpacity>
                        )}
                        <View style={styles.containerHeaderTable}>
                            {/* <Text style={styles.txtTable}>Matrícula</Text> */}
                            <Text style={styles.txtTable}>Matrícula</Text>
                            <Text style={[styles.txtTable,{width: responsiveFontSize(30)}]}>Nome</Text>
                            <Text style={styles.txtTable}>CPF</Text>
                            <Text style={styles.txtTable}>Telefone</Text>
                            <Text style={styles.txtTable}>Status</Text>
                            <Text style={[styles.txtTable,{borderRightWidth: 0, width: responsiveFontSize(15)}]}>Motivo</Text>
                        </View>
                        <FlatList
                            data={userFilter}
                            keyExtractor={item => item.id}
                            renderItem={({item, index}) => renderItemList(item,index)}
                            ListEmptyComponent={(
                                <Text style={styles.txtEmpty}>Nenhum dado encontrado...</Text>
                            )}                
                            style={styles.list}/>

                        <LoadingModal visible={loading} msg={"Aguarde, carregando..."}/>

                    </View>
                </View>
            <Footer/>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    containerBody: {
        flexDirection: 'row'
    },
    //list
    containerHeaderTable: {
        width: responsiveFontSize(79),
        flexDirection: 'row',
        marginLeft: responsiveFontSize(1.5),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        backgroundColor: 'white',
    },
    txtTable: {
        fontSize: responsiveFontSize(1),
        width: responsiveFontSize(12),
        borderRightWidth: responsiveFontSize(0.1),
        borderRightColor: colors.gray2,
        paddingLeft: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5)
    },
    list: {
        width: responsiveFontSize(79),
        height: responsiveFontSize(24),
        marginLeft: responsiveFontSize(1.5)
    },
    containerItemList: {
        flexDirection: 'row',
        backgroundColor: colors.gray
    },
    containerItemList2: {
        flexDirection: 'row',
        backgroundColor: "white"
    },
    txtList: {
        fontSize: responsiveFontSize(1),
        width: responsiveFontSize(12),
        borderLeftWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        paddingLeft: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5),
    },
    txtEmpty: {
        backgroundColor: colors.gray2,
        bborderLeftWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        padding: responsiveFontSize(0.5),
        fontSize: responsiveFontSize(1)
    },
    // btn Mes
    containerBtnMes: {
        flexDirection: 'row',
        marginTop: responsiveFontSize(5),
        alignSelf: "center"
    },
    btnMes: {
        backgroundColor: colors.gray2,
        borderRadius: responsiveFontSize(1),
        height: responsiveFontSize(3),
        marginHorizontal: responsiveFontSize(0.5)
    },
    txtBtnMes: {
        borderRadius: responsiveFontSize(1),
        fontSize: responsiveFontSize(1.2),
        width: responsiveFontSize(10),
        paddingVertical: responsiveFontSize(0.3),
        backgroundColor: colors.gray3,
        textAlign: 'center',
        color: "white"
    },
    // btn Print
    btnPrint: {
        width: responsiveFontSize(5),
        paddingVertical: responsiveFontSize(0.2),
        marginRight: responsiveFontSize(1.5),
        alignItems: "center",
        justifyContent: "center",
        borderRadius: responsiveFontSize(1),
        marginBottom: responsiveFontSize(1),
        alignSelf: "flex-end",
        backgroundColor: colors.red
    },
    btnPrintTxt: {
        fontSize: responsiveFontSize(1),
        color: "white"
    },
    //input\
    containerInput: {
        flexDirection: 'row',
        width: responsiveFontSize(40),
        marginTop: responsiveFontSize(5),
        alignItems: 'center',
        alignSelf: 'center'
    },
    input: {
        width: responsiveFontSize(30),
        fontSize: responsiveFontSize(1),
        borderRadius: responsiveFontSize(1),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        padding: responsiveFontSize(0.5)
    },
    iconSearch: {
        padding: responsiveFontSize(0.5)
    }

})