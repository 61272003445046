import { format } from 'date-fns';
import React, { useState } from 'react'
import {View, StyleSheet, Modal, Text, TouchableOpacity, TextInput} from 'react-native'
import { responsiveFontSize, responsiveWidth } from 'react-native-responsive-dimensions';
import { saveAssM } from '../services/firebaseServices';
import colors from '../styles/colors';
import { formatDate } from '../utils/formatDate';
import { formatPrice } from '../utils/formatPrice';
import LoadingModal from './loadingModal';
import ModalInfoAccount from './modalInfoAccount';


export default function ModalAddAssitenciaMedica({visible, idUser, callback}){
    const [data, setData] = useState(format(new Date(), "dd/MM/yyyy"))
    const [nome, setNome] = useState(null)

    const [loading, setLoading] = useState(false)
    const [info, setInfo] = useState(false)
    return(
        <View style={styles.centeredView}>
            <Modal
                animationType='fade'
                transparent={true}
                visible={visible}>

                <View style={styles.centeredModal}>
                    <View style={styles.modalView}>
                        <Text style={styles.title}>Adicionar Assistência Médica</Text>
                        <View>
                            <Text style={styles.txtLabel}>Data</Text>
                            <TextInput
                                style={styles.input}
                                placeholder=''
                                maxLength={10}
                                onChangeText={text => setData(formatDate(text))}
                                value={data}
                                keyboardType={'default'}
                            />
                        </View>
                        <View>
                        <Text style={styles.txtLabel}>Nome</Text>
                            <TextInput
                                style={styles.input}
                                placeholder=''
                                onChangeText={setNome}
                                value={nome}
                                keyboardType={'default'}
                            />
                        </View>
                        <View style={styles.containerBtn}>
                            <TouchableOpacity style={styles.btn} onPress={()=>{callback("Fechar")}}>
                                <Text style={styles.txtBtn}>Voltar</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.btn} onPress={()=>{
                                if(data !== null && nome !== null){
                                    setLoading(true)
                                    let _data = data.split("/");
                                    // limpar filtro mes
                                    let month = _data[1] < 10 ? _data[1].replace("0","") : _data[1];
                                    // console.log("MEs ",month) 
                                    let assistencia ={
                                        status: "Solicitado",
                                        data: new Date(_data[2],month - 1,_data[0]).getTime(),
                                        nome,
                                        idUser
                                     }
                                    saveAssM("/AssistenciaMedica", assistencia, resp => {
                                        if(resp === true){
                                            setData(null)
                                            setNome(null)
                                            setLoading(false)
                                            callback("Salvo")
                                        }
                                    })

                                }else{
                                    setLoading(false)
                                    setInfo(true)

                                }
                            }}>
                                <Text style={styles.txtBtn}>Confirmar</Text>
                            </TouchableOpacity>
                        </View>
                        <LoadingModal 
                            visible={loading}
                            msg={'Carregando, aguarde...'}
                        />
                        <ModalInfoAccount
                            visible={info}
                            title="Ops 🤔"
                            msg={"Informe todos os campos para continuar ✌🏻"}
                            callback={()=>{setInfo(false)}}
                        />
                    </View>
                </View>
            </Modal>
        </View>
    )

}

const styles = StyleSheet.create({
    centeredView: {
      flex: 1
    },
    centeredModal: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#000000aa'
    },
    modalView: {
        width: responsiveWidth(30),
        marginHorizontal: responsiveFontSize(1),
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        padding: responsiveFontSize(1),
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    title: {
        fontSize: responsiveFontSize(1.5),
        fontWeight: "bold",
        marginBottom: responsiveFontSize(2)
    },
    txtLabel: {
        fontSize: responsiveFontSize(0.8),
        fontWeight: "bold",
        paddingVertical: responsiveFontSize(0.5)
    },
    input: {
        width: responsiveFontSize(20),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        borderRadius: responsiveFontSize(0.5),
        fontSize: responsiveFontSize(1),
        padding: responsiveFontSize(0.2)
    },
    containerBtn: {
        flexDirection: "row",
        justifyContent: 'center',
        marginTop: responsiveFontSize(2)
    },
    btn: {
        width: responsiveFontSize(5),
        backgroundColor: colors.blue,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5),
        marginHorizontal: responsiveFontSize(0.5)
    },
    txtBtn: {
        color: "white",
        fontSize: responsiveFontSize(1),
    }
    
});
