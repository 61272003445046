import React from "react";
import { View, StyleSheet, FlatList, TouchableOpacity, Text } from "react-native"
import { responsiveFontSize } from "react-native-responsive-dimensions";
import { AntDesign } from "@expo/vector-icons"
import { format} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import Footer from "../components/footer";
import Header from "../components/header";
import HeaderAssociados from "../components/headerAssociados";
import Menu from "../components/menu";
import colors from "../styles/colors";
import { FormatPhone } from "../utils/formatPhone";

const data = [
    {
        name: "Unimed",
        cnpj: "12566123611000/01",
        responsavel: "Carlos Almeida",
        phone: "91985321662",
        status: "Ativo"
    },
    {
        name: "Uniodonto",
        cnpj: "12566123611000/01",
        responsavel: "Joana Xavier",
        phone: "91985321662",
        status: "Ativo"
    }
]
export default function PlanosSaude({navigation, route}){

    function renderItemList(item,index){
        return( 
            <TouchableOpacity style={index%2 === 0 ? styles.containerItemList : styles.containerItemList2} onPress={()=>{}}>
                <Text style={styles.txtList}>{item.name}</Text>
                <Text style={styles.txtList}>{item.cnpj}</Text>
                <Text style={styles.txtList}>{item.responsavel}</Text>
                <Text style={styles.txtList}>{FormatPhone(item.phone)}</Text>
                <Text style={styles.txtList}>{item.status}</Text>
            </TouchableOpacity>
        )
    }

    return(
        <View style={styles.container}>
            <Header nav={navigation}/>
                <View style={styles.containerBody}>
                    <Menu num={4} name={route.name} navigation={navigation}/>
                    <View style={{width: responsiveFontSize(80)}}>
                    <TouchableOpacity style={styles.btn} onPress={()=>{navigation.navigate("PlanosSaude")}}>
                        <View style={styles.containerIcon}>
                            <AntDesign name="medicinebox" size={responsiveFontSize(4)} color="white" />
                        </View>
                    <Text style={styles.btnTxtHeader}>Planos de Saúde</Text>
                </TouchableOpacity>
                        <View style={styles.containerHeaderTable}>
                            <Text style={styles.txtTable}>Plano</Text>
                            <Text style={styles.txtTable}>CNPJ</Text>
                            <Text style={styles.txtTable}>Responsável</Text>
                            <Text style={styles.txtTable}>Contato</Text>
                            <Text style={[styles.txtTable,{borderRightWidth: 0}]}>Status</Text>
                        </View>
                        <FlatList
                            data={data}
                            keyExtractor={item => item.key}
                            renderItem={({item, index}) => renderItemList(item,index)}                   
                            style={styles.list}/>

                    </View>
                </View>
            <Footer/>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    containerBody: {
        flexDirection: 'row'
    },
    //list
    containerHeaderTable: {
        width: responsiveFontSize(79),
        flexDirection: 'row',
        marginLeft: responsiveFontSize(1.5),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        backgroundColor: 'white',
    },
    txtTable: {
        fontSize: responsiveFontSize(1),
        width: responsiveFontSize(16),
        borderRightWidth: responsiveFontSize(0.1),
        borderRightColor: colors.gray2,
        paddingHorizontal: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5)
    },
    list: {
        width: responsiveFontSize(79),
        height: responsiveFontSize(24),
        marginLeft: responsiveFontSize(1.5)
    },
    containerItemList: {
        flexDirection: 'row',
        backgroundColor: colors.gray
    },
    containerItemList2: {
        flexDirection: 'row',
        backgroundColor: "white"
    },
    txtList: {
        fontSize: responsiveFontSize(1),
        width: responsiveFontSize(16),
        borderLeftWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        paddingHorizontal: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5),
    },
    //btnHEader
    btn: {
        backgroundColor: colors.gray,
        width: responsiveFontSize(12),
        borderRadius: responsiveFontSize(1),
        margin: responsiveFontSize(1.5)
    },
    containerIcon: {
        borderRadius: responsiveFontSize(1),
        width: responsiveFontSize(12),
        height: responsiveFontSize(7),
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.red
    },
    btnTxtHeader: {
        fontSize: responsiveFontSize(1),
        padding: responsiveFontSize(0.8)
    },
})