import React, { useState } from 'react'
import {View, StyleSheet, Modal, Text, TouchableOpacity, Picker} from 'react-native'
import { responsiveFontSize, responsiveWidth } from 'react-native-responsive-dimensions';
import colors from '../styles/colors';


export default function ModalCancelAssociado({visible, callback}){
    const [motivo, setMotivo] = useState('Desistência')
    return(
        <View style={styles.centeredView}>
            <Modal
                animationType='fade'
                transparent={true}
                visible={visible}>

                <View style={styles.centeredModal}>
                    <View style={styles.modalView}>
                        <Text style={styles.title}>Deseja mesmo cancelar este cadastro?</Text>
                        <Text style={styles.txt}>Motivo</Text>
                        <Picker
                            selectedValue={motivo}
                            style={styles.input}
                            onValueChange={(itemValue, itemIndex) => setMotivo(itemValue)}
                        >
                            <Picker.Item key={0} label={"Desistência"} value={"Desistência"} />
                            <Picker.Item key={0} label={"Falecimento"} value={"Falecimento"} />
                            <Picker.Item key={1} label={"Inadimplência"} value={"Inadimplência"} />
                        </Picker>

                        <View style={styles.containerInput}>
                            <TouchableOpacity style={[styles.btn,{backgroundColor: colors.red}]} onPress={()=>{callback(motivo)}}>
                                <Text style={styles.btnTxt}>Confirmar Cancelamento</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.btn} onPress={()=>{callback('')}}>
                                <Text style={styles.btnTxt}>Voltar</Text>
                            </TouchableOpacity>
                        </View>
                        
                    </View>
                </View>
            </Modal>
        </View>
    )

}

const styles = StyleSheet.create({
    centeredView: {
      flex: 1
    },
    centeredModal: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#000000aa'
    },
    modalView: {
        marginHorizontal: responsiveFontSize(1),
        backgroundColor: 'white',
        borderRadius: 5,
        padding: responsiveFontSize(2),
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    title: {
        fontSize: responsiveFontSize(1.5),
        width: responsiveWidth(40),
        paddingBottom: responsiveFontSize(1)
    },
    txt: {
        fontSize: responsiveFontSize(1.5),
        color: colors.gray3,
        paddingBottom: responsiveFontSize(1)
    },
    input: {
        width: responsiveFontSize(20),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        borderRadius: responsiveFontSize(0.5),
        fontSize: responsiveFontSize(1),
        padding: responsiveFontSize(0.2),
        marginBottom: responsiveFontSize(2)
    },

    containerInput: {
        flexDirection: 'row',
        justifyContent: 'space-around'
    },

    //btn
    btn: {
        width: responsiveFontSize(15),
        padding: responsiveFontSize(0.5),
        backgroundColor: colors.gray3,
        borderRadius: responsiveFontSize(0.5),
        marginVertical: responsiveFontSize(1),
        alignItems: 'center'
    },
    btnTxt: {
        fontSize: responsiveFontSize(1),
        color: "white"
    }
    
});
