import { format } from 'date-fns';
import React, { useEffect, useState } from 'react'
import {View, StyleSheet, Modal, Text, TouchableOpacity, TextInput, Picker} from 'react-native'
import { responsiveFontSize, responsiveWidth } from 'react-native-responsive-dimensions';
import { saveAssM, SaveDocStorage, UpdateAssistencia } from '../services/firebaseServices';
import * as DocumentPicker from 'expo-document-picker';
import colors from '../styles/colors';
import { formatDate } from '../utils/formatDate';
import { formatPrice } from '../utils/formatPrice';
import LoadingModal from './loadingModal';
import ModalInfoAccount from './modalInfoAccount';

const pathDocStorage = '/Users';
export default function ModalEditAssistenciaMedica({visible, assistencia, callback}){
    const [data, setData] = useState(null)
    const [nome, setNome] = useState(null)
    const [valor, setValor] = useState(null)
    const [status, setStatus] = useState(null)
    const [guia, setGuia] = useState(null)

    const [loading, setLoading] = useState(false)
    const [info, setInfo] = useState(false)

    useEffect(()=>{
        if(assistencia !== null){
            setData(format(new Date(assistencia.data),"dd/MM/yyyy"))
            setNome(assistencia.nome)
            setValor(assistencia.valor)
            setStatus(assistencia.status)
            if(assistencia.guia !== undefined){
                setGuia(assistencia.guia)
            }
        }
    },[assistencia])

    async function _pickDoc () {
        try {
          let result = await DocumentPicker.getDocumentAsync({});
          if (!result.cancelled) {
            uploadDoc(result.uri);
          }
        //   console.log(result);
        } catch (E) {
          console.log(E);
        }
    };

    async function uploadDoc(uri){
        setLoading(true)
        const response = await fetch(uri);
        const blob = await response.blob();
    
        const fileName = assistencia.key;
        SaveDocStorage(`${pathDocStorage}/${assistencia.idUser}/${fileName}`,blob, response => {
            setGuia(response)
            setLoading(false)
        })
    }

    function save(){
        if(nome !== null && valor !== null && nome !== undefined && valor !== undefined){
            let _data = data.split("/");
            // limpar filtro mes
            let month = _data[1] < 10 ? _data[1].replace("0","") : _data[1];
            let _assistencia ={
                status,
                data: new Date(_data[2],month - 1,_data[0]).getTime(),
                nome,
                idUser: assistencia.idUser,
                valor: valor.replace(",","."),
                guia
             }
            UpdateAssistencia("/AssistenciaMedica", assistencia.key, _assistencia, resp => {
                if(resp === true){
                    setData(null)
                    setNome(null)
                    setValor(null)
                    setGuia(null)
                    setStatus(null)
                    setLoading(false)
                    callback("Salvo")
                }
            })

        }else{
            setLoading(false)
            setInfo(true)

        }
    }

    return(
        <View style={styles.centeredView}>
            <Modal
                animationType='fade'
                transparent={true}
                visible={visible}>

                <View style={styles.centeredModal}>
                    <View style={styles.modalView}>
                        <Text style={styles.title}>Editar Assistência Médica</Text>
                        <View>
                            <Text style={styles.txtLabel}>Status</Text>
                            <Picker
                                selectedValue={status}
                                style={styles.input}
                                onValueChange={(itemValue, itemIndex) => setStatus(itemValue)}
                            >
                                <Picker.Item key={0} label={"Solicitado"} value={"Solicitado"} />
                                <Picker.Item key={1} label={"Aprovado"} value={"Aprovado"} />
                                <Picker.Item key={2} label={"Recusado"} value={"Recusado"} />
                                
                            </Picker>
                        </View>
                        <View>
                            <Text style={styles.txtLabel}>Data</Text>
                            <TextInput
                                editable={false}
                                style={styles.input}
                                placeholder=''
                                maxLength={10}
                                onChangeText={text => setData(formatDate(text))}
                                value={data}
                                keyboardType={'default'}
                            />
                        </View>
                        <View>
                            <Text style={styles.txtLabel}>Nome</Text>
                            <TextInput
                                style={styles.input}
                                placeholder=''
                                onChangeText={setNome}
                                value={nome}
                                keyboardType={'default'}
                            />
                        </View>
                        <View>
                            <Text style={styles.txtLabel}>Valor</Text>
                            <TextInput
                                style={styles.input}
                                placeholder=''
                                onChangeText={text => setValor(formatPrice(text))}
                                value={valor}
                                keyboardType={'default'}
                            />
                        </View>
                        {status === "Aprovado" && (
                            <View>
                                <Text style={styles.txtLabel}>Guia de Encaminhamento</Text>
                                <View style={styles.containerGuia}>
                                    <TouchableOpacity style={styles.btnGuia} onPress={()=>{_pickDoc('Contrato')}}>
                                        <Text style={styles.btnTxtDoc}>{guia === null ? 'Enviar' : 'Alterar'}</Text>
                                    </TouchableOpacity>
                                    {guia !== null && (
                                        <TouchableOpacity style={styles.btnGuia} onPress={()=>{window.open(guia)}}>
                                            <Text style={styles.btnTxtDoc}>Ver</Text>
                                        </TouchableOpacity>
                                    )}
                                </View>
                            </View>
                        )}
                        <View style={styles.containerBtn}>
                            <TouchableOpacity style={styles.btn} onPress={()=>{callback("Fechar")}}>
                                <Text style={styles.txtBtn}>Voltar</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.btn} onPress={()=>{
                                if(status === "Aprovado"){
                                    setLoading(true)
                                    if(guia !== null){
                                        save()
                                    }else{
                                        setLoading(false)
                                        setInfo(true)
                                    }
                                }else{
                                    save()
                                }
                                
                            }}>
                                <Text style={styles.txtBtn}>Confirmar</Text>
                            </TouchableOpacity>
                        </View>
                        <LoadingModal 
                            visible={loading}
                            msg={'Carregando, aguarde...'}
                        />
                        <ModalInfoAccount
                            visible={info}
                            title="Ops 🤔"
                            msg={"Informe todos os campos para continuar ✌🏻"}
                            callback={()=>{setInfo(false)}}
                        />
                    </View>
                </View>
            </Modal>
        </View>
    )

}

const styles = StyleSheet.create({
    centeredView: {
      flex: 1
    },
    centeredModal: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#000000aa'
    },
    modalView: {
        width: responsiveWidth(30),
        marginHorizontal: responsiveFontSize(1),
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        padding: responsiveFontSize(1),
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    title: {
        fontSize: responsiveFontSize(1.5),
        fontWeight: "bold",
        marginBottom: responsiveFontSize(2)
    },
    txtLabel: {
        fontSize: responsiveFontSize(0.8),
        fontWeight: "bold",
        paddingVertical: responsiveFontSize(0.5)
    },
    input: {
        width: responsiveFontSize(20),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        borderRadius: responsiveFontSize(0.5),
        fontSize: responsiveFontSize(1),
        padding: responsiveFontSize(0.2)
    },
    containerBtn: {
        flexDirection: "row",
        justifyContent: 'center',
        marginTop: responsiveFontSize(2)
    },
    btn: {
        width: responsiveFontSize(5),
        backgroundColor: colors.blue,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5),
        marginHorizontal: responsiveFontSize(0.5)
    },
    txtBtn: {
        color: "white",
        fontSize: responsiveFontSize(1),
    },
    // btn Guia
    containerGuia: {
        flexDirection: "row",
        width: responsiveFontSize(20)
    },
    btnGuia: {
        width: responsiveFontSize(4),
        backgroundColor: colors.blue,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: responsiveFontSize(0.2),
        paddingVertical: responsiveFontSize(0.2),
        marginHorizontal: responsiveFontSize(0.5),
    },
    btnTxtDoc: {
        color: "white",
        fontSize: responsiveFontSize(1),
    }
    
});
