import React, { useState } from 'react'
import {View, StyleSheet, Modal, Text, TouchableOpacity, FlatList} from 'react-native'
import { responsiveFontSize, responsiveWidth } from 'react-native-responsive-dimensions';
import { AntDesign } from "@expo/vector-icons"
import colors from '../styles/colors';
import { LoadPayment, UpdatePayment } from '../services/firebaseServices';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR'
import { getPreferenceId } from '../services/paymentService';
import ModalViewOneMensalidade from './modalViewOneMensalidade';

// const data = [
//     {
//         idUser: "1234",
//         mensalidade: "100,00",
//         unimed: "50,00",
//         uniodonto: "20,00",
//         status: "Em Aberto",
//         vencimento: "05",
//         dataPayment: "",


//     }
// ]
export default function ModalViewHistoricoMensalidade({visible, callback, userData}){
    const [visibleModal, setVisibleModal] = useState(false)
    let data = null;
    let total = 0.00;
    if(userData !== null){
        LoadPayment("/Payment", userData.key, response => {
            if(response !== null){
                data = response.sort((a, b) => {
                    if(a.dateVencimento < b.dateVencimento){
                        return -1
                    }
                    if(ta.dateVencimento > b.dateVencimento){
                        return 1
                    }
            
                    return 0
                });
            }
        })
    }
    if(data !== null){
        data.map(item => item.status === "Vencido" && (total += parseFloat(item.total?.replace(",","."))))
    }

    async function startCheckout(item){
        try {
            let name = userData.name.split(" ");
            let description = `Mensalidade de ${format(new Date(item.dateVencimento), "MMM", {locale: ptBR})}`
            
            let payItem = 
                {
                    email: userData.email,
                    first_name: name[0],
                    last_name: name[1],
                    identification: {
                        type: "CPF",
                        number: userData.cpf
                    },
                }
            const {id,transaction_details} = await getPreferenceId(payItem, parseFloat(item.total), description);
            let _updatePayment = {
                idPayment: id,
                status: "Pendente"
            }
            UpdatePayment("/Payment",item.key, _updatePayment, response => {})
            window.open(transaction_details.external_resource_url);

        } catch (err) {
          console.log('Algo deu errado', err)
        }
    };

    function renderItem(item){
        return(
            <View style={styles.containerItem}>
                <Text style={styles.txtMes}>{format(new Date(item.dateVencimento), "MMM", {locale: ptBR})}</Text>
                <Text style={styles.txtPrice}>R$ {item.total}</Text>
                <View style={styles.containerBtn}>
                    <TouchableOpacity style={styles.btnBoleto} onPress={()=>{
                        startCheckout(item)
                    }}>
                        <AntDesign name="barcode" size={responsiveFontSize(1)} color="white" />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.btnBoleto} onPress={()=>{setVisibleModal(true)}}>
                        <AntDesign name="eyeo" size={responsiveFontSize(1)} color="white" />
                    </TouchableOpacity>
                </View>
                <ModalViewOneMensalidade
                    visible={visibleModal}
                    item={item}
                    callback={(value)=>{setVisibleModal(false)}}
                />
            </View>
        )
    }

    function renderFooter(){
        return data !== null &&(
            <View style={styles.containerItem}>
                <Text style={styles.txtDeb}>Débito</Text>
                <Text style={styles.txtPriceDeb}>R$ {total.toFixed(2)}</Text>
                <View style={styles.containerBtn}>
                    
                </View>
            </View>
        )
    }

    return(
        <View style={styles.centeredView}>
            <Modal
                animationType='fade'
                transparent={true}
                visible={visible}>

                <View style={styles.centeredModal}>
                    <View style={styles.modalView}>
                        <FlatList
                            horizontal
                            data={data}
                            keyExtractor={ item => item.key}
                            renderItem={({item}) => renderItem(item)}
                            ListFooterComponent={renderFooter}
                            ListEmptyComponent={
                                <Text style={styles.txtEmp}> Nenhuma informação de pagamento encontrada, tente novamente amanhã.</Text>
                            }
                        />
                        <TouchableOpacity style={styles.btn} onPress={()=>{callback("Fechar")}}>
                            <Text style={styles.txtBtn}>Voltar</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>
        </View>
    )

}

const styles = StyleSheet.create({
    centeredView: {
      flex: 1
    },
    centeredModal: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#000000aa'
    },
    modalView: {
        width: responsiveWidth(50),
        marginHorizontal: responsiveFontSize(1),
        backgroundColor: 'white',
        borderRadius: 5,
        padding: responsiveFontSize(1),
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    containerItem: {
        width: responsiveFontSize(6),
        borderWidth: responsiveFontSize(0.2),
        borderColor: colors.gray2
    },
    txtMes: {
        height: responsiveFontSize(3),
        fontSize: responsiveFontSize(1),
        fontWeight: "bold",
        textAlign: "center",
        paddingTop: responsiveFontSize(0.8)
    },
    txtPrice: {
        height: responsiveFontSize(3),
        fontSize: responsiveFontSize(1),
        textAlign: "center",
        paddingTop: responsiveFontSize(0.8),
        backgroundColor: colors.gray
    },
    containerBtn: {
        height: responsiveFontSize(3), 
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    btnBoleto: {
        backgroundColor: colors.blue,
        width: responsiveFontSize(2),
        height: responsiveFontSize(2),
        borderRadius: responsiveFontSize(0.2),
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center'
    },
    btn: {
        padding: responsiveFontSize(0.2),
        alignSelf: 'flex-end'
    },
    txtBtn: {
        color: colors.blue,
        fontSize: responsiveFontSize(1),
        marginTop: responsiveFontSize(2),
    },
    // Debito
    txtDeb: {
        height: responsiveFontSize(3),
        fontSize: responsiveFontSize(1),
        fontWeight: "bold",
        textAlign: "center",
        paddingTop: responsiveFontSize(0.8),
        color: colors.red
    },
    txtPriceDeb: {
        height: responsiveFontSize(3),
        fontSize: responsiveFontSize(1),
        textAlign: "center",
        paddingTop: responsiveFontSize(0.8),
        backgroundColor: colors.gray,
        color: colors.red
    },
    // empty
    txtEmp: {
        fontSize: responsiveFontSize(1),
        paddingVertical: responsiveFontSize(1)
    }
    
});
