
export function formatPrice(text){
    
    let number = text.replace(/[^\d]/g,"");
    let numberView = "";

    switch(number.length){
        case 3:
        numberView = number.replace(/(\d{1})(\d{2})/,"$1,$2");
        break;
        case 4:
        numberView = number.replace(/(\d{2})(\d{2})/,"$1,$2");
        break;
        case 5:
        numberView = number.replace(/(\d{3})(\d{2})/,"$1,$2");
        break;
        case 6:
        numberView = number.replace(/(\d{4})(\d{2})/,"$1,$2");
        break;
        case 7:
        numberView = number.replace(/(\d{5})(\d{2})/,"$1,$2");
        break;
        case 8:
        numberView = number.replace(/(\d{6})(\d{2})/,"$1,$2");
        break;
        case 9:
        numberView = number.replace(/(\d{7})(\d{2})/,"$1,$2");
        break;
        default:
        numberView = number;
        break;
    }
    return numberView;
}