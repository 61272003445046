import React, {useEffect, useState} from "react"
import { View, Text, TouchableOpacity, StyleSheet, TextInput } from "react-native"
import { responsiveFontSize } from "react-native-responsive-dimensions"
import { AntDesign, MaterialIcons  } from '@expo/vector-icons'; 
import colors from "../styles/colors";

export default function HeaderAssociados({
    iconBtn1, nameBtn1, colorBtn1, activeBtn1, activeSearch, navigationBtn1, callback,
    iconBtn2, nameBtn2, colorBtn2, activeBtn2, navigationBtn2, navigation, typeFamily
    }){
    const [search, setSearch] = useState(null)
    useEffect(()=>{
        if(search !== null){
            callback(search, 'search')
        }
    },[search])
    return(
        <View style={styles.containerBtn}>
            {activeBtn1 && (
                <TouchableOpacity style={styles.btn} onPress={()=>{navigation.navigate(navigationBtn1)}}>
                    <View style={[styles.containerIcon,{backgroundColor: colorBtn1}]}>
                        {typeFamily === 0 && (
                            <AntDesign name={iconBtn1} size={responsiveFontSize(4)} color="white" />
                        )}
                        {typeFamily === 1 && (
                            <MaterialIcons name={iconBtn1} size={responsiveFontSize(4)} color="white" />
                        )}
                    </View>
                    <Text style={styles.btnTxt}>{nameBtn1}</Text>
                </TouchableOpacity>
            )}
            {activeSearch && (
                <View style={styles.containerInput}>
                    <TextInput 
                        style={styles.input}
                        placeholder=''
                        onChangeText={setSearch}
                        value={search}
                        keyboardType={'default'}
                    />
                    <TouchableOpacity style={styles.iconSearch} onPress={()=>{callback(search, 'search')}}>
                        <AntDesign name="search1" size={responsiveFontSize(2)} color={colors.gray2} />
                    </TouchableOpacity>
                </View>
            )}

            {activeBtn2 && (
                <TouchableOpacity style={styles.btn} onPress={()=>{navigation.push(navigationBtn2)}}>
                    <View style={[styles.containerIcon,{backgroundColor: colorBtn2}]}>
                        {typeFamily === 0 && (
                            <AntDesign name={iconBtn2} size={responsiveFontSize(4)} color="white" />
                        )}
                        {typeFamily === 1 && (
                            <MaterialIcons name={iconBtn2} size={responsiveFontSize(4)} color="white" />
                        )}
                    </View>
                    <Text style={styles.btnTxt}>{nameBtn2}</Text>
                </TouchableOpacity>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    containerBtn: {
        width: responsiveFontSize(80),
        height: responsiveFontSize(10),
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: responsiveFontSize(1)
    },
    btn: {
        backgroundColor: colors.gray,
        borderRadius: responsiveFontSize(1),
        marginHorizontal: responsiveFontSize(0.5)
    },
    containerIcon: {
        borderRadius: responsiveFontSize(1),
        width: responsiveFontSize(12),
        height: responsiveFontSize(7),
        alignItems: 'center',
        justifyContent: 'center'
    },
    btnTxt: {
        fontSize: responsiveFontSize(1),
        padding: responsiveFontSize(0.8)
    },
    //input\
    containerInput: {
        flexDirection: 'row',
        width: responsiveFontSize(40),
        alignItems: 'center',
        alignSelf: 'center'
    },
    input: {
        width: responsiveFontSize(30),
        fontSize: responsiveFontSize(1),
        borderRadius: responsiveFontSize(1),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        padding: responsiveFontSize(0.5)
    },
    iconSearch: {
        padding: responsiveFontSize(0.5)
    }
})