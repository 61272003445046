import React from "react";
import { View, StyleSheet, FlatList, TouchableOpacity, Text } from "react-native"
import { responsiveFontSize } from "react-native-responsive-dimensions";
import { AntDesign } from "@expo/vector-icons"
import { format} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import Footer from "../components/footer";
import Header from "../components/header";
import HeaderAssociados from "../components/headerAssociados";
import Menu from "../components/menu";
import colors from "../styles/colors";
import { FormatPhone } from "../utils/formatPhone";

const data = [
    {
        key: "0001",
        name: "Antônio Santos Silva",
        cpf: "256.987.985-00",
        phone: "91987459578",
        email: "antonio.silva@gmail.com",
        dataNascimento: "08/07/1965",
        status: "Ativo",
        obs: "Diretor",
        mensalidade: "110.72",
        paymentHistory: [
            {
                create: 1642601151000,
                total: "110.70",
                typePayment: "Boleto",
                status: "Pago",
            },
            {
                create: 1645279551000,
                total: "110.70",
                typePayment: "Boleto",
                status: "Pago",
            },
            {
                create: 1647698751000,
                total: "110.70",
                typePayment: "Boleto",
                status: "Pago",
            },
            {
                create: 1650377151000,
                total: "110.70",
                typePayment: "Boleto",
                status: "Pago",
            },
            {
                create: 1652969151000,
                total: "110.70",
                typePayment: "Boleto",
                status: "Vencido",
            }
        ]
    },
    {
        key: "0002",
        name: "Carlos Souza",
        cpf: "112.356.489-99",
        phone: "91984563225",
        email: "carlossouza@gmail.com",
        dataNascimento: "10/09/1955",
        status: "Inativo",
        obs: "Associado",
        mensalidade: "97.19",
        paymentHistory: [
            {
                create: 1642601151000,
                total: "97.10",
                typePayment: "Boleto",
                status: "Pago",
            },
            {
                create: 1645279551000,
                total: "97.10",
                typePayment: "Boleto",
                status: "Pago",
            },
            {
                create: 1647698751000,
                total: "97.10",
                typePayment: "Boleto",
                status: "Pago",
            },
            {
                create: 1650377151000,
                total: "97.10",
                typePayment: "Boleto",
                status: "Pago",
            },
            {
                create: 1652969151000,
                total: "97.10",
                typePayment: "Boleto",
                status: "Em Aberto",
            }
        ]
    }
]
export default function EmissaoBoletos({navigation, route}){

    function renderItemList(item,index){
        return(
            <TouchableOpacity style={index%2 === 0 ? styles.containerItemList : styles.containerItemList2} onPress={()=>{}}>
                <Text style={styles.txtList}>{item.key}</Text>
                <Text style={styles.txtList}>{item.name}</Text>
                <Text style={styles.txtList}>{format(new Date(item.paymentHistory[4].create),'dd/MM/yyyy')}</Text>
                <Text style={styles.txtList}>{item.paymentHistory[4].status}</Text>
                <AntDesign style={[styles.txtList,{borderRightWidth: responsiveFontSize(0.1)}]} name={"barcode"} size={responsiveFontSize(2)} color={colors.blue} />
            </TouchableOpacity>
        )
    }

    return(
        <View style={styles.container}>
            <Header nav={navigation}/>
                <View style={styles.containerBody}>
                    <Menu num={3} name={route.name} navigation={navigation}/>
                    <View style={{width: responsiveFontSize(80)}}>
                    <TouchableOpacity style={styles.btn} onPress={()=>{navigation.navigate("EmissaoBoletos")}}>
                        <View style={styles.containerIcon}>
                            <AntDesign name={"barcode"} size={responsiveFontSize(4)} color="white" />
                        </View>
                    <Text style={styles.btnTxtHeader}>Boletos</Text>
                </TouchableOpacity>
                        <View style={styles.containerHeaderTable}>
                            <Text style={styles.txtTable}>Matrícula</Text>
                            <Text style={styles.txtTable}>Nome</Text>
                            <Text style={styles.txtTable}>Data</Text>
                            <Text style={styles.txtTable}>Status</Text>
                            <Text style={[styles.txtTable,{borderRightWidth: 0}]}>Ação</Text>
                        </View>
                        <FlatList
                            data={data}
                            keyExtractor={item => item.key}
                            renderItem={({item, index}) => renderItemList(item,index)}                   
                            style={styles.list}/>

                    </View>
                </View>
            <Footer/>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    containerBody: {
        flexDirection: 'row'
    },
    //list
    containerHeaderTable: {
        width: responsiveFontSize(79),
        flexDirection: 'row',
        marginLeft: responsiveFontSize(1.5),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        backgroundColor: 'white',
    },
    txtTable: {
        fontSize: responsiveFontSize(1),
        width: responsiveFontSize(16),
        borderRightWidth: responsiveFontSize(0.1),
        borderRightColor: colors.gray2,
        paddingHorizontal: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5)
    },
    list: {
        width: responsiveFontSize(79),
        height: responsiveFontSize(24),
        marginLeft: responsiveFontSize(1.5)
    },
    containerItemList: {
        flexDirection: 'row',
        backgroundColor: colors.gray
    },
    containerItemList2: {
        flexDirection: 'row',
        backgroundColor: "white"
    },
    txtList: {
        fontSize: responsiveFontSize(1),
        width: responsiveFontSize(16),
        borderLeftWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        paddingHorizontal: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5),
    },
    //btnHEader
    btn: {
        backgroundColor: colors.gray,
        width: responsiveFontSize(12),
        borderRadius: responsiveFontSize(1),
        margin: responsiveFontSize(1.5)
    },
    containerIcon: {
        borderRadius: responsiveFontSize(1),
        width: responsiveFontSize(12),
        height: responsiveFontSize(7),
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.blue
    },
    btnTxtHeader: {
        fontSize: responsiveFontSize(1),
        padding: responsiveFontSize(0.8)
    },
})