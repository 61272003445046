import React, { useEffect, useState } from "react";
import { View, StyleSheet, FlatList, TouchableOpacity, Text } from "react-native"
import { responsiveFontSize } from "react-native-responsive-dimensions";
import Footer from "../components/footer";
import Header from "../components/header";
import HeaderAssociados from "../components/headerAssociados";
import Menu from "../components/menu";
import { LoadUsers } from "../services/firebaseServices";
import colors from "../styles/colors";
import { createPdfAniversariantes } from "../utils/createPDF";
import { FormatPhone } from "../utils/formatPhone";

const data = [
    {
        key: "0001",
        name: "Antônio Santos Silva",
        cpf: "256.987.985-00",
        phone: "91987459578",
        email: "antonio.silva@gmail.com",
        dataNascimento: "08/07/1965",
        status: "Ativo",
        obs: "Diretor"
    },
    {
        key: "0002",
        name: "Carlos Souza",
        cpf: "112.356.489-99",
        phone: "91984563225",
        email: "carlossouza@gmail.com",
        dataNascimento: "10/09/1955",
        status: "Inativo",
        obs: "Associado"
    }
]

const pathRef = "/Users"
export default function Aniversariantes({navigation, route}){

    const [selectMes, setSelectMes] = useState(new Date().getMonth())
    const [users, setUsers] = useState(null)

    useEffect(()=>{
        LoadUsers(pathRef, response => {
            if(response !== null){
                let limp = response.filter(item => item.tpVinculo === "Associado")
                setUsers(limp)

            }
        })
    },[])

    const filtroUser = users?.filter(item => {
        let txtM = item.dataNascimento.split("/");
        let mes = parseInt(txtM[1]) - 1
        return mes === selectMes
    }).sort((a, b) => {
        let data1 = a.dataNascimento.split("/");
        let data2 = b.dataNascimento.split("/")

        let timestamp1 = parseInt(`${data1[0]}${data1[1]}`)
        let timestamp2 = parseInt(`${data2[0]}${data2[1]}`)
        // console.log("data 1: ", timestamp1, "data 2", timestamp2)

        if(timestamp1 < timestamp2){
            return -1
        }
        if(timestamp1 > timestamp2){
            return 1
        }

        return 0
    })

    // console.log(!filtroUser?.length)

    function renderItemList(item,index){
        return(
            <TouchableOpacity style={index%2 === 0 ? styles.containerItemList : styles.containerItemList2} onPress={()=>{}}>
                {/* <Text style={styles.txtList}>{item.key}</Text> */}
                <Text style={[styles.txtList,{width: responsiveFontSize(30)}]}>{item.name}</Text>
                <Text style={styles.txtList}>{item.dataNascimento}</Text>
                <Text style={styles.txtList}>{item.status}</Text>
                <Text style={[styles.txtList,{borderRightWidth: responsiveFontSize(0.1), width: responsiveFontSize(17)}]}>{item.tpVinculo}</Text>
            </TouchableOpacity>
        )
    }

    return(
        <View style={styles.container}>
            <Header nav={navigation}/>
                <View style={styles.containerBody}>
                    <Menu num={2} name={"Aniversariantes"} navigation={navigation}/>
                    <View style={{width: responsiveFontSize(80)}}>
                        {/* <HeaderAssociados 
                            colorBtn1={colors.red} iconBtn1={"cake"} nameBtn1={"Aniversariantes"} 
                            activeBtn1={true} navigationBtn1={"Aniversariantes"}
                            activeSearch={true} navigation={navigation}
                            colorBtn2={colors.gray3} iconBtn2={"message"} nameBtn2={"Envio de Mensagem"} 
                            activeBtn2={true} navigationBtn2={"EnvioMensagem"}
                            typeFamily={1}/> */}
                        <View style={styles.containerBtnMes}>
                            <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectMes(0)}}>
                                <Text style={selectMes === 0 ? [styles.txtBtnMes, {backgroundColor: colors.red}] : styles.txtBtnMes}>Janeiro</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectMes(1)}}>
                                <Text style={selectMes === 1 ? [styles.txtBtnMes, {backgroundColor: colors.red}] : styles.txtBtnMes}>Fevereiro</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectMes(2)}}>
                                <Text style={selectMes === 2 ? [styles.txtBtnMes, {backgroundColor: colors.red}] : styles.txtBtnMes}>Março</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectMes(3)}}>
                                <Text style={selectMes === 3 ? [styles.txtBtnMes, {backgroundColor: colors.red}] : styles.txtBtnMes}>Abril</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectMes(4)}}>
                                <Text style={selectMes === 4 ? [styles.txtBtnMes, {backgroundColor: colors.red}] : styles.txtBtnMes}>Maio</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectMes(5)}}>
                                <Text style={selectMes === 5 ? [styles.txtBtnMes, {backgroundColor: colors.red}] : styles.txtBtnMes}>Junho</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={[styles.containerBtnMes, {marginTop: responsiveFontSize(1), marginBottom: responsiveFontSize(3)}]}>
                            <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectMes(6)}}>
                                <Text style={selectMes === 6 ? [styles.txtBtnMes, {backgroundColor: colors.red}] : styles.txtBtnMes}>Julho</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectMes(7)}}>
                                <Text style={selectMes === 7 ? [styles.txtBtnMes, {backgroundColor: colors.red}] : styles.txtBtnMes}>Agosto</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectMes(8)}}>
                                <Text style={selectMes === 8 ? [styles.txtBtnMes, {backgroundColor: colors.red}] : styles.txtBtnMes}>Setembro</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectMes(9)}}>
                                <Text style={selectMes === 9 ? [styles.txtBtnMes, {backgroundColor: colors.red}] : styles.txtBtnMes}>Outubro</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectMes(10)}}>
                                <Text style={selectMes === 10 ? [styles.txtBtnMes, {backgroundColor: colors.red}] : styles.txtBtnMes}>Novembro</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectMes(11)}}>
                                <Text style={selectMes === 11 ? [styles.txtBtnMes, {backgroundColor: colors.red}] : styles.txtBtnMes}>Dezembro</Text>
                            </TouchableOpacity>
                        </View>
                        {!filtroUser?.length === false && (
                            <TouchableOpacity style={styles.btnPrint} onPress={()=>{createPdfAniversariantes(filtroUser)}}>
                                <Text style={styles.btnPrintTxt}>Imprimir</Text>
                            </TouchableOpacity>
                        )}
                        <View style={styles.containerHeaderTable}>
                            {/* <Text style={styles.txtTable}>Matrícula</Text> */}
                            <Text style={[styles.txtTable,{width: responsiveFontSize(30)}]}>Nome</Text>
                            <Text style={styles.txtTable}>Data</Text>
                            <Text style={styles.txtTable}>Status</Text>
                            <Text style={[styles.txtTable,{borderRightWidth: 0}]}>Observação</Text>
                        </View>
                        <FlatList
                            data={filtroUser}
                            keyExtractor={item => item.key}
                            renderItem={({item, index}) => renderItemList(item,index)}
                            ListEmptyComponent={(
                                <Text style={styles.txtEmpty}>Nenhum usário encontrado...</Text>
                            )}                
                            style={styles.list}/>

                    </View>
                </View>
            <Footer/>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    containerBody: {
        flexDirection: 'row'
    },
    //list
    containerHeaderTable: {
        width: responsiveFontSize(79),
        flexDirection: 'row',
        marginLeft: responsiveFontSize(1.5),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        backgroundColor: 'white',
    },
    txtTable: {
        fontSize: responsiveFontSize(1),
        width: responsiveFontSize(16),
        borderRightWidth: responsiveFontSize(0.1),
        borderRightColor: colors.gray2,
        paddingHorizontal: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5)
    },
    list: {
        width: responsiveFontSize(79),
        height: responsiveFontSize(24),
        marginLeft: responsiveFontSize(1.5)
    },
    containerItemList: {
        flexDirection: 'row',
        backgroundColor: colors.gray
    },
    containerItemList2: {
        flexDirection: 'row',
        backgroundColor: "white"
    },
    txtList: {
        fontSize: responsiveFontSize(1),
        width: responsiveFontSize(16),
        borderLeftWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        paddingHorizontal: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5),
    },
    txtEmpty: {
        backgroundColor: colors.gray2,
        bborderLeftWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        padding: responsiveFontSize(0.5),
        fontSize: responsiveFontSize(1)
    },
    // btn Mes
    containerBtnMes: {
        flexDirection: 'row',
        marginTop: responsiveFontSize(5),
        alignSelf: "center"
    },
    btnMes: {
        backgroundColor: colors.gray2,
        borderRadius: responsiveFontSize(1),
        height: responsiveFontSize(3),
        marginHorizontal: responsiveFontSize(0.5)
    },
    txtBtnMes: {
        borderRadius: responsiveFontSize(1),
        fontSize: responsiveFontSize(1.2),
        width: responsiveFontSize(10),
        paddingVertical: responsiveFontSize(0.3),
        backgroundColor: colors.gray3,
        textAlign: 'center',
        color: "white"
    },
    // btn Print
    btnPrint: {
        width: responsiveFontSize(5),
        paddingVertical: responsiveFontSize(0.2),
        marginRight: responsiveFontSize(1.5),
        alignItems: "center",
        justifyContent: "center",
        borderRadius: responsiveFontSize(1),
        marginBottom: responsiveFontSize(1),
        alignSelf: "flex-end",
        backgroundColor: colors.red
    },
    btnPrintTxt: {
        fontSize: responsiveFontSize(1),
        color: "white"
    }

})