import React, { useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { responsiveFontSize } from "react-native-responsive-dimensions";
import colors from "../styles/colors";

export default function HeaderRelatorios({selectedM, selectedY, callback}){

    return(
        <View>
            <View style={styles.containerBtnMes}>
                <TouchableOpacity style={styles.btnMes} onPress={()=>{callback(0,selectedY)}}>
                    <Text style={selectedM === 0 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Janeiro</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnMes} onPress={()=>{callback(1,selectedY)}}>
                    <Text style={selectedM === 1 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Fevereiro</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnMes} onPress={()=>{callback(2,selectedY)}}>
                    <Text style={selectedM === 2 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Março</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnMes} onPress={()=>{callback(3,selectedY)}}>
                    <Text style={selectedM === 3 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Abril</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnMes} onPress={()=>{callback(4,selectedY)}}>
                    <Text style={selectedM === 4 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Maio</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnMes} onPress={()=>{callback(5,selectedY)}}>
                    <Text style={selectedM === 5 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Junho</Text>
                </TouchableOpacity>
            </View>
            <View style={[styles.containerBtnMes, {marginTop: responsiveFontSize(1), marginBottom: responsiveFontSize(0)}]}>
                <TouchableOpacity style={styles.btnMes} onPress={()=>{callback(6,selectedY)}}>
                    <Text style={selectedM === 6 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Julho</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnMes} onPress={()=>{callback(7,selectedY)}}>
                    <Text style={selectedM === 7 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Agosto</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnMes} onPress={()=>{callback(8,selectedY)}}>
                    <Text style={selectedM === 8 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Setembro</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnMes} onPress={()=>{callback(9,selectedY)}}>
                    <Text style={selectedM === 9 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Outubro</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnMes} onPress={()=>{callback(10,selectedY)}}>
                    <Text style={selectedM === 10 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Novembro</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnMes} onPress={()=>{callback(11,selectedY)}}>
                    <Text style={selectedM === 11 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Dezembro</Text>
                </TouchableOpacity>
            </View>
            <View style={[styles.containerBtnMes, {marginTop: responsiveFontSize(1), marginBottom: responsiveFontSize(1)}]}>
                <TouchableOpacity style={styles.btnMes} onPress={()=>{callback(selectedM,2022)}}>
                    <Text style={selectedY === 2022 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>2022</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnMes} onPress={()=>{callback(selectedM,2023)}}>
                    <Text style={selectedY === 2023 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>2023</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnMes} onPress={()=>{callback(selectedM,2024)}}>
                    <Text style={selectedY === 2024 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>2024</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnMes} onPress={()=>{callback(selectedM,2025)}}>
                    <Text style={selectedY === 2025 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>2025</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnMes} onPress={()=>{callback(selectedM,2026)}}>
                    <Text style={selectedY === 2026 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>2026</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnMes} onPress={()=>{callback(selectedM,2027)}}>
                    <Text style={selectedY === 2027 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>2027</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    // btn Mes
    containerBtnMes: {
        flexDirection: 'row',
        marginTop: responsiveFontSize(2),
        alignSelf: "center"
    },
    btnMes: {
        backgroundColor: colors.gray2,
        borderRadius: responsiveFontSize(1),
        height: responsiveFontSize(3),
        marginHorizontal: responsiveFontSize(0.5)
    },
    txtBtnMes: {
        borderRadius: responsiveFontSize(1),
        fontSize: responsiveFontSize(1.2),
        width: responsiveFontSize(10),
        paddingVertical: responsiveFontSize(0.3),
        backgroundColor: colors.gray3,
        textAlign: 'center',
        color: "white"
    }

})