import react from "react";
import { View, Image, StyleSheet } from "react-native";
import { responsiveFontSize, responsiveWidth } from "react-native-responsive-dimensions";
import colors from "../styles/colors";

export default function Footer(){
    return(
        <View style={styles.container}>
            <Image style={styles.imgLogo} resizeMode={'contain'} source={require('../../assets/icones/2022 SAUER TELES.png')}/>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: responsiveWidth(100),
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopWidth: responsiveFontSize(0.2),
        borderTopColor: colors.gray2,
        marginTop: 'auto'
    },
    imgLogo: {
        width: responsiveFontSize(10),
        height: responsiveFontSize(2)
    }
})