import { SaveDocStorage } from "../services/firebaseServices";
import * as DocumentPicker from 'expo-document-picker';

export async function _pickDoc (pathDocStorage, idUser, name, callbackLoad, responseLink) {
    try {
      let result = await DocumentPicker.getDocumentAsync({});
      if (!result.cancelled) {
        uploadDoc(pathDocStorage, idUser, result.uri, name, data => callbackLoad(data), repo => responseLink(repo));
      }
      // console.log(result);
    } catch (E) {
      console.log(E);
    }
};

async function uploadDoc(pathDocStorage, idUser, uri, name, load, callLink){
    load(true)
    const response = await fetch(uri);
    const blob = await response.blob();

    const fileName = name;
    SaveDocStorage(`${pathDocStorage}/${idUser}/${fileName}`,blob, response => {
        callLink(response)
        load(false)
    })
}