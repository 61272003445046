import React, {useEffect, useState} from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity, Modal } from "react-native";
import { responsiveFontSize, responsiveWidth } from "react-native-responsive-dimensions";
import { AntDesign, Ionicons } from '@expo/vector-icons'; 

import colors from "../styles/colors";
import ModalInfoAccount from "./modalInfoAccount";
import { getDataObject, removeItem } from "../services/asyncStorageBase";

export default function Header({nav}){
    const [modal, setModal] = useState(false)
    const [name, setName] = useState("usuario@asacelpa.com")
    const [key, setKey] = useState(null)

    
    useEffect(()=>{
        getDataObject('user').then((value)=> {
            if(value !== null){
                setName(value.name)
                setKey(value.key)
            }
        });
    },[])
    return(
        <View style={styles.container}>
            <TouchableOpacity onPress={() => {nav.navigate("Inicio")}}>
                <Image style={styles.imgLogo} resizeMode="contain" source={require('../../assets/icones/Logo AsaCelpa.png')}/>
            </TouchableOpacity>
            <Text style={styles.txtTitle}>Sistema Asacelpa</Text>
            <TouchableOpacity style={{flexDirection: 'row', alignItems: 'center'}} onPress={()=>{setModal(!modal)}}>
                <AntDesign style={styles.iconUser} name="user" size={responsiveFontSize(1.5)} color={colors.blue} />
                <AntDesign name="down" size={responsiveFontSize(1)} color={'white'} />
            </TouchableOpacity>
            <Modal
                animationType='none'
                transparent={true}
                visible={modal}>
                <TouchableOpacity style={{flex: 1}} onPress={()=>{setModal(false)}}>
                    <View style={styles.centeredModal}>
                        <View style={styles.modalView}>
                            <View style={styles.containerUser}>
                                <View style={styles.iconModalUser}>
                                    <AntDesign name="user" size={responsiveFontSize(1.5)} color={colors.gray2} />
                                </View>
                                <Text style={styles.txtModal}>{name}</Text>
                            </View>
                            <TouchableOpacity style={styles.btnModal} onPress={()=>{
                                if(key !== null){
                                    setModal(false)
                                    nav.push("NovoFuncionario",{id: key})
                                }
                            }}>
                                <Ionicons name="person-outline" size={responsiveFontSize(1.5)} color={colors.blue} />
                                <Text  style={styles.txtModal}>Perfil</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.btnModal} onPress={()=>{
                                removeItem('user')
                                setModal(false)
                                nav.replace("Home")
                            }}>
                                <Ionicons name="ios-exit-outline" size={responsiveFontSize(1.5)} color={colors.blue} />
                                <Text  style={styles.txtModal}>Sair</Text>
                            </TouchableOpacity>
                            
                        </View>
                    </View>
                </TouchableOpacity>
            </Modal>
            
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: colors.blue,
        width: responsiveWidth(100),
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: responsiveFontSize(2)
    },
    imgLogo: {
        width: responsiveFontSize(3),
        height: responsiveFontSize(3),
        tintColor: 'white'
    },
    txtTitle: {
        color: "white",
        fontSize: responsiveFontSize(1.5)
    },
    iconUser: {
        backgroundColor: 'white',
        borderRadius: responsiveFontSize(50),
        paddingHorizontal: responsiveFontSize(0.1),
        marginHorizontal: responsiveFontSize(0.5)
    },
    // modal
    centeredModal: {
        backgroundColor: colors.gray2,
        width: responsiveFontSize(20),
        alignSelf: 'flex-end',
        marginTop: responsiveFontSize(3.5),
        marginRight: responsiveFontSize(0.5),
        padding: responsiveFontSize(1)
    },
    containerUser: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    iconModalUser: {
        backgroundColor: colors.gray3,
        paddingHorizontal: responsiveFontSize(0.1),
        borderRadius: responsiveFontSize(100)
    },
    btnModal: {
        flexDirection: 'row',
        paddingHorizontal: responsiveFontSize(1),
        paddingTop: responsiveFontSize(0.5),
        alignItems: 'center'
    },
    txtModal: {
        fontSize: responsiveFontSize(1),
        marginHorizontal: responsiveFontSize(0.5)
    }
})