import React, { useEffect, useState } from "react";
import { View, StyleSheet, FlatList, TouchableOpacity, Text } from "react-native"
import { AntDesign, MaterialIcons  } from '@expo/vector-icons'; 
import { responsiveFontSize } from "react-native-responsive-dimensions";
import Footer from "../components/footer";
import Header from "../components/header";
import HeaderAssociados from "../components/headerAssociados";
import Menu from "../components/menu";
import colors from "../styles/colors";
import { FormatPhone } from "../utils/formatPhone";
import { LoadRelatorio } from "../services/firebaseServices";
import LoadingModal from "../components/loadingModal";
import { createPdfReceitas } from "../utils/createPDF";

const data = [
    {
        key: "0001",
        name: "Antônio Santos Silva",
        cpf: "256.987.985-00",
        phone: "91987459578",
        email: "antonio.silva@gmail.com",
        dataNascimento: "08/07/1965",
        status: "Ativo",
        obs: "Diretor",
        mensalidade: "110.72",
        paymentHistory: [
            {
                create: 1642601151000,
                total: "110.70",
                typePayment: "Boleto",
                status: "Pago",
            },
            {
                create: 1645279551000,
                total: "110.70",
                typePayment: "Boleto",
                status: "Pago",
            },
            {
                create: 1647698751000,
                total: "110.70",
                typePayment: "Boleto",
                status: "Pago",
            },
            {
                create: 1650377151000,
                total: "110.70",
                typePayment: "Boleto",
                status: "Pago",
            },
            {
                create: 1652969151000,
                total: "110.70",
                typePayment: "Boleto",
                status: "Pago",
            }
        ]
    },
    {
        key: "0002",
        name: "Carlos Souza",
        cpf: "112.356.489-99",
        phone: "91984563225",
        email: "carlossouza@gmail.com",
        dataNascimento: "10/09/1955",
        status: "Inativo",
        obs: "Associado",
        mensalidade: "97.19",
        paymentHistory: [
            {
                create: 1642601151000,
                total: "97.10",
                typePayment: "Boleto",
                status: "Pago",
            },
            {
                create: 1645279551000,
                total: "97.10",
                typePayment: "Boleto",
                status: "Pago",
            },
            {
                create: 1647698751000,
                total: "97.10",
                typePayment: "Boleto",
                status: "Pago",
            },
            {
                create: 1650377151000,
                total: "97.10",
                typePayment: "Boleto",
                status: "Pago",
            },
            {
                create: 1652969151000,
                total: "97.10",
                typePayment: "Boleto",
                status: "Pago",
            }
        ]
    }
]
const pathRef = "/Payment"

export default function Receitas({navigation, route}){
    const [selectedM, setSelectedM] = useState(new Date().getMonth())
    const [selectedY, setSelectedY] = useState(new Date().getFullYear())
    const [users, setUsers] = useState(null)
    const [userFilter, setUserFilter] = useState(null)

    // modal
    const [loading, setLoading] = useState(false)
    useEffect(()=>{
        LoadRelatorio(pathRef, data => {
            if(data !== null){
                setLoading(true)
                setTimeout(() => {
                    setUsers(data)
                    setLoading(false)

                }, 1000);
            }
        })
    },[])

    useEffect(()=>{
        if(users !== null){
            setLoading(true)
            let limp = users.filter( item => {
                let dt = new Date(item.dateVencimento);

                return dt.getMonth() === selectedM && dt.getFullYear() === selectedY && item.status === "Aprovado"
            })

            let totalM = 0.0, totalUnim = 0.0, totalUniod = 0.0, totalAss = 0.0, totalOf = 0.0;

            if(!limp.length === false){
                limp.map(item => {
                    totalM += parseFloat(item.valorMensalidade);
                    totalUnim += parseFloat(item.totalUnimedGeral);
                    totalUniod += parseFloat(item.totalGeralUniodonto);
                    totalAss += parseFloat(item.totalAssistencia);
                    totalOf += parseFloat(item.totalOficinas);
                })
                let final = [
                    {
                        id: "001",
                        dateVencimento: limp[0].dateVencimento,
                        tipo: "Mensalidade",
                        total: totalM,
                    },
                    {
                        id: "002",
                        dateVencimento: limp[0].dateVencimento,
                        tipo: "Unimed",
                        total: totalUnim,
                    },
                    {
                        id: "003",
                        dateVencimento: limp[0].dateVencimento,
                        tipo: "Uniodonto",
                        total: totalUniod,
                    },
                    {
                        id: "004",
                        dateVencimento: limp[0].dateVencimento,
                        tipo: "Assistência Médica",
                        total: totalAss,
                    },
                    {
                        id: "005",
                        dateVencimento: limp[0].dateVencimento,
                        tipo: "Oficinas",
                        total: totalOf,
                    },
                ]
                let fimLimp = final.filter( item => item.total !== 0)
                setUserFilter(fimLimp)
            }else{
                setUserFilter(null)

            }

            setTimeout(() => {
                setLoading(false)

            }, 1000);
        }

    },[users, selectedM, selectedY])

    function renderItemList(item,index){
        return(
            <TouchableOpacity style={index%2 === 0 ? styles.containerItemList : styles.containerItemList2} onPress={()=>{}}>
                <Text style={styles.txtList}>{item.tipo}</Text>
                <Text style={styles.txtList}>Referente a {item.tipo}</Text>
                <Text style={styles.txtList}>R$ {item.total.toFixed(2)}</Text>
            </TouchableOpacity>
        )
    }

    return(
        <View style={styles.container}>
            <Header nav={navigation}/>
                <View style={styles.containerBody}>
                    <Menu num={3} name={route.name} navigation={navigation}/>
                    <View style={{width: responsiveFontSize(80)}}>
                        <View style={styles.containerBtn}>
                                <TouchableOpacity style={styles.btn} onPress={()=>{navigation.navigate("Receitas")}}>
                                    <View style={[styles.containerIcon,{backgroundColor: colors.blue}]}>
                                        <AntDesign name={"pluscircleo"} size={responsiveFontSize(4)} color="white" />
                                    </View>
                                    <Text style={styles.btnTxt}>Receitas</Text>
                                </TouchableOpacity>
                            {/* meio */}
                            <View>
                                {/* Mes 1 */}
                                <View style={styles.containerBtnMes}>
                                    <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectedM(0)}}>
                                        <Text style={selectedM === 0 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Janeiro</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectedM(1)}}>
                                        <Text style={selectedM === 1 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Fevereiro</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectedM(2)}}>
                                        <Text style={selectedM === 2 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Março</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectedM(3)}}>
                                        <Text style={selectedM === 3 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Abril</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectedM(4)}}>
                                        <Text style={selectedM === 4 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Maio</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectedM(5)}}>
                                        <Text style={selectedM === 5 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Junho</Text>
                                    </TouchableOpacity>
                                </View>
                                {/* Mes 2 */}
                                <View style={[styles.containerBtnMes, {marginTop: responsiveFontSize(1), marginBottom: responsiveFontSize(0)}]}>
                                    <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectedM(6)}}>
                                        <Text style={selectedM === 6 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Julho</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectedM(7)}}>
                                        <Text style={selectedM === 7 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Agosto</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectedM(8)}}>
                                        <Text style={selectedM === 8 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Setembro</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectedM(9)}}>
                                        <Text style={selectedM === 9 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Outubro</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectedM(10)}}>
                                        <Text style={selectedM === 10 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Novembro</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectedM(11)}}>
                                        <Text style={selectedM === 11 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>Dezembro</Text>
                                    </TouchableOpacity>
                                </View>
                                {/* Ano */}
                                <View style={[styles.containerBtnMes, {marginTop: responsiveFontSize(1), marginBottom: responsiveFontSize(1)}]}>
                                    <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectedY(2022)}}>
                                        <Text style={selectedY === 2022 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>2022</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectedY(2023)}}>
                                        <Text style={selectedY === 2023 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>2023</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectedY(2024)}}>
                                        <Text style={selectedY === 2024 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>2024</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectedY(2025)}}>
                                        <Text style={selectedY === 2025 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>2025</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectedY(2026)}}>
                                        <Text style={selectedY === 2026 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>2026</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.btnMes} onPress={()=>{setSelectedY(2027)}}>
                                        <Text style={selectedY === 2027 ? [styles.txtBtnMes, {backgroundColor: colors.blue}] : styles.txtBtnMes}>2027</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>

                                <TouchableOpacity style={styles.btn} onPress={()=>{navigation.navigate("Despesas")}}>
                                    <View style={[styles.containerIcon,{backgroundColor: colors.gray3}]}>
                                        <AntDesign name={"minuscircleo"} size={responsiveFontSize(4)} color="white" />
                                    </View>
                                    <Text style={styles.btnTxt}>Despesas</Text>
                                </TouchableOpacity>
                        </View>
                        {userFilter !== null && (
                            <TouchableOpacity style={styles.btnPrint} onPress={()=>{createPdfReceitas(userFilter)}}>
                                <Text style={styles.btnPrintTxt}>Imprimir</Text>
                            </TouchableOpacity>
                        )}
                        <View style={styles.containerHeaderTable}>
                            <Text style={styles.txtTable}>Item</Text>
                            <Text style={styles.txtTable}>Descrição</Text>
                            <Text style={[styles.txtTable,{borderRightWidth: 0}]}>Valor</Text>
                        </View>
                        <FlatList
                            data={userFilter}
                            keyExtractor={item => item.id}
                            renderItem={({item, index}) => renderItemList(item,index)}
                            ListEmptyComponent={(
                                <Text style={styles.txtEmpty}>Nenhum dado encontrado...</Text>
                            )}                    
                            style={styles.list}/>

                        <LoadingModal visible={loading} msg={"Aguarde, carregando..."}/>
                    </View>
                </View>
            <Footer/>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    containerBody: {
        flexDirection: 'row'
    },
    //list
    containerHeaderTable: {
        width: responsiveFontSize(79),
        flexDirection: 'row',
        marginLeft: responsiveFontSize(1.5),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        backgroundColor: 'white',
    },
    txtTable: {
        fontSize: responsiveFontSize(1),
        width: responsiveFontSize(25),
        borderRightWidth: responsiveFontSize(0.1),
        borderRightColor: colors.gray2,
        paddingHorizontal: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5)
    },
    list: {
        width: responsiveFontSize(79),
        height: responsiveFontSize(24),
        marginLeft: responsiveFontSize(1.5)
    },
    txtEmpty: {
        backgroundColor: colors.gray2,
        bborderLeftWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        padding: responsiveFontSize(0.5),
        fontSize: responsiveFontSize(1)
    },
    containerItemList: {
        flexDirection: 'row',
        backgroundColor: colors.gray
    },
    containerItemList2: {
        flexDirection: 'row',
        backgroundColor: "white"
    },
    txtList: {
        fontSize: responsiveFontSize(1),
        width: responsiveFontSize(25),
        borderLeftWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        paddingHorizontal: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5),
    },
    // Menu
    containerBtn: {
        width: responsiveFontSize(80),
        height: responsiveFontSize(10),
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: responsiveFontSize(1)
    },
    btn: {
        backgroundColor: colors.gray,
        borderRadius: responsiveFontSize(1),
        marginHorizontal: responsiveFontSize(0.5)
    },
    containerIcon: {
        borderRadius: responsiveFontSize(1),
        width: responsiveFontSize(12),
        height: responsiveFontSize(7),
        alignItems: 'center',
        justifyContent: 'center'
    },
    btnTxt: {
        fontSize: responsiveFontSize(1),
        padding: responsiveFontSize(0.8)
    },

    // btn Mes
    containerBtnMes: {
        flexDirection: 'row',
        marginTop: responsiveFontSize(2),
        alignSelf: "center"
    },
    btnMes: {
        backgroundColor: colors.gray2,
        borderRadius: responsiveFontSize(1),
        height: responsiveFontSize(2.5),
        marginHorizontal: responsiveFontSize(0.5)
    },
    txtBtnMes: {
        borderRadius: responsiveFontSize(1),
        fontSize: responsiveFontSize(1),
        width: responsiveFontSize(7),
        paddingVertical: responsiveFontSize(0.3),
        backgroundColor: colors.gray3,
        textAlign: 'center',
        color: "white"
    },
    // btn Print
    btnPrint: {
        width: responsiveFontSize(5),
        paddingVertical: responsiveFontSize(0.2),
        marginRight: responsiveFontSize(1.5),
        alignItems: "center",
        justifyContent: "center",
        borderRadius: responsiveFontSize(1),
        marginBottom: responsiveFontSize(1),
        alignSelf: "flex-end",
        backgroundColor: colors.red
    },
    btnPrintTxt: {
        fontSize: responsiveFontSize(1),
        color: "white"
    }
})