

export function FormatPhone(text){
  if(text !== null){
    let number = text.replace(/[^\d]/g,"");
    let numberView = "";

    switch(number.length){
      case 3:
        numberView = number.replace(/(\d{2})(\d{1})/,"($1) $2");
        break;
      case 4:
        numberView = number.replace(/(\d{2})(\d{2})/,"($1) $2");
        break;
      case 5:
        numberView = number.replace(/(\d{2})(\d{3})/,"($1) $2");
        break;
      case 6:
        numberView = number.replace(/(\d{2})(\d{4})/,"($1) $2");
        break;
      case 7:
        numberView = number.replace(/(\d{2})(\d{5})/,"($1) $2");
        break;
      case 8:
        numberView = number.replace(/(\d{2})(\d{5})(\d{1})/,"($1) $2-$3");
        break;
      case 9:
        numberView = number.replace(/(\d{2})(\d{5})(\d{2})/,"($1) $2-$3");
        break;
      case 10:
        numberView = number.replace(/(\d{2})(\d{5})(\d{3})/,"($1) $2-$3");
        break;
      case 11:
        numberView = number.replace(/(\d{2})(\d{5})(\d{4})/,"($1) $2-$3");
        break;
      default:
        numberView = number;
        break;
    }
      return numberView
    }
  }