
export function formatCpf(text){
    if(text !== null){

        let number = text.replace(/[^\d]/g,"");
        let numberView = "";

        switch(number.length){
            case 4:
                numberView = number.replace(/(\d{3})(\d{1})/,"$1.$2");
                break;
            case 5:
                numberView = number.replace(/(\d{3})(\d{2})/,"$1.$2");
                break;
            case 6:
                numberView = number.replace(/(\d{3})(\d{3})/,"$1.$2");
                break;
            case 7:
                numberView = number.replace(/(\d{3})(\d{3})(\d{1})/,"$1.$2.$3");
                break;
            case 8:
                numberView = number.replace(/(\d{3})(\d{3})(\d{2})/,"$1.$2.$3");
            break;
            case 9:
                numberView = number.replace(/(\d{3})(\d{3})(\d{3})/,"$1.$2.$3");
                break;
            case 10:
                numberView = number.replace(/(\d{3})(\d{3})(\d{3})(\d{1})/,"$1.$2.$3-$4");
                break;
            case 11:
                numberView = number.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,"$1.$2.$3-$4");
                break;
            case 12:
                numberView = number.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/,"$1.$2.$3/$4");
                break;
            case 13:
                numberView = number.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1})/,"$1.$2.$3/$4-$5");
                break;
            case 14:
                numberView = number.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,"$1.$2.$3/$4-$5");
                break;
            default:
            numberView = number;
            break;
        }
        return numberView;
        
    }
}