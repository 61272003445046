import React, { useEffect, useState } from "react";
import { View, StyleSheet, FlatList, TouchableOpacity, Text } from "react-native"
import { responsiveFontSize } from "react-native-responsive-dimensions";
import Footer from "../components/footer";
import Header from "../components/header";
import HeaderAssociados from "../components/headerAssociados";
import Menu from "../components/menu";
import { LoadUsers } from "../services/firebaseServices";
import colors from "../styles/colors";
import { formatCpf } from "../utils/formatCpf";
import { FormatPhone } from "../utils/formatPhone";

const pathRef = "/Funcionarios"
export default function Funcionarios({navigation, route}){
    const [data, setData] = useState(null)
    const [filterData, setFilterData] = useState(null)
    const [searchTxt, setSearchTxt] = useState('')

    useEffect(()=>{
        LoadUsers(pathRef, response => {
            if(response !== null){
                let limp = response.filter(item => item.tpVinculo === "Funcionário")
                setData(limp)

            }
        })
    },[])

    useEffect(()=>{
        if(data !== null){
            if(searchTxt !== ''){
                let _limp = data.filter(item => item.name.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1)
                console.log(_limp)
                setFilterData(_limp)
            }else{
                setFilterData(data)
            }
        }
    },[searchTxt,data])

    function renderItemList(item,index){
        return(
            <TouchableOpacity style={index%2 === 0 ? styles.containerItemList : styles.containerItemList2} onPress={()=>{
                navigation.push("NovoFuncionario",{id: item.key})
            }}>
                <Text style={styles.txtList}>{item.matricula}</Text>
                <Text style={styles.txtList}>{item.name}</Text>
                <Text style={styles.txtList}>{formatCpf(item.cpf)}</Text>
                <Text style={styles.txtList}>{FormatPhone(item.phone)}</Text>
                <Text style={[styles.txtList,{borderRightWidth: responsiveFontSize(0.1)}]}>{item.dtEntrada}</Text>
            </TouchableOpacity>
        )
    }

    return(
        <View style={styles.container}>
            <Header nav={navigation}/>
                <View style={styles.containerBody}>
                    <Menu num={1} name={"Funcionarios"} navigation={navigation}/>
                    <View style={{width: responsiveFontSize(80)}}>
                        <HeaderAssociados 
                            colorBtn1={colors.blue} iconBtn1={"idcard"} nameBtn1={"Funcionários"} 
                            activeBtn1={true} navigationBtn1={"Funcionarios"}
                            activeSearch={true} navigation={navigation}
                            colorBtn2={colors.gray3} iconBtn2={"adduser"} nameBtn2={"Novo Funcionário"} 
                            activeBtn2={true} navigationBtn2={"NovoFuncionario"}
                            typeFamily={0}
                            callback={(txtSearch,value) => {
                                setSearchTxt(txtSearch)
                            }}/>
                        <View style={styles.containerHeaderTable}>
                            <Text style={styles.txtTable}>Matrícula</Text>
                            <Text style={styles.txtTable}>Nome</Text>
                            <Text style={styles.txtTable}>CPF</Text>
                            <Text style={styles.txtTable}>Telefone</Text>
                            <Text style={[styles.txtTable,{borderRightWidth: 0}]}>Data de Adimissão</Text>
                        </View>
                        <FlatList
                            data={filterData}
                            keyExtractor={item => item.key}
                            renderItem={({item, index}) => renderItemList(item,index)}     
                            ListEmptyComponent={<Text style={styles.txtEmpty}>Nenhum funcionário encontrado...</Text>}              
                            style={styles.list}/>

                    </View>
                </View>
            <Footer/>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    containerBody: {
        flexDirection: 'row'
    },
    //list
    containerHeaderTable: {
        width: responsiveFontSize(79),
        flexDirection: 'row',
        marginLeft: responsiveFontSize(1.5),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        backgroundColor: 'white',
    },
    txtTable: {
        fontSize: responsiveFontSize(1),
        width: responsiveFontSize(16),
        borderRightWidth: responsiveFontSize(0.1),
        borderRightColor: colors.gray2,
        paddingHorizontal: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5)
    },
    list: {
        width: responsiveFontSize(79),
        height: responsiveFontSize(24),
        marginLeft: responsiveFontSize(1.5)
    },
    containerItemList: {
        flexDirection: 'row',
        backgroundColor: colors.gray
    },
    containerItemList2: {
        flexDirection: 'row',
        backgroundColor: "white"
    },
    txtList: {
        fontSize: responsiveFontSize(1),
        width: responsiveFontSize(16),
        borderLeftWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        paddingHorizontal: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5),
    },
    txtEmpty: {
        fontSize: responsiveFontSize(0.8),
        color: colors.gray3,
        paddingHorizontal: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5),
    }
})