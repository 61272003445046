import React,{useState} from "react"
import { View, Text, TouchableOpacity, TextInput, StyleSheet } from "react-native"
import { responsiveFontSize } from "react-native-responsive-dimensions"

import Footer from "../components/footer"
import Header from "../components/header"
import HeaderAssociados from "../components/headerAssociados"
import Menu from "../components/menu"
import colors from "../styles/colors"

export default function EnvioMensagem({navigation, route}){
    const[assunto, setAssunto] = useState('')
    const[msg, setMsg] = useState('')

    return(
        <View style={styles.container}>
            <Header nav={navigation}/>
            <View style={styles.containerBody}>
                <Menu num={2} name={route.name} navigation={navigation}/>
                <View style={styles.containerBodyF}>
                    <HeaderAssociados 
                        colorBtn1={colors.gray3} iconBtn1={"cake"} nameBtn1={"Aniversariantes"} 
                        activeBtn1={true} navigationBtn1={"Aniversariantes"}
                        activeSearch={false} navigation={navigation}
                        colorBtn2={colors.red} iconBtn2={"message"} nameBtn2={"Envio de Mensagem"} 
                        activeBtn2={true} navigationBtn2={"EnvioMensagem"}
                        typeFamily={1}/>
                    <View style={styles.containerInput}>
                        <Text style={styles.txtTitle}>Enviar Mensagem</Text>
                        <Text style={styles.txtSub}>Envio para todos os associados.</Text>
                        <Text style={styles.txtLabel}>Assunto</Text>
                        <TextInput 
                            style={styles.input}
                            placeholder=''
                            onChangeText={setAssunto}
                            value={assunto}
                            keyboardType={'default'}
                        />
                        <Text style={styles.txtLabel}>Mensagem</Text>
                        <TextInput 
                            style={[styles.input,{height: responsiveFontSize(8)}]}
                            multiline
                            placeholder=''
                            onChangeText={setMsg}
                            value={msg}
                            keyboardType={'default'}
                        />

                        <TouchableOpacity onPress={()=>{}}>
                            <Text style={styles.btnSend}>Enviar</Text>
                        </TouchableOpacity>

                    </View>
                    
                </View>
            </View>
            <Footer/>
        </View>
    )
}
    
    const styles = StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: 'white'
        },
        containerBody: {
            flexDirection: 'row'
        },
        containerBodyF: {
            width: responsiveFontSize(80)
        },
        //input
        containerInput: {
            width: responsiveFontSize(40),
            alignSelf: 'center'
        },
        txtTitle: {
            fontSize: responsiveFontSize(1.5)
        },
        txtSub: {
            fontSize: responsiveFontSize(1),
            color: colors.gray2,
            marginBottom: responsiveFontSize(1)
        },
        txtLabel: {
            fontSize: responsiveFontSize(1),
            marginVertical: responsiveFontSize(0.5)
        },
        input: {
            width: responsiveFontSize(30),
            borderWidth: responsiveFontSize(0.1),
            borderColor: colors.gray2,
            fontSize: responsiveFontSize(1),
            padding: responsiveFontSize(0.5),
            borderRadius: responsiveFontSize(0.2)
        },
        btnSend: {
            width: responsiveFontSize(5),
            textAlign: 'center',
            fontSize: responsiveFontSize(1),
            backgroundColor: colors.blue,
            color: 'white',
            padding: responsiveFontSize(0.5),
            borderRadius: responsiveFontSize(1),
            marginTop: responsiveFontSize(2)
        }
        
    })