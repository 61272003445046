import React,{useState} from "react";
import { View, Text, StyleSheet, Image, TextInput, TouchableOpacity } from 'react-native'
import { responsiveFontSize } from "react-native-responsive-dimensions";
import { AntDesign } from '@expo/vector-icons'

import colors from "../styles/colors";
import Footer from "../components/footer";
import { checkLogin } from "../services/firebaseServices";
import LoadingModal from "../components/loadingModal";
import ModalInfoAccount from "../components/modalInfoAccount";
import { storeDataObject } from "../services/asyncStorageBase";

export default function Home({navigation}) {
    const [login, setLogin] = useState('')
    const [pass, setPass] = useState('')

    // modal
    const [load, setLoad] = useState(false)
    const [modalInfo, setModalInfo] = useState(false)
    const [msgModal, setMsgModal] = useState(null)
    function check(){
        setLoad(true)
        if(login !== null && login !== ''
            && pass !== null && pass !== ''){

                checkLogin("/Funcionarios", login, response => {
                    if(response !== null){
                        // console.log(pass)
                        if(response[0].password === pass){
                            let user = {
                                name: response[0].name,
                                key: response[0].key
                            }
                            storeDataObject("user", user)
                            setLoad(false)
                            navigation.replace("Inicio")
                        }else{
                            setLoad(false)
                            setMsgModal("Login ou senha incorretas.")
                            setModalInfo(true)
                        }
                    }else{
                        setLoad(false)
                        setMsgModal("Login ou senha incorretas.")
                        setModalInfo(true)
                    }
                })
        }else{
            setLoad(false)
            setMsgModal("Por favor, preencha todos os campos para continuar.")
            setModalInfo(true)
        }
    }
    return(
        <View style={styles.container}>
            <View style={styles.containerPainel}>
                <Image style={styles.imgLogo} source={require('../../assets/icones/Logo AsaCelpa.png')}/>
                <View style={styles.containerInput}>
                    <Text style={styles.label}>Usuário</Text>
                    <TextInput 
                        style={styles.input}
                        placeholder=''
                        onChangeText={setLogin}
                        value={login}
                        keyboardType={'default'}
                    />
                </View>
                <View style={styles.containerInput}>
                    <Text style={styles.label}>Senha</Text>
                    <TextInput 
                        style={styles.input}
                        placeholder=''
                        onChangeText={setPass}
                        value={pass}
                        keyboardType={'default'}
                        secureTextEntry={true}
                    />
                </View>
                {/* <TouchableOpacity onPress={()=>{}}>
                    <Text style={styles.btnPass}>Esqueci a senha</Text>
                </TouchableOpacity> */}
                <View style={styles.linha}/>
                <ModalInfoAccount 
                    visible={modalInfo}
                    title={"Essa não 🤔"}
                    msg={msgModal}
                    callback={(value)=>{
                        setModalInfo(false)
                    }}
                />
                <LoadingModal
                    visible={load}
                    msg={"Aguarde, verificando informações..."}
                />
                <View style={styles.containerBtn}>
                    <TouchableOpacity style={styles.btn} onPress={()=>{setLogin('');setPass('')}}>
                        <AntDesign name="close" size={responsiveFontSize(1.5)} color="red" />
                        <Text style={styles.btnTxt}>Cancelar</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.btn} onPress={()=>{check()}}>
                        <AntDesign name="check" size={responsiveFontSize(1.5)} color={colors.green} />
                        <Text style={styles.btnTxt}>Ok</Text>
                    </TouchableOpacity>
                </View>
            </View>
            <Footer/>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white'
    },
    containerPainel: {
        width: responsiveFontSize(30),
        height: responsiveFontSize(30),
        backgroundColor: colors.gray,
        borderRadius: responsiveFontSize(1),
        alignItems: 'center',
        marginTop: 'auto'
    },
    imgLogo: {
        width: responsiveFontSize(10),
        height: responsiveFontSize(10),
        marginBottom: responsiveFontSize(4)
    },
    containerInput: {
        flexDirection: 'row',
        marginTop: responsiveFontSize(0.5)
    },
    label: {
        width: responsiveFontSize(5),
        fontSize: responsiveFontSize(1.2)
    },
    input: {
        backgroundColor: colors.blue2,
        width: responsiveFontSize(20),
        borderRadius: responsiveFontSize(1),
        paddingHorizontal: responsiveFontSize(1),
        fontSize: responsiveFontSize(1)

    },
    btnPass: {
        fontSize: responsiveFontSize(0.8),
        color: 'red',
        marginTop: responsiveFontSize(1)
    },
    linha: {
        backgroundColor: colors.gray2,
        height: responsiveFontSize(0.5),
        width: responsiveFontSize(28),
        borderRadius: responsiveFontSize(1),
        marginTop: responsiveFontSize(5)
    },
    containerBtn: {
        flexDirection: 'row',
        paddingVertical: responsiveFontSize(0.5),
        alignSelf: 'flex-end',
        paddingHorizontal: responsiveFontSize(2)
    },
    btn: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: responsiveFontSize(0.5),
        borderRadius: responsiveFontSize(1),
        backgroundColor: colors.gray2,
        marginHorizontal: responsiveFontSize(0.5)
    },
    btnTxt: {
        fontSize: responsiveFontSize(1)
    }
})