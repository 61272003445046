import React, { useEffect, useState } from "react";
import { View, StyleSheet, FlatList, TouchableOpacity, Text } from "react-native"
import { responsiveFontSize } from "react-native-responsive-dimensions";
import Footer from "../components/footer";
import Header from "../components/header";
import HeaderRelatorios from "../components/headerRelatorios";
import LoadingModal from "../components/loadingModal";
import Menu from "../components/menu";
import { LoadRelatorio } from "../services/firebaseServices";
import colors from "../styles/colors";
import { createPdfRelatorio } from "../utils/createPDF";

const pathRef = "/Payment"
export default function Relatorios({navigation, route}){

    const [selectMes, setSelectMes] = useState(new Date().getMonth())
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [users, setUsers] = useState(null)
    const [userFilter, setUserFilter] = useState(null)

    // modal
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        LoadRelatorio(pathRef, data => {
            if(data !== null){
                setLoading(true)
                setTimeout(() => {
                    setUsers(data)
                    setLoading(false)

                }, 1000);
            }
        })
    },[])

    useEffect(()=>{
        if(users !== null){
            setLoading(true)
            let limp = users.filter( item => {
                let dt = new Date(item.dateVencimento);

                return dt.getMonth() === selectMes && dt.getFullYear() === selectedYear
            })

            setTimeout(() => {
                setLoading(false)

            }, 1000);
            setUserFilter(limp)
        }

    },[users, selectMes, selectedYear])

    function renderItemList(item,index){
        return(
            <TouchableOpacity style={index%2 === 0 ? styles.containerItemList : styles.containerItemList2} onPress={()=>{}}>
                {/* <Text style={styles.txtList}>{item.key}</Text> */}
                <Text style={[styles.txtList,{width: responsiveFontSize(30)}]}>{item?.user?.name}</Text>
                <Text style={styles.txtList}>R$ {parseFloat(item?.user?.valorINSS).toFixed(2)}</Text>
                <Text style={styles.txtList}>R$ {parseFloat(item?.user?.planoEQTPREV).toFixed(2)}</Text>
                <Text style={styles.txtList}>R$ {parseFloat(item.totalUnimedGeral).toFixed(2)}</Text>
                <Text style={styles.txtList}>R$ {parseFloat(item.totalGeralUniodonto).toFixed(2)}</Text>
                <Text style={styles.txtList}>R$ {parseFloat(item.totalOficinas).toFixed(2)}</Text>
                <Text style={styles.txtList}>R$ {parseFloat(item.totalAssistencia).toFixed(2)}</Text>
                <Text style={styles.txtList}>R$ {parseFloat(item?.user?.valorMensalidade).toFixed(2)}</Text>
                <Text style={[styles.txtList,{borderRightWidth: responsiveFontSize(0.1), width: responsiveFontSize(17)}]}>{item.status}</Text>
            </TouchableOpacity>
        )
    }

    return(
        <View style={styles.container}>
            <Header nav={navigation}/>
                <View style={styles.containerBody}>
                    <Menu num={3} name={route.name} navigation={navigation} sub={2}/>
                    <View style={{width: responsiveFontSize(80)}}>
                        <HeaderRelatorios selectedM={selectMes} selectedY={selectedYear} callback={(month,year) => {
                            setSelectMes(month)
                            setSelectedYear(year)
                        }}/>
                        {userFilter !== null && (
                            <TouchableOpacity style={styles.btnPrint} onPress={()=>{createPdfRelatorio(userFilter)}}>
                                <Text style={styles.btnPrintTxt}>Imprimir</Text>
                            </TouchableOpacity>
                        )}
                        <View style={styles.containerHeaderTable}>
                            {/* <Text style={styles.txtTable}>Matrícula</Text> */}
                            <Text style={[styles.txtTable,{width: responsiveFontSize(30)}]}>Nome</Text>
                            <Text style={styles.txtTable}>INSS</Text>
                            <Text style={styles.txtTable}>EQTPREV</Text>
                            <Text style={styles.txtTable}>Unimed</Text>
                            <Text style={styles.txtTable}>Uniodonto</Text>
                            <Text style={styles.txtTable}>Oficinas</Text>
                            <Text style={styles.txtTable}>Ass. Médica</Text>
                            <Text style={styles.txtTable}>Mensalidade</Text>
                            <Text style={[styles.txtTable,{borderRightWidth: 0, width: responsiveFontSize(18.1)}]}>Status</Text>
                        </View>
                        <FlatList
                            data={userFilter}
                            keyExtractor={item => item.id}
                            renderItem={({item, index}) => renderItemList(item,index)}
                            ListEmptyComponent={(
                                <Text style={styles.txtEmpty}>Nenhum usário encontrado...</Text>
                            )}                
                            style={styles.list}/>

                        <LoadingModal visible={loading} msg={"Aguarde, carregando..."}/>

                    </View>
                </View>
            <Footer/>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    containerBody: {
        flexDirection: 'row'
    },
    //list
    containerHeaderTable: {
        width: responsiveFontSize(79),
        flexDirection: 'row',
        marginLeft: responsiveFontSize(1.5),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        backgroundColor: 'white',
    },
    txtTable: {
        fontSize: responsiveFontSize(1),
        width: responsiveFontSize(10),
        borderRightWidth: responsiveFontSize(0.1),
        borderRightColor: colors.gray2,
        paddingLeft: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5),
    },
    list: {
        width: responsiveFontSize(79),
        height: responsiveFontSize(24),
        marginLeft: responsiveFontSize(1.5)
    },
    containerItemList: {
        flexDirection: 'row',
        backgroundColor: colors.gray
    },
    containerItemList2: {
        flexDirection: 'row',
        backgroundColor: "white"
    },
    txtList: {
        fontSize: responsiveFontSize(1),
        width: responsiveFontSize(10),
        borderLeftWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        paddingLeft: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5)
    },
    txtEmpty: {
        backgroundColor: colors.gray2,
        bborderLeftWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        padding: responsiveFontSize(0.5),
        fontSize: responsiveFontSize(1)
    },
    // btn Mes
    containerBtnMes: {
        flexDirection: 'row',
        marginTop: responsiveFontSize(5),
        alignSelf: "center"
    },
    btnMes: {
        backgroundColor: colors.gray2,
        borderRadius: responsiveFontSize(1),
        height: responsiveFontSize(3),
        marginHorizontal: responsiveFontSize(0.5)
    },
    txtBtnMes: {
        borderRadius: responsiveFontSize(1),
        fontSize: responsiveFontSize(1.2),
        width: responsiveFontSize(10),
        paddingVertical: responsiveFontSize(0.3),
        backgroundColor: colors.gray3,
        textAlign: 'center',
        color: "white"
    },
    // btn Print
    btnPrint: {
        width: responsiveFontSize(5),
        paddingVertical: responsiveFontSize(0.2),
        marginRight: responsiveFontSize(1.5),
        alignItems: "center",
        justifyContent: "center",
        borderRadius: responsiveFontSize(1),
        marginBottom: responsiveFontSize(1),
        alignSelf: "flex-end",
        backgroundColor: colors.red
    },
    btnPrintTxt: {
        fontSize: responsiveFontSize(1),
        color: "white"
    }

})