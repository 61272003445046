import firebase from 'firebase/compat/app';
import { initializeAnalytics } from 'firebase/analytics';
import 'firebase/compat/database'
import 'firebase/compat/storage'
const firebaseConfig = {
    apiKey: "AIzaSyACAJhyYU2JYqjAbw58qwjVlJxn9RO1a24",
    authDomain: "as4celpa.firebaseapp.com",
    databaseURL: "https://as4celpa-default-rtdb.firebaseio.com",
    projectId: "as4celpa",
    storageBucket: "as4celpa.appspot.com",
    messagingSenderId: "711774991283",
    appId: "1:711774991283:web:a5ab364a3689ec090551c6",
    measurementId: "G-YFH3VGMW49"
};

export const firebaseImpl = firebase.initializeApp(firebaseConfig);
export const firebaseAnalytics = initializeAnalytics(firebaseImpl);
export const firebaseDatabase = firebase.database();
export const firebaseStorage = firebase.storage();

