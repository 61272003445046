import React, { useEffect, useState } from "react";
import { View, StyleSheet, FlatList, TouchableOpacity, Text } from "react-native"
import { responsiveFontSize } from "react-native-responsive-dimensions";
import Footer from "../components/footer";
import Header from "../components/header";
import HeaderAssociados from "../components/headerAssociados";
import Menu from "../components/menu";
import { LoadUsers } from "../services/firebaseServices";
import colors from "../styles/colors";
import { createListPdf } from "../utils/createPDF";
import { formatCpf } from "../utils/formatCpf";
import { FormatPhone } from "../utils/formatPhone";

const pathRef = "/Users"
export default function Associados({navigation, route}){
    const [data, setData] = useState(null)
    const [filterData, setFilterData] = useState(null)
    const [searchTxt, setSearchTxt] = useState('')

    useEffect(()=>{
        LoadUsers(pathRef, response => {
            if(response !== null){
                let limp = response.filter(item => item.tpVinculo === "Associado" && item.status === "Ativo")
                let ordenar = limp.sort((a,b) => {
                    let x = a.name.toLowerCase(),
                        y = b.name.toLowerCase();

                    return x === y ? 0 : x > y ? 1 : -1;

                })
                setData(ordenar)

            }
        })
    },[])

    useEffect(()=>{
        if(data !== null){
            if(searchTxt !== ''){
                let _limp = data.filter(item => item.name.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1)
                // console.log(_limp)
                setFilterData(_limp)
            }else{
                setFilterData(data)
            }
        }
    },[searchTxt,data])

    function renderItemList(item,index){
        return(
            <TouchableOpacity style={index%2 === 0 ? styles.containerItemList : styles.containerItemList2} onPress={()=>{
                navigation.push("NovoAssociado",{id: item.key})
            }}>
                <Text style={styles.txtList}>{item.matricula}</Text>
                <Text style={styles.txtList}>{item.name}</Text>
                <Text style={styles.txtList}>{formatCpf(item.cpf)}</Text>
                <Text style={styles.txtList}>{FormatPhone(item.phone)}</Text>
                <Text style={[styles.txtList,{borderRightWidth: responsiveFontSize(0.1)}]}>{item.email}</Text>
            </TouchableOpacity>
        )
    }

    return(
        <View style={styles.container}>
            <Header nav={navigation}/>
                <View style={styles.containerBody}>
                    <Menu num={1} name={"Associados"} navigation={navigation}/>
                    <View style={{width: responsiveFontSize(80)}}>
                        <HeaderAssociados 
                            colorBtn1={colors.blue} iconBtn1={"team"} nameBtn1={"Associados"} 
                            activeBtn1={true} navigationBtn1={"Associados"}
                            activeSearch={true} navigation={navigation}
                            colorBtn2={colors.gray3} iconBtn2={"adduser"} nameBtn2={"Novo Associado"} 
                            activeBtn2={true} navigationBtn2={"NovoAssociado"}
                            typeFamily={0}
                            callback={(txtSearch,value) => {
                                setSearchTxt(txtSearch)
                            }}/>

                        {/* <TouchableOpacity onPress={()=>{createListPdf(filterData)}}>
                            <Text>Imprimir</Text>
                        </TouchableOpacity> */}

                        <View style={styles.containerHeaderTable}>
                            <Text style={styles.txtTable}>Matrícula</Text>
                            <Text style={styles.txtTable}>Nome</Text>
                            <Text style={styles.txtTable}>CPF</Text>
                            <Text style={styles.txtTable}>Telefone</Text>
                            <Text style={[styles.txtTable,{borderRightWidth: 0}]}>E-mail</Text>
                        </View>
                        <FlatList
                            data={filterData}
                            keyExtractor={item => item.key}
                            renderItem={({item, index}) => renderItemList(item,index)}     
                            ListEmptyComponent={<Text style={styles.txtEmpty}>Nenhum associado encontrado...</Text>}              
                            style={styles.list}/>

                    </View>
                </View>
            <Footer/>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    containerBody: {
        flexDirection: 'row'
    },
    //list
    containerHeaderTable: {
        width: responsiveFontSize(79),
        flexDirection: 'row',
        marginLeft: responsiveFontSize(1.5),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        backgroundColor: 'white',
    },
    txtTable: {
        fontSize: responsiveFontSize(1),
        width: responsiveFontSize(16),
        borderRightWidth: responsiveFontSize(0.1),
        borderRightColor: colors.gray2,
        paddingHorizontal: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5)
    },
    list: {
        width: responsiveFontSize(79),
        height: responsiveFontSize(24),
        marginLeft: responsiveFontSize(1.5)
    },
    containerItemList: {
        flexDirection: 'row',
        backgroundColor: colors.gray
    },
    containerItemList2: {
        flexDirection: 'row',
        backgroundColor: "white"
    },
    txtList: {
        fontSize: responsiveFontSize(1),
        width: responsiveFontSize(16),
        borderLeftWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        paddingHorizontal: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5),
    },
    txtEmpty: {
        fontSize: responsiveFontSize(0.8),
        color: colors.gray3,
        paddingHorizontal: responsiveFontSize(0.5),
        paddingVertical: responsiveFontSize(0.5),
    }
})