import { firebaseDatabase, firebaseStorage } from '../../firebase'

export function saveUser(Ref, user, callback){
  firebaseDatabase.ref(Ref).orderByChild("matricula").equalTo(user.matricula).once("value", dataSnapshot =>{
    if(dataSnapshot.exists()){
      dataSnapshot.forEach(childSnapshot => {
        firebaseDatabase.ref(Ref).child(childSnapshot.key).update(user)
          .then(()=>{callback(true)})
          .catch(()=>{callback(null)})
        
      })
    }else{
      firebaseDatabase.ref(Ref).push().set(user)
          .then(()=>{callback(true)})
          .catch(()=>{callback(null)})
    }
  })
}

export function LoadUsers(Ref, callback){
    firebaseDatabase.ref(Ref).on("value", dataSnapshot =>{
        if(dataSnapshot.exists()){
            let items =[];
            dataSnapshot.forEach(childSnapshot => {
              let item = childSnapshot.val();
              item['key'] = childSnapshot.key;
              items.unshift(item);
            })
            callback(items);
          }else{
            callback(null);
          }
    })
}

export function LoadNameOneDependente(Ref, idDep, callback){
  firebaseDatabase.ref(Ref).orderByKey().equalTo(idDep).once("value", dataSnapshot => {
    if(dataSnapshot.exists()){
      callback(dataSnapshot.val().name)
    }else{
      callback(null)
    }
  })
}

export function LoadDependentes(Ref, idAssociado, callback){
  firebaseDatabase.ref(Ref).orderByChild("idAssociado").equalTo(idAssociado).on("value", dataSnapshot =>{
    if(dataSnapshot.exists()){
      let items =[];
      dataSnapshot.forEach(childSnapshot => {
        let item = childSnapshot.val();
        item['key'] = childSnapshot.key;
        items.unshift(item);
      })
      callback(items);
    }else{
      callback(null);
    }
  })
}

export function RemoveDep(Ref, idAssociado, idDep){
  firebaseDatabase.ref(Ref).orderByKey().equalTo(idAssociado).once("value", dataSnapshot => {
    if(dataSnapshot.exists()){
      dataSnapshot.forEach(childSnapshot => {
        let dep = childSnapshot.val().dependentes;
        if(dep !== undefined){
          let limp = dep.filter(it => it.key !== idDep)
          firebaseDatabase.ref(`${Ref}/${idAssociado}`).update({dependentes: limp});
        }
      })
    }
  })
}

export function LoadOneUser(Ref,userId, callback){
    firebaseDatabase.ref(Ref).child(userId).on("value", dataSnapshot =>{
        if(dataSnapshot.exists()){
            let items = dataSnapshot.val();
            items['key'] = dataSnapshot.key;
            callback(items);
          }else{
            callback(null);
          }
    })
}

export function RemoveUser(Ref, key, callback){
  firebaseDatabase.ref(Ref).child(key).remove().then(()=>{callback(true)}).catch(()=>{callback(false)})
}

export function checkMatricula(Ref, matricula, callback){
    firebaseDatabase.ref(Ref).orderByChild("matricula").equalTo(matricula).on("value", dataSnapshot =>{
        if(dataSnapshot.exists()){
            callback(true)
        }else{
            callback(false)
        }
    })
}

export function LoadPayment(Ref, key, callback){
  firebaseDatabase.ref(Ref).orderByChild("idUser").equalTo(key).on("value", dataSnapshot =>{
      if(dataSnapshot.exists()){
          let items =[];
          dataSnapshot.forEach(childSnapshot => {
            let item = childSnapshot.val();
            item['key'] = childSnapshot.key;
            items.unshift(item);
          })
          callback(items);
        }else{
          callback(null);
        }
  })
}

export function UpdatePayment(Ref, key, payment, callback){
  firebaseDatabase.ref(Ref).child(key).update(payment).then(()=>callback(true)).catch(()=>callback(false))
}

export function saveAssM(Ref, assistencia, callback){
  firebaseDatabase.ref(Ref).push().set(assistencia).then(()=>{callback(true)}).catch(()=>{callback(false)})
}

export function LoadAssiM(Ref, key, callback){
  firebaseDatabase.ref(Ref).orderByChild("idUser").equalTo(key).on("value", dataSnapshot =>{
      if(dataSnapshot.exists()){
          let items =[];
          dataSnapshot.forEach(childSnapshot => {
            let item = childSnapshot.val();
            item['key'] = childSnapshot.key;
            items.unshift(item);
          })
          callback(items);
        }else{
          callback(null);
        }
  })
}

export function UpdateAssistencia(Ref, key, assistencia, callback){
  firebaseDatabase.ref(Ref).child(key).update(assistencia).then(()=>callback(true)).catch(()=>callback(false))
}

export function deleteItem(Ref, key, callback){
  firebaseDatabase.ref(Ref).child(key).remove().then(()=>{callback(true)}).catch(()=>{callback(false)})
}

//Doc
export function SaveDocStorage(Ref, file, callback){
  const uploadTask = firebaseStorage.ref(Ref).put(file);
 
  uploadTask.on('state_changed', function(snapshot){
    //var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    
    // switch (snapshot.state) {
    //   case firebase.storage.TaskState.PAUSED: // or 'paused'
    //     console.log('Upload is paused');
    //     break;
    //   case firebase.storage.TaskState.RUNNING: // or 'running'
    //     console.log('Upload is running');
    //     break;
    // }
  }, function(error) {
      console.log(error)
  }, function() {
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
      callback(downloadURL)
    });
  });
}

//delete Doc
export function DeleteDocStorage(Ref){
  firebaseStorage.ref(Ref).delete()
}

// Login
export function checkLogin(Ref, matricula, callback){
  firebaseDatabase.ref(Ref).orderByChild("matricula").equalTo(matricula).on("value", dataSnapshot =>{
      if(dataSnapshot.exists()){
        let items =[];
          dataSnapshot.forEach(childSnapshot => {
            let item = childSnapshot.val();
            item['key'] = childSnapshot.key;
            items.unshift(item);
          })
          callback(items);
      }else{
          callback(null)
      }
  })
}

export function LoadRelatorio(Ref, callback){
  firebaseDatabase.ref(Ref).on("value", dataSnapshot => {
    if(dataSnapshot.exists()){
      let items =[];
        dataSnapshot.forEach(childSnapshot => {
          let item = childSnapshot.val();
          item['id'] = childSnapshot.key;
          firebaseDatabase.ref("/Users").child(childSnapshot.val().idUser).once("value", dataSnapshot2 =>{
            if(dataSnapshot2.exists()){
                item['user'] = dataSnapshot2.val();
              }
            })
          items.unshift(item);
        })
        callback(items);
    }else{
        callback(null)
    }
  })
}