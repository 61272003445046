import React from 'react'
import {View, StyleSheet, Modal, Text, TouchableOpacity} from 'react-native'
import { responsiveFontSize, responsiveWidth } from 'react-native-responsive-dimensions';
import colors from '../styles/colors';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR'

export default function ModalViewOneMensalidade({visible, item, callback}){
    return(
        <View style={styles.centeredView}>
            <Modal
                animationType='fade'
                transparent={true}
                visible={visible}>

                <View style={styles.centeredModal}>
                    <View style={styles.modalView}>
                        <Text style={styles.txtMes}>{format(new Date(item.dateVencimento), "MMMM", {locale: ptBR}).toUpperCase()}</Text>
                        <Text style={styles.txtMsg1}>Status: {item.status}</Text>
                        <Text style={styles.txtMsg1}>Data de Vencimento: {format(new Date(item.dateVencimento), "dd/MM/yyyy", {locale: ptBR})}</Text>
                        <View style={{marginTop: responsiveFontSize(0.5),height: responsiveFontSize(0.1), backgroundColor: colors.gray2}}/>
                        <View style={styles.containerInfo}>
                            <Text style={styles.txtMsg}>Mensalidade:</Text>
                            <Text style={styles.txtMsg}>R${parseFloat(item.valorMensalidade).toFixed(2)}</Text>
                        </View>
                        <View style={styles.containerInfo}>
                            <Text style={styles.txtMsg}>Unimed:</Text>
                            <Text style={styles.txtMsg}>R${parseFloat(item.totalUnimedGeral).toFixed(2)}</Text>
                        </View>
                        <View style={styles.containerInfo}>
                            <Text style={styles.txtMsg}>Uniodonto:</Text>
                            <Text style={styles.txtMsg}>R${parseFloat(item.totalGeralUniodonto).toFixed(2)}</Text>

                        </View>
                        <View style={styles.containerInfo}>
                            <Text style={styles.txtMsg}>Assistência Médica:</Text>
                            <Text style={styles.txtMsg}>R${parseFloat(item.totalAssistencia).toFixed(2)}</Text>

                        </View>
                        <View style={styles.containerInfo}>
                            <Text style={styles.txtMsg}>Oficinas:</Text>
                            <Text style={styles.txtMsg}>R${parseFloat(item.totalOficinas).toFixed(2)}</Text>

                        </View>
                        <View style={{marginTop: responsiveFontSize(0.5),height: responsiveFontSize(0.1), backgroundColor: colors.gray2}}/>
                        <View style={styles.containerInfo}>
                            <Text style={styles.txtMsg1}>Total:</Text>
                            <Text style={styles.txtMsg1}>R${parseFloat(item.total).toFixed(2)}</Text>
                        </View>
                        <TouchableOpacity style={styles.btn} onPress={()=>{callback("Fechar")}}>
                            <Text style={styles.txtBtn}>Voltar</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>
        </View>
    )

}

const styles = StyleSheet.create({
    centeredView: {
      flex: 1
    },
    centeredModal: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#000000aa'
    },
    modalView: {
        width: responsiveWidth(20),
        marginHorizontal: responsiveFontSize(1),
        backgroundColor: 'white',
        borderRadius: 5,
        padding: responsiveFontSize(1),
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    txtMes: {
        fontSize: responsiveFontSize(1.5),
        fontWeight: "bold",
        marginBottom: responsiveFontSize(2),
        alignSelf: 'center'
    },
    containerInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    txtMsg1: {
        fontSize: responsiveFontSize(1),
        marginTop: responsiveFontSize(0.5),
        fontWeight: "bold"
    },
    txtMsg: {
        fontSize: responsiveFontSize(1),
        marginTop: responsiveFontSize(0.5)
    },
    btn: {
        alignSelf: 'center'
    },
    txtBtn: {
        color: colors.blue,
        fontSize: responsiveFontSize(1),
        marginTop: responsiveFontSize(2),
    }
    
});
