import React, {useEffect, useState} from "react";
import { View, StyleSheet, TextInput, TouchableOpacity, Text, ScrollView, Picker, FlatList } from "react-native"
import { responsiveFontSize } from "react-native-responsive-dimensions";
import { FontAwesome5, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import { Checkbox } from 'react-native-paper';

import Footer from "../components/footer";
import Header from "../components/header";
import HeaderAssociados from "../components/headerAssociados";
import Menu from "../components/menu";
import ModalCancelAssociado from "../components/modalCancelAssociado";
import colors from "../styles/colors";
import { FormatPhone } from "../utils/formatPhone";
import { checkMatricula, LoadAssiM, LoadDependentes, LoadNameOneDependente, LoadOneUser, LoadUsers, saveUser } from "../services/firebaseServices";
import ModalInfoAccount from "../components/modalInfoAccount";
import LoadingModal from "../components/loadingModal";
import { generateUUID } from "../utils/generateUID";
import { set } from "date-fns/esm";
import { formatPrice } from "../utils/formatPrice";
import { formatCpf } from "../utils/formatCpf";
import { formatDate } from "../utils/formatDate";
import { format } from "date-fns";
import ModalViewHistoricoMensalidade from "../components/modalViewHistoricoMensalidade";
import ModalAddAssitenciaMedica from "../components/modalAddAssistenciaMedica";
import ModalViewAssistenciaMedica from "../components/modalViewAssistenciaMedica";
import ModalAddOficina from "../components/modalAddOficina";
import ModalEditAssistenciaMedica from "../components/modalEditAssistenciaMedica";
import { _pickDoc } from "../utils/uploadDoc";

const _dependentes = [
    {
        key: "1",
        name: "Antônio Santos Silva Junior",
        unimed: {
            active: true,
            valor: "",
            taxa: "",
            total: ""
        },
        uniodonto: {
            active: true,
            valor: ""
        }
    },
    {
        key: "2",
        name: "Clarice Santos Silva",
        unimed: {
            active: true,
            valor: "",
            taxa: "",
            total: ""
        },
        uniodonto: {
            active: true,
            valor: ""
        }
    },
    {
        key: "3",
        name: "Maria Santos Silva",
        unimed: {
            active: true,
            valor: "",
            taxa: "",
            total: ""
        },
        uniodonto: {
            active: true,
            valor: ""
        }
    }
]
const _oficinas = [
    {
        ativo: true,
        name: "Yoga",
        valor: "100.00"
    },
    {
        ativo: false,
        name: "Musculação",
        valor: "200.00"
    }
]

const pathRef = "/Users"
const pathAssistencia = "/AssistenciaMedica";
export default function NovoAssociado({navigation, route}){
    const [user, setUser] = useState(null)

    const [status, setStatus] = useState("Ativo")
    const [motivo, setMotivo] = useState(null)
    const [tpVinculo, setTpVinculo] = useState('Associado')
    const [matricula, setMatricula] = useState(null)
    const [dtEntrada, setDtEntrada] = useState(format(new Date(),"dd/MM/yyyy"))
    const [name, setName] = useState(null)
    const [cpf, setCpf] = useState(null)
    const [rg, setRg] = useState(null)
    const [phone, setPhone] = useState(null)
    const [email, setEmail] = useState(null)
    const [dataNascimento, setDataNascimento] = useState(null)
    const [dependentes, setDependentes] = useState(null)
    const [docDigital, setDocDigital] = useState(null)

    // Mensalidade
    const [associado, setAssociado] = useState("INSS")
    const [nBeneficio, setNBeneficio] = useState(null)
    const [salarioBruto, setSalarioBruto] = useState(null)
    const [valorINSS, setValorINSS] = useState(null)
    const [acordoCelpa, setAcordoCelpa] = useState(null)
    const [planoEQTPREV, setPlanoEQTPREV] = useState(null)
    const [variacao, setVariacao] = useState(null)
    const [valorMensalidade, setValorMensalidade] = useState(null)
    const [diaVencimento, setDiaVencimento] = useState(null)

    //Convenios
    //Unimed
    const [contratoUnimed, setContratoUnimed] = useState(null)
    const [valorPagarUnimed, setValorPagarUnimed] = useState(null)
    const [taxaUnimed, setTaxaUnimed] = useState(null)
    const [totalUnimed, setTotalUnimed] = useState(null)
    const [totalUnimedGeral, setTotalUnimedGeral] = useState(null)

    // Uniodonto
    const [contratoUniodonto, setContratoUniodonto] = useState(null)
    const [valorPagarUniodonto, setValorPagarUniodonto] = useState(null)
    const [totalGeralUniodonto, setTotalGeralUniodonto] = useState(null)

    const [visibleCancelModal, setVisibleCancelModal] = useState(false)
    // modal info
    const [visibleModalInfo, setVisibleModalInfo] = useState(false)
    const [titleModalInfo, setTitleModalInfo] = useState(null)
    const [msgModalInfo, setMsgModalInfo] = useState(null)
    // ModalLoading
    const [loading, setLoading] = useState(false)
    // Modal Historico Mensalidade
    const [viewMensalidade, setViewMensalidade] = useState(false)
    // Modal Assiscencia médica
    const [viewAddAssistencia, setViewAddAssistencia] = useState(false)
    const [viewListModalAssM, setViewListModalAssM] = useState(false)
    const [viewEditAss, setViewEditAss] = useState(false)

    // limite assistencia medica
    const [saldoAss, setSaldoAss] = useState(0.00)
    const [maxAss, setMaxAss] = useState(null)
    const [assistenciaThisMonth, setAssistenciaThisMonth] = useState(null)
    const [allAssistencia, setAllAssistencia] = useState(null)
    const [solicitacoes, setSolicitacoes] = useState(null)
    const [selectedAssistentcia, setSelectedAssistencia] = useState(null)
    
    // Oficinas
    const [oficinas, setOficinas] = useState(null)
    const [modaAddOficina, setModalAddOficina] = useState(false)

    useEffect(()=>{
        let key = route.params?.id;
        if(key !== undefined && key !== ''){
            LoadOneUser(pathRef, key, data => setUser(data))
        }
    },[])

    useEffect(()=>{
        if(user !== null){
            setTpVinculo(user.tpVinculo)
            setMatricula(user.matricula)
            setDtEntrada(user.dtEntrada)
            setName(user.name)
            setCpf(user.cpf)
            setRg(user.rg)
            setPhone(user.phone)
            setDataNascimento(user.dataNascimento)
            setEmail(user.email)
            setDiaVencimento(user.diaVencimento)
            setStatus(user.status)
            if(user.docDigital !== undefined){
                setDocDigital(user.docDigital)
            }
            if(user.motivo !== undefined){
                setMotivo(user.motivo)
            }

            // Mensalidade
            setAssociado(user.associado)
            setNBeneficio(user.nBeneficio)
            setSalarioBruto(user.salarioBruto)
            setValorINSS(user.valorINSS)
            setAcordoCelpa(user.acordoCelpa)
            setPlanoEQTPREV(user.planoEQTPREV)
            setVariacao(user.variacao)
            setValorMensalidade(user.valorMensalidade)
            setDiaVencimento(user.diaVencimento)

            if(user.dependentes !== undefined){
                setDependentes(user.dependentes)
            }else{
                LoadDependentes(pathRef, user.key, response => {
                    if(response !== null){
                        let fimDep = response.map(item => {
                            let i = {...item}
                            i["unimed"] = {
                                                active: true,
                                                valor: "",
                                                taxa: "",
                                                total: ""
                                            }
                            i["uniodonto"] = {
                                                active: true,
                                                valor: ""
                                            }
                            return i
                        })
                        setDependentes(fimDep)
                    }
                })

            }
            // Unimed
            setValorPagarUnimed(user.valorPagarUnimed)
            setTaxaUnimed(user.taxaUnimed)
            setTotalUnimed(user.totalUnimed)
            if(user.contratoUnimed !== undefined){
                setContratoUnimed(user.contratoUnimed)
            }

            // uniodonto
            setValorPagarUniodonto(user.valorPagarUniodonto)
            if(user.contratoUniodonto !== undefined){
                setContratoUniodonto(user.contratoUniodonto)
            }

            // oficinas
            if(user.oficinas !== undefined){
                setOficinas(user.oficinas)
            }
            // Carregar Assistencia medica
            LoadAssiM(pathAssistencia, user.key, response =>{
                if(response !== null){
                    let filter = response.sort((a, b) => {
                        if(a.data < b.data){
                            return -1
                        }
                        if(a.data > b.data){
                            return 1
                        }
                
                        return 0
                    })
    
                    setAllAssistencia(filter)
                }
            })
            
        }
    },[user])

    // filter solicitacoes assistencia medica
    useEffect(()=>{
        if(allAssistencia !== null){
            let limp = allAssistencia.filter(item => item.status === "Solicitado");
            setSolicitacoes(limp)
            // mostrar apenas aprovados e no mes atual
            let dt = new Date()
            let aproved = allAssistencia.filter(item => item.status === "Aprovado");
            let limpAtMonth = aproved.filter(item => new Date(item.data).getMonth() === dt.getMonth());
            let totalMonth = 0.0;
            limpAtMonth.map(item => totalMonth += parseFloat(item.valor))
            setSaldoAss(totalMonth)
        }
    },[allAssistencia])

    useEffect(()=>{
        if(dependentes !== null){
            LoadDependentes(pathRef, user.key, data => {
                if(data !== null){
                    if(data.length !== dependentes.length){
                        if(data.length > dependentes.length){
                            let newDep = data
                            dependentes.map(dep => {
                                newDep = newDep.filter(item => item.key !== dep.key)
                            })
                            let fimDep = newDep.map(item => {
                                let i = {...item}
                                i["unimed"] = {
                                                    active: true,
                                                    valor: "",
                                                    taxa: "",
                                                    total: ""
                                                }
                                i["uniodonto"] = {
                                                    active: true,
                                                    valor: ""
                                                }
                                return i
                            })
                            let all = [...dependentes,...fimDep]
                            setDependentes(all)
                        }else{
                            console.log("dep2",data)

                            let limp = []
                            data.map(item => {
                               let one = dependentes.filter(dep => dep.key === item.key)
                               limp.push(one[0])
                            })
                            setDependentes(limp)
                        }
                    }
                }
            })

            // let fim = user.dependentes.map(item =>{
            //     let i = {...item}
            //     LoadNameOneDependente(pathRef, item.key, data => {
            //         if(data !== null){
            //             i["name"] = data;
            //         }
            //     })
            //     return i
            // })
        }
    },[dependentes])

    useEffect(()=>{
        if(dtEntrada !== null && dtEntrada !== undefined && dtEntrada !== ''){
            let dtAt = format(new Date(), "yyyy")
            let dt = dtEntrada.split("/")
            if(dt[2] > dtAt){
                setTitleModalInfo("Essa não 🤔")
                setMsgModalInfo(`Parece que você esqueceu de tomar seu café ☕ e informou uma data inválida no campo "Data de Entrada", por favor verifique a data e tente novamente ✌🏻`)
                setVisibleModalInfo(true)
            }
        }
    },[dtEntrada])
    //calculo da mensalidade
    useEffect(()=>{
        if(associado === "INSS"){

            let _total = (parseFloat(valorINSS?.replace(",",".")) + parseFloat(acordoCelpa?.replace(",",".")) + parseFloat(planoEQTPREV?.replace(",",".")));
            let _valorMensalidade = _total * (parseFloat(variacao)/100);
            if(!isNaN(_valorMensalidade.toFixed(2))){
                setValorMensalidade(_valorMensalidade.toFixed(2))
            }
        }
        
    },[valorINSS,acordoCelpa,planoEQTPREV,variacao, associado])
    //Calculo Unimed
    useEffect(()=>{
        let _total = parseFloat(valorPagarUnimed) + parseFloat(taxaUnimed)
        if(!isNaN(_total.toFixed(2))){
            setTotalUnimed(_total.toFixed(2))
        }
    },[valorPagarUnimed, taxaUnimed])
    //CalculoTotalUnimedDep + totalUnimed
    useEffect(()=>{
        if(totalUnimed !== null && dependentes !== null){
            let _total = 0.0;
            dependentes.filter(item => item.unimed.active && item.unimed.total !== '').map(item => {_total += parseFloat(item.unimed.total);})
            
            let final = _total + parseFloat(totalUnimed)
            if(!isNaN(final.toFixed(2))){
                // console.log("Total Unimed: ", totalUnimed)
                // console.log("Total geral: R$",final)
                setTotalUnimedGeral(final.toFixed(2))
            }

        }else{
            setTotalUnimedGeral("0.00")
        }
    },[totalUnimed, dependentes])
    //CalculoTotalUniodontoDep + totalUniodonto
    useEffect(()=>{
        if(valorPagarUniodonto !== null && dependentes !== null){
            let _total = 0.0;
            dependentes.filter(item => item.uniodonto.active && item.uniodonto.valor !== '').map(item => {_total += parseFloat(item.uniodonto.valor);})
            let final = _total + parseFloat(valorPagarUniodonto)
            if(!isNaN(final.toFixed(2))){
                // console.log("Total geral Uniodonto: R$",final)
                setTotalGeralUniodonto(final.toFixed(2))
            }

        }else{
            setTotalGeralUniodonto("0.00")
        }
    },[valorPagarUniodonto, dependentes])

    useEffect(()=>{
        if(salarioBruto !== null){
            let _maxAss = parseFloat(salarioBruto) * (30/100);
            setMaxAss(_maxAss)
        }
    },[salarioBruto])

    // retornar quando cancelar o associado
    useEffect(()=>{
        if(status === "Inativo"){
            salvar()
        }
    },[status])

    
    function calculateUnimedDep(index){
        let dep = [...dependentes];
        let _total = parseFloat(dep[index].unimed.valor) + parseFloat(dep[index].unimed.taxa)
        if(!isNaN(_total.toFixed(2))){
            dep[index].unimed.total = _total.toFixed(2)
        }

    }

    function gerarMatricula(){
        let nM = 0;
        let exists = true;
        setLoading(true)
        switch(exists){
            case true:
                nM = generateUUID(6)
                checkMatricula(pathRef, nM, data =>{exists = data})
            case false:
                setMatricula(nM)
                setLoading(false)
                break;

        }
    }

    function verificarMatricula(){
        // console.log("check")
        setLoading(true)
        let exists = false;
        checkMatricula(pathRef, matricula, data =>{exists = data})

        if(exists){
            setMatricula(null)
            setLoading(false)
            setTitleModalInfo("Matrícula");
            setMsgModalInfo("Atenção a matrícula informada já está sendo usada, por gentileza informe outra matrícula ou aperte em gerar para criar uma nova matrícula.")
            setVisibleModalInfo(true)
        }else{
            setLoading(false)
            setTitleModalInfo("Matrícula");
            setMsgModalInfo("Matrícula disponível, prossiga com o cadastro. 👍🏻")
            setVisibleModalInfo(true)
        }
        
    }

    function salvar(){
        if(matricula !== null && dtEntrada !== null && name !== null && cpf !== null && rg !== null && dataNascimento !== null
        && phone !== null && email !== null && diaVencimento !== null){
            setLoading(true)
            let _user = {
                status,
                motivo,
                tpVinculo,
                matricula,
                dtEntrada,
                name,
                cpf,
                rg,
                dataNascimento,
                phone,
                email,
                docDigital,
                dependentes,
                associado,
                nBeneficio,
                salarioBruto,
                valorINSS,
                acordoCelpa,
                planoEQTPREV,
                variacao,
                valorMensalidade,
                valorPagarUnimed,
                taxaUnimed,
                totalUnimed,
                totalUnimedGeral,
                valorPagarUniodonto,
                totalGeralUniodonto,
                diaVencimento,
                oficinas,
                contratoUniodonto,
                contratoUnimed
            }
            saveUser(pathRef, _user, data =>{
                if(data){
                    setLoading(false)
                    setTitleModalInfo("Cadastro 📃")
                    setMsgModalInfo("Informações salvas com sucesso ✅")
                    setVisibleModalInfo(true)
                }else{
                    setLoading(false)
                    setTitleModalInfo("Cadastro 📃")
                    setMsgModalInfo("IParece que algo deu errado, tente novamente 🤔")
                    setVisibleModalInfo(true)
                }
            })
        }else{
            setTitleModalInfo("Atenção 🚨")
            setMsgModalInfo("Parece que você esqueceu de informar alguns dados, preencha-os e tente novamente. ✌🏻")
            setVisibleModalInfo(true)
        }
    }

    function renderItemDependente(item){
        
        return(
            <TouchableOpacity style={styles.containerBtnDep} onPress={()=>{navigation.push("NovoDependente",{id: item.key})}}>
                <Text style={styles.txtDependente}>{item.name}</Text>
            </TouchableOpacity>
        )
    }

    function renderItemDependenteEmpty(){
        return(
            <View>
                <Text style={styles.txtDependente}>Nenhum dependente encontrado.</Text>
            </View>
        )
    }

    function renderItemUnimedDep(item, index){
        if(item.unimed.active){
            calculateUnimedDep(index)
        }
        return(
            <View style={styles.containerInput}>
                <View>
                    <Text style={styles.txtLabel}>Ativo</Text>
                    <Checkbox
                        status={item.unimed.active ? 'checked' : 'unchecked'}
                        onPress={() => {
                            let dep = [...dependentes];
                            dep[index].unimed.active = !dep[index].unimed.active;
                            setDependentes(dep)
                        }}
                        color={colors.blue}
                    />
                </View>
                <View>
                    <Text style={styles.txtLabel}>Dependente</Text>
                    <Text style={styles.txtDependente}>{item.name}</Text>
                </View>
                <View>
                    <Text style={styles.txtLabel}>Valor a Pagar</Text>
                    <TextInput
                        editable={item.unimed.active}
                        style={styles.inputUnimedDp}
                        placeholder='R$'
                        onChangeText={text => {
                            let dep = [...dependentes];
                            dep[index].unimed.valor = formatPrice(text);
                            setDependentes(dep)
                        }}
                        value={item.unimed.valor}
                        keyboardType={'default'}
                    />
                </View>
                <View>
                    <Text style={styles.txtLabel}>Taxa</Text>
                    <TextInput
                        editable={item.unimed.active}
                        style={styles.inputUnimedDp}
                        placeholder='R$'
                        onChangeText={text => {
                            let dep = [...dependentes];
                            dep[index].unimed.taxa = formatPrice(text);
                            setDependentes(dep)
                        }}
                        value={item.unimed.taxa}
                        keyboardType={'default'}
                    />
                </View>
                <View>
                    <Text style={styles.txtLabel}>Total</Text>
                    <TextInput
                        editable={item.unimed.active}
                        style={styles.inputUnimedDp}
                        placeholder='R$'
                        onChangeText={text => {
                            let dep = [...dependentes];
                            dep[index].unimed.total = formatPrice(text);
                            setDependentes(dep)
                        }}
                        value={item.unimed.total}
                        keyboardType={'default'}
                    />
                </View>
                {/* <TouchableOpacity style={[styles.btnMensalidade,{width: responsiveFontSize(5)}]} onPress={()=>{}}>
                    <FontAwesome5 name="calendar-alt" size={responsiveFontSize(2)} color="white" />
                </TouchableOpacity> */}
                <View style={{width: responsiveFontSize(5)}}/>
            </View>
        )
    }

    function renderItemUniodontoDep(item, index){
        return(
            <View style={styles.containerInput}>
                <View>
                    <Text style={styles.txtLabel}>Ativo</Text>
                    <Checkbox
                        status={item.uniodonto.active ? 'checked' : 'unchecked'}
                        onPress={() => {
                            let dep = [...dependentes];
                            dep[index].uniodonto.active = !dep[index].uniodonto.active;
                            setDependentes(dep)
                        }}
                        color={colors.blue}
                    />
                </View>
                <View>
                    <Text style={styles.txtLabel}>Dependente</Text>
                    <Text style={styles.txtDependente}>{item.name}</Text>
                </View>
                <View>
                    <Text style={styles.txtLabel}>Valor a Pagar</Text>
                    <TextInput
                        editable={item.uniodonto.active}
                        style={styles.inputUnimedDp}
                        placeholder='R$'
                        onChangeText={text => {
                            let dep = [...dependentes];
                            dep[index].uniodonto.valor = formatPrice(text);
                            setDependentes(dep)
                        }}
                        value={item.uniodonto.valor}
                        keyboardType={'default'}
                    />
                </View>
                
                {/* <TouchableOpacity style={[styles.btnMensalidade,{width: responsiveFontSize(5)}]} onPress={()=>{}}>
                    <FontAwesome5 name="calendar-alt" size={responsiveFontSize(2)} color="white" />
                </TouchableOpacity> */}

                <View style={{width: responsiveFontSize(25)}}/>
            </View>
        )
    }

    function renderSolicitacaoItem(item){
        return(
            <View style={styles.containerSolicitacaoItem}>
                <Text style={styles.solicitacaoTxt}>Data: {format(new Date(item.data),"dd/MM/yyyy")}</Text>
                <Text style={styles.solicitacaoTxt}>Status: {item.status}</Text>
                <Text style={styles.solicitacaoTxt}>Nome: {item.nome}</Text>
                <TouchableOpacity style={styles.btnSolicitacaoEdit} onPress={()=>{
                    setSelectedAssistencia(item)
                    setViewEditAss(true)
                }}>
                    <Text style={styles.btnSolicitacaoEditTxt}>Editar</Text>
                </TouchableOpacity>
            </View>
        )
    }

    function renderOficinaItem(item, index){
        return(
            <View style={styles.containerOficinaItem}>
                <Checkbox status={item.ativo === true ? "checked" : "unchecked"}
                    color={colors.blue}
                    onPress={()=>{
                        let all = [...oficinas]
                        all[index].ativo = !item.ativo;
                        setOficinas(all)
                    }}
                />
                <Text style={styles.oficinasTxt}>{item.name}</Text>
                <Text style={styles.oficinasTxt}>R$ {item.valor}</Text>
                <TouchableOpacity style={styles.btnOficinaDelete} onPress={()=>{
                    let all = [...oficinas]
                    all.splice(index,1)
                    setOficinas(all)
                }}>
                    <Text style={styles.btnOficinaDeleteTxt}>Excluir</Text>
                </TouchableOpacity>
            </View>
        )
    }

    return(
        <View style={styles.container}>
            <Header nav={navigation}/>
                <View style={styles.containerBody}>
                    <Menu num={1} name={"Associados"} navigation={navigation}/>
                    <View style={{width: responsiveFontSize(80)}}>
                        <HeaderAssociados 
                            colorBtn1={colors.gray3} iconBtn1={"team"} nameBtn1={"Associados"} 
                            activeBtn1={true} navigationBtn1={"Associados"}
                            activeSearch={false} navigation={navigation}
                            colorBtn2={colors.blue} iconBtn2={"adduser"} nameBtn2={"Novo Associado"} 
                            activeBtn2={true} navigationBtn2={"NovoAssociado"}
                            typeFamily={0}/>
                        <Text style={styles.txtTitle}>Formulário de Inscrição</Text>
                        <ScrollView style={styles.form}>
                            {/* Dados Pessoais */}
                            <Text style={[styles.txtLabel,{marginTop: responsiveFontSize(1), fontSize: responsiveFontSize(1.2)}]}>Dados Pessoais</Text>
                            <View style={styles.containerInput}>
                                    <View>
                                        <Text style={styles.txtLabel}>Tipo de Vínculo</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholder=''
                                            onChangeText={setTpVinculo}
                                            value={tpVinculo}
                                            keyboardType={'default'}
                                            editable={false}
                                        />
                                    </View>
                                    <View>
                                        <Text style={styles.txtLabel}>Matrícula</Text>
                                        <View style={{flexDirection: 'row'}}>
                                            <TextInput
                                                style={[styles.input,{width: responsiveFontSize(15)}]}
                                                placeholder=''
                                                onChangeText={setMatricula}
                                                value={matricula}
                                                keyboardType={'default'}
                                                editable={user === null ? true : false}
                                            />
                                            <TouchableOpacity disabled={user === null ? false : true} style={styles.btnGerarMatricula} 
                                                onPress={()=>{
                                                    if(matricula?.length >= 6){
                                                        verificarMatricula()
                                                    }else{
                                                        gerarMatricula()
                                                    }
                                                }}>
                                                <MaterialCommunityIcons name="numeric" size={responsiveFontSize(1)} color="white" />
                                                <Text style={styles.txtBtnGerar}>{user === null && matricula?.length >= 6 ? "Verificar" : "Gerar"}</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                    <View>
                                        <Text style={styles.txtLabel}>Data de Entrada</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholder=''
                                            onChangeText={text => setDtEntrada(formatDate(text))}
                                            value={dtEntrada}
                                            maxLength={10}
                                            keyboardType={'default'}
                                        />
                                    </View>
                            </View>

                            <View style={styles.containerInput}>
                                <View>
                                    <Text style={styles.txtLabel}>Nome Completo</Text>
                                    <TextInput
                                        style={styles.input}
                                        placeholder=''
                                        onChangeText={setName}
                                        value={name}
                                        keyboardType={'default'}
                                    />
                                </View>
                                <View>
                                    <Text style={styles.txtLabel}>CPF</Text>
                                    <TextInput
                                        style={styles.input}
                                        placeholder=''
                                        onChangeText={setCpf}
                                        value={formatCpf(cpf)}
                                        maxLength={14}
                                        keyboardType={'default'}
                                    />
                                </View>
                                <View>
                                    <Text style={styles.txtLabel}>RG</Text>
                                    <TextInput
                                        style={styles.input}
                                        placeholder=''
                                        onChangeText={setRg}
                                        value={rg}
                                        keyboardType={'default'}
                                    />
                                </View>
                            </View>

                            <View style={styles.containerInput}>
                                <View>
                                    <Text style={styles.txtLabel}>Telefone</Text>
                                    <TextInput
                                        style={styles.input}
                                        placeholder=''
                                        onChangeText={setPhone}
                                        maxLength={15}
                                        value={FormatPhone(phone)}
                                        keyboardType={'default'}
                                    />
                                </View>
                                <View>
                                    <Text style={styles.txtLabel}>Data de Nascimento</Text>
                                    <TextInput
                                        style={styles.input}
                                        placeholder=''
                                        onChangeText={text => setDataNascimento(formatDate(text))}
                                        value={dataNascimento}
                                        maxLength={10}
                                        keyboardType={'default'}
                                    />
                                </View>
                                <View>
                                    <Text style={styles.txtLabel}>E-mail</Text>
                                    <TextInput
                                        style={styles.input}
                                        placeholder=''
                                        onChangeText={setEmail}
                                        value={email}
                                        keyboardType={'default'}
                                    />
                                </View>

                            </View>
                            {/* doc Digital */}
                            <Text style={styles.txtLabel}>Documentos Digitalizados</Text>
                            <Text style={styles.txtDoc}>É importante que crie um pdf com todos os documentos digitalizados e envie somente um arquivo.</Text>
                            <View style={styles.containerDocDigital}>
                                <TouchableOpacity style={[styles.btnMensalidade,{width: responsiveFontSize(5), backgroundColor: colors.gray3, marginTop: 0, marginRight: responsiveFontSize(1)}]} onPress={()=>{
                                        if(user !== undefined && user !== null){
                                            _pickDoc("/Users",user.key,"doc-digitalizado", load => setLoading(load), link => setDocDigital(link))
                                        
                                        }else{
                                            setTitleModalInfo("Atenção 🚨");
                                            setMsgModalInfo("Para enviar o pdf dos documentos digitalizados, primeiro você precisa informar as informações de dados pessoais e apertar em SALVAR.")
                                            setVisibleModalInfo(true)
                                        }
                                }}>
                                    <Ionicons name="ios-document-text-outline" size={responsiveFontSize(2)} color="white" />
                                    <Text style={styles.txtUniodonto}>{docDigital === null ? "Enviar" : "Alterar"}</Text> 
                                </TouchableOpacity>
                                {docDigital !== null && (
                                    <TouchableOpacity style={[styles.btnMensalidade,{width: responsiveFontSize(5), backgroundColor: colors.blue,  marginTop: 0}]} onPress={()=>{window.open(docDigital)}}>
                                        <MaterialCommunityIcons  name="file-eye-outline" size={responsiveFontSize(2)} color="white" />
                                        <Text style={styles.txtUniodonto}>Ver</Text>
                                    </TouchableOpacity>
                                )}
                            </View>
                            {tpVinculo !== "Dependente" && (
                            <>
                                {/* Dependentes */}
                                <Text style={styles.txtLabel}>Dependentes</Text>
                                <FlatList
                                    data={dependentes}
                                    numColumns={3}
                                    keyExtractor={ item => item.key}
                                    renderItem={({item}) => renderItemDependente(item)}
                                    ListEmptyComponent={renderItemDependenteEmpty}
                                />

                                <TouchableOpacity style={styles.btnDep} onPress={()=>{
                                    if(user !== undefined && user !== null){
                                        navigation.navigate("NovoDependente",{id: undefined, idAssociado: user.key})
                                    }else{
                                        setTitleModalInfo("Atenção 🚨");
                                        setMsgModalInfo("Para adicionar um dependente, primeiro você precisa informar as informações de dados pessoais e apertar em SALVAR.")
                                        setVisibleModalInfo(true)
                                    }
                                }}>
                                    <Text style={styles.txtBtnDependente}>Adicionar novo Dependente</Text>
                                </TouchableOpacity>

                                {/* Mensalidade */}
                                <View style={styles.containerInput}>
                                    <View>
                                        <Text style={styles.txtLabel}>Associado</Text>
                                        <Picker
                                            selectedValue={associado}
                                            style={styles.input}
                                            onValueChange={(itemValue, itemIndex) => setAssociado(itemValue)}
                                        >
                                            <Picker.Item key={0} label={"INSS"} value={"INSS"} />
                                            <Picker.Item key={1} label={"Particular"} value={"Particular"} />
                                        </Picker>
                                    </View>
                                    {associado === "INSS" && (
                                        <>
                                            <View>
                                                <Text style={styles.txtLabel}>N° do Benefício</Text>
                                                <TextInput
                                                    style={styles.input}
                                                    placeholder=''
                                                    onChangeText={setNBeneficio}
                                                    value={nBeneficio}
                                                    keyboardType={'default'}
                                                />
                                            </View>
                                            <View>
                                                <Text style={styles.txtLabel}>Salário Bruto</Text>
                                                <TextInput
                                                    style={styles.input}
                                                    placeholder='R$'
                                                    onChangeText={text => setSalarioBruto(formatPrice(text))}
                                                    value={salarioBruto}
                                                    keyboardType={'default'}
                                                />
                                            </View>
                                        </>
                                    )}
                                </View>
                                {associado === "INSS" && (
                                    <View style={styles.containerInput}>
                                        <View>
                                            <Text style={styles.txtLabel}>Valor INSS</Text>
                                            <TextInput
                                                style={styles.input}
                                                placeholder='R$'
                                                onChangeText={text => setValorINSS(formatPrice(text))}
                                                value={valorINSS}
                                                keyboardType={'default'}
                                            />
                                        </View>
                                        <View>
                                            <Text style={styles.txtLabel}>Acordo Celpa</Text>
                                            <TextInput
                                                style={styles.input}
                                                placeholder='R$'
                                                onChangeText={text => setAcordoCelpa(formatPrice(text))}
                                                value={acordoCelpa}
                                                keyboardType={'default'}
                                            />
                                        </View>
                                        <View>
                                            <Text style={styles.txtLabel}>Plano EQTPREV</Text>
                                            <TextInput
                                                style={styles.input}
                                                placeholder='R$'
                                                onChangeText={text => setPlanoEQTPREV(formatPrice(text))}
                                                value={planoEQTPREV}
                                                keyboardType={'default'}
                                            />
                                        </View>
                                    </View>
                                )}
                                <View style={styles.containerInput}>
                                    {associado === "INSS" && (
                                        <View>
                                            <Text style={styles.txtLabel}>Variação</Text>
                                            <TextInput
                                                style={styles.input}
                                                placeholder='%'
                                                onChangeText={setVariacao}
                                                value={variacao}
                                                keyboardType={'default'}
                                            />
                                        </View>
                                    )}
                                    <View>
                                        <Text style={styles.txtLabel}>Valor Mensalidade</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholder='R$'
                                            onChangeText={text => setValorMensalidade(formatPrice(text))}
                                            value={valorMensalidade}
                                            keyboardType={'default'}
                                        />
                                    </View>
                                    {/* btn ver historico mensalidade */}
                                    <TouchableOpacity style={styles.btnMensalidade} onPress={()=>{setViewMensalidade(true)}}>
                                        <FontAwesome5 name="calendar-alt" size={responsiveFontSize(2)} color="white" />
                                    </TouchableOpacity>

                                </View>
                                <View style={styles.containerInput}>
                                <View>
                                        <Text style={styles.txtLabel}>Vencimento</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholder='Dia ex: 5'
                                            onChangeText={setDiaVencimento}
                                            value={diaVencimento}
                                            keyboardType={'default'}
                                        />
                                    </View>
                                </View>
                                {/* Fim Mensalidade */}
                                {/* Convenios */}
                                <Text style={[styles.txtLabel,{marginTop: responsiveFontSize(2), fontSize: responsiveFontSize(1.2)}]}>Unimed</Text>
                                <View style={styles.containerInput}>
                                    <View>
                                        <Text style={styles.txtLabel}>Valor a Pagar</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholder='R$'
                                            onChangeText={text => setValorPagarUnimed(formatPrice(text))}
                                            value={valorPagarUnimed}
                                            keyboardType={'default'}
                                        />
                                    </View>
                                    <View>
                                        <Text style={styles.txtLabel}>Taxa</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholder='R$'
                                            onChangeText={text => setTaxaUnimed(formatPrice(text))}
                                            value={taxaUnimed}
                                            keyboardType={'default'}
                                        />
                                    </View>
                                    <View>
                                        <Text style={styles.txtLabel}>Total</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholder='R$'
                                            onChangeText={text => setTotalUnimed(formatPrice(text))}
                                            value={totalUnimed}
                                            keyboardType={'default'}
                                        />
                                    </View>
                                    {/* <TouchableOpacity style={[styles.btnMensalidade,{width: responsiveFontSize(5)}]} onPress={()=>{}}>
                                        <FontAwesome5 name="calendar-alt" size={responsiveFontSize(2)} color="white" />
                                    </TouchableOpacity> */}
                                    <TouchableOpacity style={[styles.btnMensalidade,{width: responsiveFontSize(5), backgroundColor: colors.gray3}]} onPress={()=>{_pickDoc("/Users",user.key,"unimed-contrato", load => setLoading(load), link => setContratoUnimed(link))}}>
                                        <Ionicons name="ios-document-text-outline" size={responsiveFontSize(2)} color="white" />
                                        <Text style={styles.txtUniodonto}>{contratoUnimed === null ? "Enviar" : "Alterar"}</Text> 
                                    </TouchableOpacity>
                                    {contratoUnimed !== null && (
                                        <TouchableOpacity style={[styles.btnMensalidade,{width: responsiveFontSize(5), backgroundColor: colors.blue}]} onPress={()=>{window.open(contratoUnimed)}}>
                                            <MaterialCommunityIcons  name="file-eye-outline" size={responsiveFontSize(2)} color="white" />
                                            <Text style={styles.txtUniodonto}>Ver</Text>
                                        </TouchableOpacity>
                                    )}
                                </View>
                                {/* Unimed Dependentes */}
                                <FlatList
                                    data={dependentes}
                                    keyExtractor={ item => item.key}
                                    renderItem={({item, index}) => renderItemUnimedDep(item,index)}
                                    
                                />
                                {/* Uniodonto */}
                                <Text style={[styles.txtLabel,{marginTop: responsiveFontSize(2), fontSize: responsiveFontSize(1.2)}]}>Uniodonto</Text>
                                <View style={styles.containerInput}>
                                    <View>
                                        <Text style={styles.txtLabel}>Valor a Pagar</Text>
                                        <TextInput
                                            style={styles.input}
                                            placeholder='R$'
                                            onChangeText={text => setValorPagarUniodonto(formatPrice(text))}
                                            value={valorPagarUniodonto}
                                            keyboardType={'default'}
                                        />
                                    </View>
                                    <View style={{width: responsiveFontSize(20)}}/>
                                    {/* <TouchableOpacity style={[styles.btnMensalidade,{width: responsiveFontSize(5)}]} onPress={()=>{}}>
                                        <FontAwesome5 name="calendar-alt" size={responsiveFontSize(2)} color="white" />
                                    </TouchableOpacity> */}
                                    <TouchableOpacity style={[styles.btnMensalidade,{width: responsiveFontSize(5), backgroundColor: colors.gray3}]} onPress={()=>{_pickDoc("/Users",user.key,"uniodonto-contrato", load => setLoading(load), link => setContratoUniodonto(link))}}>
                                        <Ionicons name="ios-document-text-outline" size={responsiveFontSize(2)} color="white" />
                                        <Text style={styles.txtUniodonto}>{contratoUniodonto === null ? "Enviar" : "Alterar"}</Text> 
                                    </TouchableOpacity>
                                    {contratoUniodonto !== null && (
                                        <TouchableOpacity style={[styles.btnMensalidade,{width: responsiveFontSize(5), backgroundColor: colors.blue}]} onPress={()=>{window.open(contratoUniodonto)}}>
                                            <MaterialCommunityIcons  name="file-eye-outline" size={responsiveFontSize(2)} color="white" />
                                            <Text style={styles.txtUniodonto}>Ver</Text>
                                        </TouchableOpacity>
                                    )}
                                    <View style={{width: responsiveFontSize(20)}}/>
                                </View>
                                {/* Uniodonto Dependentes */}
                                <FlatList                                                           
                                    data={dependentes}
                                    keyExtractor={ item => item.key}
                                    renderItem={({item, index}) => renderItemUniodontoDep(item,index)}
                                />

                                {/* Assistencia Medica */}
                                <Text style={[styles.txtLabel,{marginTop: responsiveFontSize(2), fontSize: responsiveFontSize(1.2)}]}>Assistência Médica</Text>
                                <View style={styles.containerInputAssM}>
                                    <Text style={styles.txtAss}>Utilizado: R${saldoAss?.toFixed(2)}</Text>
                                    <Text style={styles.txtAss}>Máximo: R${maxAss?.toFixed(2)}</Text>
                                </View>
                                <View style={styles.containerInputAssM}>
                                    <TouchableOpacity style={styles.btnAssMed} onPress={()=>{setViewListModalAssM(true)}}>
                                        <Ionicons name="receipt-outline" size={responsiveFontSize(2)} color="white" />
                                        <Text style={styles.txtBtnAssM}>Ver</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.btnAssMed} onPress={()=>{setViewAddAssistencia(true)}}>
                                        <Ionicons name="add" size={responsiveFontSize(2)} color="white" />
                                        <Text style={styles.txtBtnAssM}>Adicionar</Text>
                                    </TouchableOpacity>
                                </View>
                                <Text style={[styles.txtLabel,{marginTop: responsiveFontSize(1), fontSize: responsiveFontSize(1)}]}>Solicitações</Text>
                                <FlatList
                                    data={solicitacoes}
                                    renderItem={({item}) => renderSolicitacaoItem(item)}
                                    keyExtractor={(item) => item.key}
                                    style={{marginLeft: responsiveFontSize(1)}}
                                    ListEmptyComponent={<Text style={styles.txtEmptyOficina}>Nenhuma solicitação encontrada.</Text>}
                                />

                                {/* Oficinas */}
                                <Text style={[styles.txtLabel,{marginTop: responsiveFontSize(2), fontSize: responsiveFontSize(1.2)}]}>Oficinas</Text>
                                <View style={styles.containerInputAssM}>
                                    <TouchableOpacity style={styles.btnAssMed} onPress={()=>{setModalAddOficina(true)}}>
                                        <Ionicons name="add" size={responsiveFontSize(2)} color="white" />
                                        <Text style={styles.txtBtnAssM}>Adicionar</Text>
                                    </TouchableOpacity>
                                </View>
                                <FlatList
                                    data={oficinas}
                                    renderItem={({item, index}) => renderOficinaItem(item, index)}
                                    keyExtractor={(item, index) => item.name + index}
                                    style={{marginLeft: responsiveFontSize(1)}}
                                    ListEmptyComponent={<Text style={styles.txtEmptyOficina}>Nenhum dado encontrado, adicione uma oficina ou tente novamente mais tarde.</Text>}
                                />
                            </>
                            )}
                            {/* Modals */}
                            <ModalCancelAssociado
                                visible={visibleCancelModal}
                                callback={(value) => {
                                    setVisibleCancelModal(false)
                                    if(value !== ''){
                                        setStatus("Inativo")
                                        setMotivo(value)
                                        setLoading(true)
                                    }
                                }}
                            />

                            <ModalViewHistoricoMensalidade
                                visible={viewMensalidade}
                                callback={(value) => {setViewMensalidade(false)}}
                                userData={user}
                            />

                            {/* Modal Assistencia */}
                            <ModalAddAssitenciaMedica 
                                visible={viewAddAssistencia}
                                idUser={user?.key}
                                callback={()=>{
                                    setViewAddAssistencia(false)
                                }}
                            />
                            <ModalEditAssistenciaMedica
                                visible={viewEditAss}
                                assistencia={selectedAssistentcia}
                                callback={(value)=>{
                                    setViewEditAss(false)

                                }}
                            />
                            {/* modal ver Assistencia */}
                            <ModalViewAssistenciaMedica
                                visible={viewListModalAssM}
                                idUser={user?.key}
                                callback={()=>{
                                    setViewListModalAssM(false)
                                }}
                            />
                            {/* Modal add Oficina */}
                            <ModalAddOficina
                                visible={modaAddOficina}
                                callback={(value, itemOf)=>{
                                    setModalAddOficina(false)
                                    if(value === "Salvo"){
                                        let all = oficinas !== null ? [...oficinas] : []
                                        all.push(itemOf)
                                        setOficinas(all)
                                    }
                                }}
                            />

                            <ModalInfoAccount 
                                visible={visibleModalInfo}
                                title={titleModalInfo}
                                msg={msgModalInfo}
                                callback={({value})=>{
                                    setVisibleModalInfo(false)
                                    if(status === "Inativo"){
                                        navigation.goBack();
                                    }
                                }}/>

                            <LoadingModal 
                                visible={loading}
                                msg={'Carregando, aguarde...'}
                            />
                            {/* Fim modals */}
                            <View style={styles.containerInput}>
                                <TouchableOpacity style={styles.btn} onPress={()=>{salvar()}}>
                                    <Text style={styles.btnTxt}>Salvar</Text>
                                </TouchableOpacity>
                                {user !== null && (
                                    <TouchableOpacity style={[styles.btn,{backgroundColor: colors.red}]} onPress={()=>{setVisibleCancelModal(true)}}>
                                        <Text style={styles.btnTxt}>Cancelar Cadastro</Text>
                                    </TouchableOpacity>
                                )}
                            </View>
                        </ScrollView>
                    </View>
                </View>
            <Footer/>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    containerBody: {
        flexDirection: 'row'
    },
    txtTitle: {
        width: responsiveFontSize(79),
        fontSize: responsiveFontSize(1.5),
        color: colors.gray3,
        padding: responsiveFontSize(0.5),
        borderBottomWidth: responsiveFontSize(0.1),
        borderBottomColor: colors.gray2,
        marginLeft: responsiveFontSize(1.5)
    },
    form: {
        marginLeft: responsiveFontSize(1.5),
        width: responsiveFontSize(79),
        height: responsiveFontSize(20),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        borderRadius: responsiveFontSize(0.5),
        borderTopWidth: 0,
        borderTopEndRadius: 0,
        borderTopStartRadius: 0,
        padding: responsiveFontSize(0.5)
    },
    containerInput: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    txtLabel: {
        fontSize: responsiveFontSize(0.8),
        fontWeight: "bold",
        paddingVertical: responsiveFontSize(1)
    },
    input: {
        width: responsiveFontSize(20),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        borderRadius: responsiveFontSize(0.5),
        fontSize: responsiveFontSize(1),
        padding: responsiveFontSize(0.2)
    },
    // docDigital
    containerDocDigital: {
        flexDirection: "row",
    },
    txtDoc: {
        color: colors.gray3,
        fontSize: responsiveFontSize(0.8),
        fontStyle: "italic",
        marginBottom: responsiveFontSize(1)
    },
    // btnMatricula
    btnGerarMatricula: {
        width: responsiveFontSize(4),
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        backgroundColor: colors.blue,
        borderRadius: responsiveFontSize(0.5),
        marginLeft: responsiveFontSize(1)
    },
    txtBtnGerar: {
        color: "white",
        fontSize: responsiveFontSize(0.8)
    },

    //btn
    btn: {
        width: responsiveFontSize(15),
        padding: responsiveFontSize(0.5),
        backgroundColor: colors.blue,
        borderRadius: responsiveFontSize(0.5),
        marginVertical: responsiveFontSize(1),
        alignItems: 'center'
    },
    btnTxt: {
        fontSize: responsiveFontSize(1),
        color: "white"
    },

    //Dependentes
    containerBtnDep: {
        marginRight: responsiveFontSize(7.9),
        marginBottom: responsiveFontSize(1),
        padding: responsiveFontSize(0.2)
    },
    txtDependente: {
        width: responsiveFontSize(20),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        borderRadius: responsiveFontSize(0.5),
        fontSize: responsiveFontSize(1),
        padding: responsiveFontSize(0.2),
        paddingHorizontal: responsiveFontSize(0.5),
        backgroundColor: colors.gray,
        color: colors.gray3,
    },
    btnDep:{
        width: responsiveFontSize(20),
        backgroundColor: colors.blue,
        borderRadius: responsiveFontSize(0.5),
        marginBottom: responsiveFontSize(2)
    },
    txtBtnDependente: {
        fontSize: responsiveFontSize(1),
        padding: responsiveFontSize(0.5),
        color: "white",
        textAlign: 'center',
    },
    //Mensalidade
    btnMensalidade: {
        width: responsiveFontSize(20),
        backgroundColor: colors.blue,
        padding: responsiveFontSize(0.2),
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: responsiveFontSize(0.5),
        marginTop: responsiveFontSize(1.5)
    },
    // Convenio Unimed
    inputUnimedDp: {
        width: responsiveFontSize(10),
        borderWidth: responsiveFontSize(0.1),
        borderColor: colors.gray2,
        borderRadius: responsiveFontSize(0.5),
        fontSize: responsiveFontSize(1),
        padding: responsiveFontSize(0.2)
    },
    // Uniodonto
    txtUniodonto: {
        color: "white",
        fontSize: responsiveFontSize(0.8)
    },
    // Ass medica
    containerInputAssM: {
        flexDirection: 'row',
    },
    txtAss: {
        fontSize: responsiveFontSize(1),
        marginRight: responsiveFontSize(1)
    },
    btnAssMed: {
        width: responsiveFontSize(6),
        backgroundColor: colors.blue,
        padding: responsiveFontSize(0.2),
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: responsiveFontSize(0.5),
        marginTop: responsiveFontSize(1.5),
        marginLeft: responsiveFontSize(1)
    },
    txtBtnAssM: {
        fontSize: responsiveFontSize(1),
        color: "white"
    },
    // Solicitações assistencia
    containerSolicitacaoItem: {
        flexDirection: "row",
        justifyContent: 'space-between',
        width: responsiveFontSize(35),
        padding: responsiveFontSize(0.2),
        borderRadius: responsiveFontSize(0.2),
        backgroundColor: colors.gray,
        marginTop: responsiveFontSize(1),
        alignItems: 'center'
    },
    solicitacaoTxt: {
        width: responsiveFontSize(10),
        fontSize: responsiveFontSize(1)
    },
    btnSolicitacaoEdit: {
        padding: responsiveFontSize(0.2),
        borderRadius: responsiveFontSize(0.2),
        backgroundColor: colors.blue
    },
    btnSolicitacaoEditTxt: {
        fontSize: responsiveFontSize(1),
        color: "white"
    },
    // Oficinas
    containerOficinaItem: {
        flexDirection: "row",
        justifyContent: 'space-between',
        width: responsiveFontSize(30),
        padding: responsiveFontSize(0.2),
        borderRadius: responsiveFontSize(0.2),
        backgroundColor: colors.gray,
        marginTop: responsiveFontSize(1),
        alignItems: 'center'
    },
    oficinasTxt: {
        width: responsiveFontSize(10),
        fontSize: responsiveFontSize(1)
    },
    btnOficinaDelete: {
        padding: responsiveFontSize(0.2),
        borderRadius: responsiveFontSize(0.2),
        backgroundColor: colors.red,
        marginRight: responsiveFontSize(0.5)
    },
    btnOficinaDeleteTxt: {
        fontSize: responsiveFontSize(1),
        color: "white"
    },
    txtEmptyOficina: {
        marginVertical: responsiveFontSize(2),
        marginLeft: responsiveFontSize(1),
        fontSize: responsiveFontSize(1)
    }
    
})